.notification-bell__base {
  position: relative;
  cursor: pointer;
}

.notification-bell__overlay {
  position: absolute;
  bottom: -8px;
  right: 10px;
  font-size: 0;
  font-family: "Montserrat", sans-serif;
  color: white;
  font-weight: bolder;
  background: var(--red-01);
  height: 4px;
  width: 4px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 0;
}

@media screen and (max-width: 992px) {
  .notification-bell__base {
    border: none;
    margin-top: 0.5vw;
  }
}
