.manage-account__input-title {
  color: var(--gray-70);
  font-weight: 600;
  font-size: 0.875rem;
}

.manage-account__input {
  font-size: 1rem;
  font-weight: 500;
  border: 2px solid var(--separator-01);
  margin: 0.5rem 0;
  color: var(--navy);
  padding: 0.5rem 1rem;
}

.manage-account__input:disabled {
  background-color: white;
}

.manage-account__connected_input:disabled {
  background-color: var(--gray-10) !important;
}

.account-details__input,
.pylon-input.account-details__input {
  line-height: 2rem;
  height: auto;
  padding: 0.5rem 1rem;
  border: 2px solid var(--separator-01);
  font-size: 1rem;
}

.account-details__input:disabled {
  background-color: white;
}

.react-toggle--disabled.account-details-toggle {
  cursor: unset;
  opacity: unset;
}
.account-type-selection-row {
  margin-bottom: 2rem;
}

.account-details__manage-save-update {
  position: absolute;
  bottom: 1rem;
  right: 2rem;
  background-color: white;
}

.account-type-selection-row > div {
  width: 48%;
}

.account-details__manage-save-saved {
  animation: account-details__manage-save-saved 3s 1;
  animation-fill-mode: forwards;

  animation-delay: 0.5s;
}

.account-tray-tablist {
  display: flex;
  justify-content: space-around;
  margin-block-start: 0;
  padding-inline-start: 0;
  margin-top: 2rem;
}

.account-tray-tab {
  display: inline-block;
  position: relative;
  list-style: none;
  margin: 0;
  margin-bottom: 1rem;
  cursor: pointer;

  font-family: "Montserrat", sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 0.875rem;
  line-height: 1.5rem;
  color: var(--text-03);

  text-transform: uppercase;
}

.account-tray-tab.selected {
  color: var(--blue-01);
  border-bottom: solid var(--blue-01) 3px;
  padding-bottom: 0.5rem;
}

@media screen and (max-width: 992px) {
  .account-tray-tab {
    margin: 0 4vw;
  }
}

@keyframes account-details__manage-save-saved {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}

@media screen and (max-width: 992px) {
  .account-type-selection-row > div {
    width: 100%;
  }
}

.account-input-highlight-error {
  background: #fee6e5;
  border: 2px solid #fe7770;
}

.account-detail__detail-row {
  display: flex;
  align-items: center;
  border-bottom: 1px solid var(--separator-02);
  padding: 0.5rem 0;
  gap: 0.5rem;
}

.account-detail__detail-label {
  flex-grow: 0;
  flex-shrink: 0;
  width: 12rem;
}

.account-detail__detail-value {
  flex-grow: 1;
  flex-shrink: 1;
}
