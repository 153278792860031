.table-row.plan-row {
  padding-top: 1rem !important;
  align-items: flex-start;
}
.table-cell.status,
.table-cell.setup-time {
  flex-basis: 5%;
}

#financial-plan:hover {
  background-color: var(--nav-hover-bg);
}

#financial-plan:active {
  background-color: var(--nav-pressed-bg);
}

@media screen and (max-width: 992px) {
  .table-cell.status,
  .table-cell.setup-time {
    flex-basis: 17%;
  }
}
