.financial-plan-status-block,
.financial-plan-status-block .status-bar {
  width: 100%;
}

.financial-plan-status-block .status-bar {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  margin-top: 1rem;
}

.financial-plan-status-block .status-bar > div.bar {
  height: 8px;
  background: var(--gray-200);
  flex: 1;
  gap: 2px;
  row-gap: 2px;
  border: 1px solid white;
  border-radius: 3px;
  margin-right: 2px;
}
.financial-plan-status-block .status-bar > div.done {
  height: 8px;
  background: var(--green-500);
}
.financial-plan-status-block .status-bar > div:last-child {
  margin-right: 0;
}
