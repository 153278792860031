.drop-down-menu-item {
  cursor: pointer;
  padding: 0.5rem 1rem;
  top: 0;
}

.drop-down-menu-item__account_add {
  cursor: unset;
}

.drop-down-menu-item__fixed-min-height > div,
.drop-down-menu-item__fixed-min-height > a {
  min-height: 40px;
  line-height: 40px !important;
  text-decoration: none;
  color: var(--text-01);
}

.drop-down-menu-item:hover {
  background-color: var(--yellow-50);
}

.drop-down-menu-item:active {
  background-color: var(--yellow-100);
}
