.password-reset-page-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 2rem;
}

.password-reset-page-border {
  min-height: 50vh;
  border-radius: 5px;
  padding: 1rem 2rem;
}

.password-page-field {
  width: 25rem;
}
