.newuser-name-wrapper {
  display: flex;
  align-content: space-between;
}

.newuser-button-wrapper {
  padding-top: 1rem;
  padding-bottom: 6rem;
}

.new-user__span-as-link {
  cursor: pointer;
  color: var(--blue-01);
  text-decoration: underline;
}

.new-user__span-as-link:hover {
  color: var(--blue);
}

.react-datepicker-wrapper {
  width: 100%;
}

.react-datepicker__input-container input {
  width: 100%;
  padding-left: 1rem;
  line-height: 3rem;
  border-radius: 6px;
  width: 100%;
  height: 3rem;
  border: 2px solid var(--separator-01);
}

.react-datepicker__input-container input::placeholder {
  color: var(--gray-30);
}
