.wizard-header > div.pylon {
  display: block;
}

.wizard-header-mobile {
  display: none;
}
@media screen and (max-width: 992px) {
  .wizard-header-mobile {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 2vh 4vw;
    z-index: var(--z-index-left-nav);
  }
  .left-nav-divider {
    width: 88%;
  }
  .show-nav .wizard-header-mobile {
    background-color: var(--nav-bg);
  }
  .wizard-header-mobile .pylon-logo {
    margin-top: -0.5rem;
  }

  .overview {
    padding: 0 0.75rem;
  }
  .overview-component {
    margin-right: 0;
  }

  .overview-component > div {
    margin: 0.75rem;
  }

  .networth-summary {
    width: calc(100% - 1.5rem);
    padding: 0.75rem;
  }
  .public-page-background {
    padding: 2vh 4vw;
  }
  .account-page {
    max-width: unset;
  }

  .account-page h2 {
    margin-top: 10vh;
    text-align: center;
  }

  .public-page-background {
    background-attachment: unset;
  }

  .onboarding-page__bg-wrapper {
    background-size: 100vh;
    background-position-y: 80vh;
    background-attachment: fixed;
  }

  .dashboard-content-wrapper {
    padding: 0;
    margin-top: 0;
  }
  .dashboard-content-inner {
    padding: 0 4vw;
  }
  /* ----------------------- */

  .manage-account-tray {
    margin: 0;
  }
  .left-nav-container {
    display: none;
  }
  .login-page-wrapper {
    padding: 2rem;
  }
  .title {
    margin-top: -5rem !important;
  }
  .row-card__more {
    display: none;
  }
  .dashboard-wrapper {
    width: 100%;
    padding-left: 0 !important;
  }

  h1 {
    font-size: 2rem !important;
    margin-top: -7.25rem !important;
  }
  .collapsible-wrapper {
    width: 100%;
  }
  .row-card__source-type {
    display: none;
  }
  .row-card__content-subtext-shared-by,
  .row-card__content-subtext-divider {
    display: none !important;
  }
  .side-tray .row-card__content-subtext-shared-by,
  .side-tray .row-card__content-subtext-divider,
  .side-tray .row-card__content-subtext {
    display: block !important;
  }

  h1 {
    font-size: 2rem;
    margin-top: -5rem;
  }

  .account-picker-option {
    margin-bottom: 1rem;
  }
  .account-picker-option .arrow-up {
    display: none;
  }

  .account-filtering {
    margin-right: 2rem;
  }
}
