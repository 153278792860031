.manage-account-menu {
  z-index: 1000;
  width: 14.375rem;
  background: #ffffff;
  box-shadow: 0px 1px 8px rgba(230, 233, 237, 0.8),
    0px 1px 4px rgba(181, 190, 202, 0.64);
  border-radius: 6px;
  position: absolute;
  right: 0;
  top: 0rem;
  display: flex;
  flex-direction: column;
  cursor: pointer;
}
