.document-card__icon {
  height: 3rem;
  width: 3rem;
  color: white;
  background-color: #e2f3fa;
  justify-content: center;
  border-radius: 5px;
  font-size: 0.563rem;
  font-weight: bold;
  display: flex;
  align-items: center;
  text-transform: uppercase;
  position: relative;
  padding: 0.25rem;
  line-height: 1rem;
}

.document-icon__file-preview {
  height: 3rem;
  width: 3rem;
  object-fit: contain;
}
