.landing-page .taxes-2 .landing-right {
  width: 60%;
}

.landing-page .taxes-2 .landing-left {
  width: 40%;
  padding-right: 5%;
}

.landing-page .taxes-3a .landing-left {
  padding-right: 5%;
}

.title-summary {
  font-family: "Montserrat", sans-serif;
  font-size: 1.5rem;
  font-style: normal;
  font-weight: 600;
  line-height: 100%;
  letter-spacing: 0em;
  color: var(--blue-01);
}
