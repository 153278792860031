@media screen and (max-width: 992px) {
  .apexcharts-xaxistooltipv {
    display: none;
  }
  .credit-chart-summary {
    width: unset !important;
  }
}

.credit-chart-summary {
  flex-shrink: 0;
  flex-grow: 0;
  width: 19rem;
  background: var(--bg-01);
  border-radius: 4px;
  height: 32rem;
  margin: 1rem;
}

.credit-history-tooltip-wrapper {
  display: flex;
  flex-direction: column;
  width: 15rem;
  padding: 1rem 1.5rem;
  row-gap: 1rem;
  background-color: white;
  border: 1px solid var(--separator-01);
  box-sizing: border-box;
  box-shadow: 0px 4px 13px rgba(101, 135, 187, 0.25);
  border-radius: 8px;
}
