.landing-page {
  margin-top: 2rem;
}
.landing-page .landing-left {
  margin-top: auto;
  margin-bottom: auto;
  height: auto;
  width: 50%;
  padding-right: 10%;
}
.landing-page .landing-right {
  margin-top: 10%;
  margin-bottom: auto;
  height: auto;
  width: 50%;
}
.landing-page .landing-left > h1,
.landing-page .landing-left > div {
  margin-bottom: 4vh;
  margin-top: unset !important;
}
.landing-page .title-line-1 {
  font-family: Montserrat;
  font-style: normal;
  font-weight: bold;
  font-size: 28.7935px;
  line-height: 39px;
  color: var(--blue-01);
}
.landing-page .title-line-2 {
  font-family: Montserrat;
  font-style: normal;
  font-weight: bold;
  font-size: 48px;
  line-height: 60px;
  color: var(--text-01);
  margin-top: 2vh !important;
}
.landing-page .text-block-1 {
  font-family: Montserrat;
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 26px;
  color: var(--text-01);
}
.landing-page .text-block-2 {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  color: var(--text-01);
}
.landing-page .divider {
  width: 40%;
  height: 1rem;
  border-bottom: 2px solid var(--blue-01);
}
.landing-page .landing-left {
  margin-top: 10%;
}
.landing-page {
  background: url(../../../core/assets/landing-image-blur.png);
  background-position-x: 79%;
  background-size: 72%;
  background-repeat: no-repeat;
  background-position-y: 3%;
}
.public-page-wrapper {
  background: url("../../../core/assets/full-wire-background.svg") repeat-x;
  background-size: 100%;
  background-position-y: 84%;
}

.landing-page button {
  color: white;
  background-color: var(--blue-01);
  cursor: pointer;
  text-align: center;
  text-decoration: none;
  font-weight: bold;
  box-sizing: border-box;
  border: none;
  border-radius: 0.5rem;
  height: 3.5rem;
  width: 100%;
  padding: inherit auto;
  margin-top: 1rem;
}

.landing-page .small-button {
  width: 6.5rem !important;
  padding: 1rem !important;
  margin-top: unset !important;
}

.landing-page input {
  border: 2px solid var(--separator-01);
  border-radius: 6px;
}

.landing-page .input-horizontal-layout {
  gap: 1rem;
  max-width: 40rem;
  width: 100%;
}

.landing-page .form-title {
  font-family: "Montserrat", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 2rem;
  line-height: 110%;
  display: block;
  margin-bottom: 1rem;
  color: var(--text-01);
}

.landing-page .home__green-check {
  margin: 0 1rem;
  height: 32px;
  width: 32px;
  background: url("../../../core/assets/greencheck.svg") no-repeat center;
}

.landing-page .home__animation-holder {
  width: 0%;
  height: 275px;
  position: absolute;
  transition: width 0.75s;
  border-radius: 0.5rem;
  display: none;
}

.landing-page .home__text-animation-holder {
  display: none;
  flex-direction: row;
  height: 100%;
  align-items: center;
  visibility: hidden;
  opacity: 0;
  transition: opacity 1s;
  display: none;
  width: 100%;
}

.landing-page .home__text-animiation-success {
  display: flex;
  visibility: visible;
  opacity: 1;
}

.landing-page .home__success-animation {
  width: 100vw;
  background-color: #cfeedd;
  border: 1px solid #cfeedd;
  display: flex;
}

.landing-page .form {
  width: 100%;
  position: relative;
  padding-bottom: 2rem;
}

.landing-page .err {
  color: var(--red-01);
}

@media screen and (max-width: 992px) {
  .landing-page {
    background-size: 130%;
    background-position-y: 35%;
  }
  .landing-page .landing-left,
  .landing-page .landing-right {
    width: 100% !important;
    padding-right: 0 !important;
    margin-bottom: unset;
  }
  .landing-page .landing-left > div:last-child {
    margin-bottom: 0vh;
  }
  .landing-page .landing-right {
    margin-bottom: 2rem;
  }
}
