.transaction-right-aligned {
  text-align: right !important;
}

.transaction-row-exclude-toggle {
  cursor: pointer !important;
}

.table-head,
.table-row {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.table-cell {
  border: none;
  flex-grow: 0;
  flex-shrink: 0;
  padding: 0;
  flex-basis: unset;
}

.table-head .table-cell {
  font-family: "Montserrat", sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 11px;
  line-height: 12px !important;

  letter-spacing: 0.04em;
  text-transform: uppercase;

  color: var(--text-03);
}

.table-row .table-cell {
  font-family: "Montserrat", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 1rem;
  line-height: 150%;

  color: var(--navy);
}

.table-body {
  display: flex;
  flex-direction: column;
}

.table-head,
.table-row {
  gap: 0.5rem;
}

.table-row {
  border: none;
  width: 100%;
  padding: 1.5rem 0 0;
  display: flex;
}

.transaction-row-wrapper {
  border-bottom: 1px solid var(--separator-02);
}

@media screen and (max-width: 992px) {
  .transactions-table .table-head,
  .transactions-table .table-row {
    gap: 0.5rem;
  }
  .transactions-table .table-row {
    padding: 1rem 0 0;
  }
  .account-column {
    display: none !important;
  }
  .recurring-column {
    display: none !important;
  }
  .transaction-table .category-column {
    display: none !important;
  }
}

.transactions-table .table-cell {
  flex-shrink: 0;
  flex-grow: 0;
}

/* Column to fill rest of row*/
.table-cell.transaction-column {
  flex: 1 1 !important;
  width: unset !important;
}

.table-cell.account-column {
  width: 7.5rem;
  flex: 0 0 6.5rem;
  display: flex;
}

.table-cell.recurring-column {
  width: 4.5rem;
  flex: 0 0 4.5rem;
  display: flex;
}

.table-cell.category-column {
  width: 7.5rem;
  flex: 0 0 7.5rem;
  display: flex;
  overflow: visible;
}

.table-cell.income-column {
  width: 7.5rem;
  flex: 0 0 7.5rem;
  text-align: right;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
}

.table-cell.income-column .income {
  color: var(--green-02);
}

.transaction-row-transaction-enter {
  opacity: 0;
  transform: translate(-250px, 0);
  transition-delay: 1s;
}
.transaction-row-transaction-enter.transaction-row-transaction-enter-active {
  opacity: 1;
  transition: opacity 1s ease;
  transform: translate(0, 0);
  transform: translate3d(0, 0, 0);
  transition-property: transform, opacity;
  transition-duration: 300ms;
  transition-timing-function: cubic-bezier(0.175, 0.665, 0.32, 1), linear;
}

.transaction-row-transaction-exit {
  opacity: 1;
  transform: translate(0, 0, 0);
  transform: translate3d(0, 0, 0);
  transition-property: transform, opacity;
  transition-duration: 300ms;
  transition-timing-function: cubic-bezier(0.175, 0.665, 0.32, 1), linear;
}
.transaction-row-transaction-exit.transaction-row-transaction-exit-active {
  opacity: 0;
  transform: translate(250px, 0);
  transform: translate3d(250px, 0, 0);
}

.transaction-table-records {
  overflow-y: hidden;
}

.amount-column-menu-item {
  display: flex;
  padding: 0.25rem 0.5rem;
  cursor: pointer;
  gap: 0.5rem;
  align-items: center;
}

.amount-column-menu-item__text {
  font-family: "Inter", sans-serif;
  font-size: 14px;
  font-weight: 400;
  text-transform: none;
}

.amount-column-menu-item:hover {
  background-color: var(--yellow-50);
}

.amount-date-header {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.amount-date-toggle {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  padding: 0 0.5rem;
  line-height: 20px;
}

.amount-date-toggle:hover {
  background-color: var(--blue-50);
}

.empty-state-link:hover {
  text-decoration: underline;
  text-decoration-color: var(--blue-500);
}
