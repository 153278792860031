.pylon-toast-body {
  padding: 1rem;
  width: 100%;
  height: 100%;
  background: #f8f8fa;
  color: black;
  box-shadow: 0px 7px 14px rgba(0, 0, 0, 0.12);
  border-radius: 0.625rem;
  color: black;
}

.Toastify__toast-container {
  width: auto !important;
}

.Toastify__toast-container button {
  right: 3.25rem;
  top: 0.75rem;
  position: absolute;
}

.pylon-toast-body {
  width: 20rem;
  margin-right: 2rem;
}

.pylon-toast-body__success {
  background: #e6fbfb !important;
  box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.02) !important;
  border-radius: 10px !important;
}

.pylon-toast-body__error {
  background-color: #fff2f2;
  box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.02);
  border-radius: 10px;
}

.pylon-toast-body__error .pylon-toast-body__title {
  color: var(--red-01);
}

.pylon-toast-body__title {
  padding-bottom: 0.25rem;
  padding-top: 0.5rem;
  font-weight: 600;
  font-size: 1rem;
}

.pylon-toast-body__success .pylon-toast-body__title {
  color: #12bd89;
}

.pylon-toast-body__notification {
  background: #ebf9ff;
  box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.02);
  border-radius: 10px;
}

.pylon-toast-body__notification .pylon-toast-body__title {
  color: var(--blue-01);
}

@media screen and (max-width: 992px) {
  .Toastify__toast-container button {
    right: 1.25rem;
  }
  .Toastify__toast-body {
    width: 100% !important;
  }
  .pylon-toast-container {
    right: 0 !important;
    left: 0 !important;
    width: 100% !important;
  }
  .pylon-toast-body {
    width: 100% !important;
  }
}
