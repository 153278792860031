.compact-tax-results {
  bottom: 0;
  border-top-left-radius: 0.5rem;
  border-top-right-radius: 0.5rem;
  background-color: #f4f6fb;
  border: 2px solid var(--separator-01);
  display: flex;
  width: 100%;
}
.compact-tax-results > .main-tax-result > h6 {
  margin-bottom: 0;
}
.compact-tax-results > div:first-child {
  width: 55%;
  border-right: 2px solid var(--separator-01);
  display: flex;
}
.compact-tax-results > div:last-child {
  width: 45%;
  display: flex;
}

.tax-results {
  bottom: 0;
  height: 12.563rem;
  border-top-left-radius: 0.5rem;
  border-top-right-radius: 0.5rem;
  background-color: #f4f6fb;
  border: 2px solid var(--separator-01);
  display: flex;
  z-index: 1000;
}

.full-width {
  width: 100%;
}

.tax-results > div:first-child {
  width: 55%;
  border-right: 2px solid var(--separator-01);
  display: flex;
}
.tax-results > div:last-child {
  width: 45%;
  display: flex;
}
@media screen and (max-width: 992px) {
  .compact-tax-results,
  .tax-results {
    position: unset !important;
    height: auto;
    display: flex;
    width: 100%;
    z-index: 1000;
    flex-direction: column;
  }
  .compact-tax-results > div:first-child,
  .tax-results > div:first-child {
    width: 100%;
    padding: 3rem 1rem;
    border-bottom: 2px solid var(--separator-01);
    border-right: 0 !important;
  }
  .compact-tax-results > div:first-child > div,
  .tax-results > div:first-child > div {
    margin: 0 auto !important;
  }
  .compact-tax-results > div:last-child,
  .tax-results > div:last-child {
    width: 100% !important;
    flex-direction: column;
    display: flex;
    padding-top: 3rem;
    border-right: 0 !important;
    padding-bottom: 3rem !important;
  }
  .compact-tax-results > div:last-child > div,
  .tax-results > div:last-child > div {
    border-right: 0 !important;
    width: 100%;
    justify-content: space-evenly;
  }

  .compact-tax-results > div:last-child > div:first-child,
  .tax-results > div:last-child > div:first-child {
    border-right: 0 !important;
    border-bottom: 2px solid var(--separator-01);
    padding-bottom: 3rem !important;
    width: 100%;
  }
  .compact-tax-results > div:last-child > div:last-child,
  .tax-results > div:last-child > div:last-child {
    padding-top: 3rem !important;
  }
  .compact-tax-results > div:last-child > div span,
  .tax-results > div:last-child > div span {
    margin-bottom: 1rem;
    display: inline-block;
  }

  .compact-tax-results > div:last-child > div > div,
  .tax-results > div:last-child > div > div {
    width: 80% !important;
  }
  .compact-tax-results > div:last-child > div hr,
  .tax-results > div:last-child > div hr {
    width: 25% !important;
  }
  .main-tax-result > div > div:first-child > div:first-child {
    width: 75% !important;
    margin-bottom: 1rem;
  }
  .main-tax-result h6 {
    text-align: center;
  }
}
