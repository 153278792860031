.landing-page__register_button_2 {
  padding: 0 2rem;
  width: fit-content !important;
  background-color: var(--alt-blue) !important;
  height: 4.5rem !important;
  border: unset !important;
  border-radius: unset;
}

.landing-page__register_button_2:hover {
  background-color: var(--alt-blue-2) !important;
}

.landing-page__register_button_2__round-corner {
  border-radius: 8px;
}

.landing-page__register_button_2.landing-page__register_button_2__black-background {
  background-color: #000000 !important;
}

.landing-page__register_button_2.landing-page__register_button_2__black-background:hover {
  background-color: #333333 !important;
}
