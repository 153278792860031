.manage-collaborators-tray {
  display: flex;
  width: 100%;
  transition: width 5s ease;
}

.manage-collaborators-tray__content {
  width: 100%;
  overflow-y: unset;
}

@media screen and (max-width: 992px) {
  .manage-collaborators-tray__content .react-tabs ul {
    padding-inline-start: 0;
    display: flex;
    justify-content: space-evenly;
    margin-bottom: 3rem;
  }
  .manage-collaborators-tray__content .react-tabs ul li {
    width: 150px;
    text-align: center;
    padding-bottom: 0.5rem;
  }
}
