.manage-account-tray {
  margin: 1rem;
}

.manage-account-tray__info-row {
  display: flex;
  flex-direction: column;
  margin: 1rem 0;
}
.manage-account-tray__info-subrow {
  min-height: 2rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 0.5rem;
}

.manage-account-tray__info-subrow-bold-text {
  font-size: 20px;
  font-weight: 600;
}

.manage-account-tray__info-subrow-light-text {
  font-size: 14px;
  font-weight: 400;
}
.manage-account-tray__info-subrow-tag {
  background-color: rgba(181, 190, 202, 0.3);
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0.25rem 0.5rem;
  font-size: 10px;
  font-weight: 600;
  border-radius: 2px;
}

.manage-account-tray__info-subrow-buttons {
  display: flex;
  align-items: center;
}

.manage-account-tray__balance-row-text {
  font-size: 13px;
  font-weight: 600;
}

.manage-account-tray__balance-row-balance {
  font-size: 32px;
  font-weight: 600;
}

.manage-account-tray__tag-section {
  border-bottom: 1px solid var(--gray-10);
}

.manage-document-tray__tag-wrapper {
  padding-left: 0;
  padding-top: 1rem;
  padding-right: 0.5rem;
}
