.security-wrapper {
  overflow: auto;
  background-repeat: repeat-x;
  background-size: 100%;
  background-position-y: 100%;
  background-color: white;
  background-image: url("../../../../core/assets/full-wire-background.svg");
}

.sec-right {
  height: 3rem;
  width: 100vw;
  position: absolute;
  right: 0;
  top: 0px;
  left: auto;
  background-color: white;
  display: flex;
  align-items: center;
}

.sec-right .flat-back {
  width: 40vw;
  height: 100vh;
  background-color: var(--bg-01);
  z-index: 1;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
}
.sec-right a {
  font-family: Montserrat;
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 39px;
  color: var(--text-03);
  right: 3vw;
  top: 3vh;
  text-align: right;
  z-index: 3;
  margin: 0 1rem;
}
.sec-right .bg {
  height: 100vh;
  width: 100vh;
  background-color: transparent;
  background-image: url("../../../../core/assets/landing-page.png");
  background-size: 100%;
  background-repeat: no-repeat;
  background-position-x: 100%;
  background-position-y: 100%;
  z-index: 2;
  position: absolute;
  right: 0;
  top: 0;
  overflow: hidden;
}
