.login-page-wrapper {
  overflow-y: auto;
  width: 100%;
  height: 100%;
  background-color: white;
  background-image: url("../../../core/assets/full-wire-background.svg");
  background-repeat: repeat-x;
  background-size: 100%;
  background-position-y: 100%;
}
