.cashflow-statement-row {
  display: flex;
  justify-content: space-between;
  cursor: pointer;
  padding: 0 0.5rem;
  margin-left: 1rem;
}
.cashflow-statement-row:nth-child(2n) {
  background-color: #eeeeee;
}

.cashflow-statement-row:hover {
  background-color: var(--blue-04);
}
