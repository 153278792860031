.notification-cell {
  height: 100%;
  padding: 0rem 0.5rem;
}

.notification-cell__date {
  display: flex;
  align-items: center;
  width: 6.5rem;
  flex-grow: 0;
  flex-shrink: 0;
  color: var(--text-03);
  font-size: 0.875rem;
  font-weight: bold;
}

.notification-cell__message {
  display: flex;
  align-items: center;
  flex-grow: 1;
  flex-shrink: 1;
  padding-right: 1rem;
}

.notification-cell__action {
  display: flex;
  align-items: center;
  flex-grow: 0;
  flex-shrink: 0;
  min-width: 5rem;
}

.notification-row {
  display: flex;
  align-items: center;
  max-width: 65rem;
  flex-direction: row;
  border-bottom: 1px solid var(--gray-10);
  color: var(--text-01);
  padding: 1rem 1rem;
}

.notification-header.notification-row {
  border-bottom: 1px solid var(--separator-02);
  color: var(--text-03);
}

.notification-header .notification-cell {
  justify-content: start;
}

.notification-row__clickable-area {
  cursor: pointer;
}

@media screen and (max-width: 992px) {
  .notification-header {
    display: none;
  }
  .notification-row {
    margin-bottom: 2rem;
    padding: 0.5rem 0rem;
    padding-bottom: 2rem;
    flex-direction: column;
    align-items: flex-start;
  }
}
