.growth-chart-header {
  float: right;
  margin-top: -3.25rem;
  width: 20rem;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}
.growth-chart-header .set-years {
  display: flex;
  flex-wrap: nowrap;
  height: 3rem;
  width: 13rem;
  align-items: center;
}

.growth-chart-header .set-years input {
  margin-left: 0.25rem;
  margin-right: 0.25rem;
}

.growth-chart-header-mobile .set-years {
  display: flex;
  flex-wrap: nowrap;
  height: 3rem;
  width: 100%;
  align-items: center;
}
.growth-chart-header-mobile .set-years .lhs {
  width: 4.25rem;
  margin-left: auto;
}
.growth-chart-header-mobile .set-years .rhs {
  width: 6rem;
  margin-right: auto;
  margin-left: 0.25rem;
}

.input-years input {
  height: 3rem;
  width: 4rem;
  text-align: center;
}

.growth-chart .summary-box {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: var(--bg-01);
  padding: 1rem 2rem;
}

.growth-chart .summary-box.mobile {
  flex-direction: column;
  padding: 1rem 0.5rem;
}

.source-type {
  height: 4rem;
  width: 20rem;
  background-color: white;
  border-radius: 0.5rem;
  padding-right: 0.5rem;
  font-weight: 600;
  margin-top: 0.5rem;
}

.source-type.mobile {
  width: 100%;
  margin-left: 0.5rem;
}

.summary-box > div:first-of-type {
  flex-direction: column;
}

.source-type .value-in-year {
  margin-right: 3rem;
  font-weight: bolder;
}
.source-type .value-in-amount {
  font-size: medium;
}

.growth-value {
  font-weight: bolder;
  margin-right: 3rem;
}
.growth-row {
  display: flex;
  flex: 0 0 100%;
}

.growth-row .interest-rate {
  font-weight: bolder;
}

.growth-row .section-label {
  margin-left: 0.25rem;
}

.growth-row .edit-apr {
  margin-left: 1rem;
  margin-top: -2px;
}
