.reconnect-text {
  display: none;
}
.reconnect-warning:hover .reconnect-text {
  display: flex;
}

.reconnect-warning {
  display: flex;
}
