.sharing-dropdown {
  display: inline-block;
  margin-left: 0.5rem;
  border: 2px solid var(--gray-30);
  border-radius: 6px;
  cursor: pointer;
}

.show-all {
  display: flex;
  height: 4.125rem;
  align-items: center;
  padding-right: 0px;
  border-bottom-style: none;
}

.show-all-badge {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 2rem;
  height: 2rem;
  border-top-left-radius: 2rem;
  border-top-right-radius: 2rem;
  border-bottom-right-radius: 2rem;
  border-bottom-left-radius: 2rem;
  background-color: var(--gray-70);
  color: white;
  margin-right: 1rem;
  margin-left: 1rem;
  font-weight: 700;
  font-size: 0.625rem;
}

.show-all-text {
  color: var(--gray-70);
  font-weight: 500;
  font-size: 0.875rem;
  margin-left: 4rem;
}

.show-all.border {
  border: none !important;
  border-bottom: 1px solid var(--gray-10) !important;
}
.recipient-group-label {
  margin-left: 1rem;
  margin-right: 1rem;
}

.sharing-caret {
  height: 1.25rem;
  width: 1.25rem;
  margin-left: 0.25rem;
  display: inline-block;
  vertical-align: middle;
  background: url("../../../../../core/assets/down-arrow.svg") 0px center
    no-repeat;
}
.sharing-caret-closed {
  height: 1.25rem;
  width: 1.25rem;
  margin-left: 0.25rem;
  display: inline-block;
  vertical-align: middle;
  background: url("../../../../../core/assets/down-arrow.svg") 0px center
    no-repeat;
  transform: rotate(-90deg);
}
