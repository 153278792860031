.wizard-modal-content {
  z-index: 1001;
  position: fixed;
  top: 25%;
  left: 50%;
  transform: translate(-50%, -20%);
  min-width: 30rem;
  /* height: 30rem; */
  background-color: white;
  border-radius: 0.5rem;
}

.wizard-modal-bg {
  z-index: 1000;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: var(--dark-navy-70);
}

.wizard-modal-content__title {
  height: 6rem;
  font-size: 24px;
  font-weight: bolder;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.wizard-modal-content__title-text {
  margin-left: auto;
  margin-right: auto;
}

.wizard-modal-content__title-x {
  cursor: pointer;
  position: absolute;
  right: 2rem;
  width: 24px;
  height: 24px;
  background: url("../../../core/assets/Close.svg") 0px center no-repeat;
}

.wizard-modal-content__children {
  margin: 1rem 2rem;
  margin-top: 0.5rem;
}

.wizard-modal-content__buttons {
  margin: 1rem 2rem;
  margin-top: 0.5rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
}

.wizard-modal-content__buttons > button {
  border-radius: 0.5rem;
  margin-left: 1rem;
}

.wizard-modal-info {
  padding: 1rem;
  font-size: 14px;
  border: 1px solid #d7d7d7;
  border-radius: 0.5rem;
  margin-bottom: 1rem;
}

.wizard-modal-opts__wrapper {
  display: flex;
  flex-direction: column;
}

.wizard-modal-opts__wrapper-item-row {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-evenly;
  flex-wrap: wrap;
  margin-bottom: 1rem;
}

.wizard-modal-opts__wrapper-item-row > button {
  width: 100%;
}

.wizard-modal-opts__wrapper-item {
  cursor: pointer;
  height: 8rem;
  width: 8rem;
  border: solid 1px #3c64b1;
  border-radius: 0.5rem;
  margin: 1rem;
  margin-top: 0;
  text-align: center;
  /* font-size: 14px; */
}

.wizard-modal-opts__wrapper-item:hover {
  background-color: #f3f6fd;
}

.modal-base.new-modal-base {
  border-radius: 0;
}
