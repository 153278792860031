.onboarding-card {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 12px;
  border: 2px solid var(--gray-30);
  background-color: var(--bg-02);
  width: 14.75rem;
  height: 12.625rem;
  margin: 0.5rem;
  cursor: pointer;
  line-height: 1.25rem;
  position: relative;
}

.onboarding-card__selected.smaller,
.onboarding-card.smaller {
  width: 14%;
  height: 20vh;
}

.onboarding-card__selected.summary,
.onboarding-card.summary {
  width: 20%;
  height: 20vh;
}

.onboarding-card.large,
.onboarding-card__selected.large {
  width: 19%;
  height: 27vh;
}

.onboarding-card.disabled,
.onboarding-card__selected.disabled {
  cursor: default;
}

.onboarding-card__selected {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 12px;
  border: 2px solid var(--green-01);
  background-color: #e1fff1;
  width: 14.75rem;
  height: 12.625rem;
  margin: 0.5rem;
  cursor: pointer;
  position: relative;
}

.onboarding-card__selected:hover {
  background-color: #e8fcf3;
}

.onboarding-card:hover {
  background-color: var(--bg-01);
}
.onboarding-card__icon {
  height: 60%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: -4vh;
}

.onboarding-card__icon.smaller {
  height: 40%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.onboarding-card__text {
  text-align: center;
  width: 100%;
  font-size: 0.875rem;
  font-weight: 400;
  color: var(--text-01);
  height: 20%;
  line-height: 1.25rem;
  position: absolute;
  bottom: 1rem;
}
