.left-nav-item-container {
  position: relative;
}
.left-nav-container {
  min-width: 17rem;
  max-width: 17rem;
  margin-right: 2rem;
  height: 100vh;
  position: fixed;
  background: var(--nav-bg);
  z-index: var(--z-index-left-nav);
  border-right: 1px solid var(--gray-100);
}

.left-nav__options-menu {
  position: absolute;
  left: 210px;
  top: 80px;
  background: white;
  width: 240px;
  z-index: var(--z-index-left-nav);
}

.left-nav-brand {
  display: flex;
  margin: 1.5rem 0.5rem;
}

.left-nav__contents {
  margin-top: 1.5rem;
}
.left-nav__contents-item {
  padding: 0.5rem 0.75rem;
  cursor: pointer;
}

.left-nav__contents-item-selected {
  background-color: var(--white);
  border-radius: 0rem;
  color: #ffffff;
}
.left-nav-divider {
  width: 82%;
  border: 1px solid var(--yellow-600);
  margin: 0 auto;
  opacity: 0.5;
}

.left-nav__contents-bottom .left-nav-divider {
  margin-top: 1.5rem;
  margin-bottom: 1.5rem;
}

#financial-plan {
  bottom: 0;
  position: fixed;
  width: calc(17rem - 1px);
  padding: 16px 24px;
  padding-bottom: 24px;
  background: white;
}

@media screen and (max-width: 992px) {
  .left-nav-container > div:nth-child(1) {
    display: none;
  }

  #financial-plan {
    border-top: 1px solid #eeeeee;
    width: 100vw;
  }
  .bottom-menu-cell {
    width: 50%;
    text-align: center;
    height: 40vw;
    vertical-align: middle;
    display: flex;
    flex-direction: column;
    justify-content: center;
    border-bottom: 2px solid var(--separator-02);
  }
  .show-nav .left-nav-container .flex-column .flex-row:first-child {
    margin-top: 2rem;
    border-top: 2px solid var(--separator-02);
  }
  .bottom-menu-cell:first-child {
    border-right: 2px solid var(--separator-02);
  }

  .bottom-menu-cell > a > div:first-child {
    margin-bottom: 1rem;
  }

  .bottom-menu-cell a {
    text-decoration: none;
    color: var(--text-02);
    font-size: 1.125rem;
    font-family: "Montserrat";
    font-weight: 500;
  }

  .left-nav-item {
    padding-left: 0;
  }

  .show-nav .left-nav__contents {
    margin-top: 1.5rem;
    padding-bottom: 7rem;
  }

  .show-nav .left-nav-container {
    display: block;
    position: absolute;
    top: 86px;
    bottom: 0;
    right: 0;
    left: 0;
    max-width: unset;
    margin: 0;
    z-index: var(--z-index-left-nav);
    overflow: auto;
  }
  .show-nav .left-nav-item-selected {
    background: none;
  }
  .show-nav .left-nav-item-container {
    width: auto;
  }
  .show-nav .left-nav-item-container:nth-last-child(2) {
    border-bottom: 0;
  }
  .show-nav .left-nav-item__section-header {
    font-size: 1.125rem;
    padding-left: 1.25rem;
  }
}
