.pylon__moved-calculator {
  --title-height: 5rem;
  display: flex;
  flex-direction: column;
}

.pylon__moved-calculator__title {
  height: var(--title-height);
  padding-top: 3rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-bottom: 3rem;
}

.pylon__moved-calculator__right-side {
  padding-top: 3.5rem;

  max-width: 60rem;
}

.pylon__moved-calculator__input {
  flex-basis: 50%;
}

.pylon__moved-calculator__input:nth-child(odd) {
  padding-right: 3rem;
}

.pylon__moved-calculator__input-wrapper {
  display: flex;
  flex-wrap: wrap;
  padding-top: 1rem;
  padding-right: 2.5rem;
}

.pylon__moved-calculator__left-side {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 1rem 3rem;
  flex-shrink: 0;
  position: sticky;
  top: 0;
}

.pylon__moved-calculator__result-card {
  border-radius: 12px;
  background: var(--bg-01);
  width: 21.75rem;
  padding: 1.5rem 1.75rem;
  display: flex;
  flex-direction: column;
}

.pylon__moved-calculator__result-card__title {
  display: flex;
  justify-content: space-between;
}

.pylon__moved-calculator__result-card__padding {
  padding-top: 1.25rem;
}

.pylon__moved-calculator__result-card__separator {
  margin-top: 0.25rem;
  border-bottom: 1px solid var(--separator-01);
  margin-bottom: 0.25rem;
}

.pylon__moved-calculator-tab-list {
  padding-inline-start: 0 !important;
}

.pylon__moved-calculator-tab__tab-title {
  display: inline-block;
  position: relative;
  list-style: none;
  margin-top: 1rem;
  margin-right: 2rem;
  cursor: pointer;
  font-family: "Montserrat", sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 0.875rem;
  line-height: 1.5rem;
  color: var(--text-03);
  text-transform: uppercase;
}

.pylon__moved-calculator-tab__tab-title-selected {
  color: var(--blue-01);
}

/* .react-tabs__tab-panel--selected.pylon__moved-calculator-panel__content {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
} */
