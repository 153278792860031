.modal-base {
  border: 2px;
  box-shadow: 0px 24px 48px rgba(0, 0, 0, 0.07);
  border-radius: 10px;
  background: rgb(255, 255, 255);
  border-radius: 10px;
  position: absolute;
  top: 50vh;
  left: 50%;
  transform: translate(-50%, -50%) !important;
}
.modal-base-dc {
  border: 2px;
  background: rgb(255, 255, 255);

  position: absolute;
  top: 50vh;
  left: 50%;
  transform: translate(-50%, -50%) !important;

  padding: 40px;

  box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.16);
}

.overlay-base {
  background-color: rgba(0, 0, 0, 0.31);

  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1000;
}

.overlay-base.overlay-base--low-priority {
  z-index: 100;
}
