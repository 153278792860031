.cashflow-tooltip {
  padding: 1rem;
  display: flex;
  flex-direction: column;
  font-family: "Montserrat";
}
.cashflow-tooltip > div {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  max-width: 20rem;
}

.cashflow-tooltip > div > div:first-child {
  margin-right: 1rem;
}

.category-item-entry__category {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  padding-right: 0.5rem;
}

.cashflow-chart-and-summary > div:first-child {
  padding-right: 2rem;
}
.cashflow-categorized-summary > div {
  width: 25%;
}
.cashflow-categorized-summary > div:first-child {
  width: 75%;
}
@media screen and (max-width: 992px) {
  .apexcharts-xaxistooltipv {
    display: none;
  }
  .cashflow-chart-and-summary > div {
    padding-right: 0 !important;
    width: 100% !important;
  }
}

.cashflow-summary {
  flex-shrink: 0;
  flex-grow: 0;
  width: 19rem;
  background: var(--bg-01);
  border-radius: 4px;
  height: 32rem;
}

.cashflow-chart .apexcharts-tooltip {
  border: unset;
  background: unset;
}

.cashflow-tooltip-wrapper {
  display: flex;
  flex-direction: column;
  width: 15rem;
  height: 9rem;
  padding: 1rem 1.5rem;
  row-gap: 1rem;
  background-color: white;
  border: 1px solid var(--separator-01);
  box-sizing: border-box;
  box-shadow: 0px 4px 13px rgba(101, 135, 187, 0.25);
  border-radius: 8px;
}

.cashflow-tooltip-title {
  align-self: center;
  font-family: "Montserrat", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 0.875rem;
  line-height: 1.375rem;
  color: var(--text-01);
}

.cashflow-tooltip-row {
  display: flex;
  justify-content: space-between;
  font-family: "Montserrat", sans-serif;
  font-size: 0.875rem;
  font-style: normal;
  line-height: 1 375rem;
  color: var(--text-01);
}

.cashflow-tooltip-row .tooltip-title {
  font-weight: 500;
}
.cashflow-tooltip-row .tooltip-value {
  font-weight: 400;
}
