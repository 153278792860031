.row-card-wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0;
  cursor: default;
}

.row-card {
  cursor: pointer;
  width: 100%;
  display: flex;
  height: 83px;
  border-radius: 10px;
  align-items: center;
  padding: 0 1rem;
}

.example-row {
  opacity: 0.5 !important;
  cursor: initial !important;
  padding-left: 2rem;
}

.example-row:hover,
.example-row:active {
  background-color: "none";
}

.row-card-wrapper .example {
  color: #eeeeee;
}
.row-card:hover {
  background-color: var(--gray-02);
}

.row-card__icon {
  height: 48px;
  width: 48px;
  color: white;
  background-color: rgba(182, 224, 242, 0.2);
  border-radius: 0.75rem;
  justify-content: center;
  margin-left: 0.5rem;
  margin-right: 1rem;
  display: flex;
  align-items: center;
  position: relative;
}

.row-card__content {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
}

.row-card .account__icon {
  margin-left: 0.5rem;
}

.row-card__content-title-text {
  color: var(--navy);
  font-size: 16px;
  font-weight: 600;
  white-space: normal;
}
.row-card__content-subtext-wrapper {
  display: flex;
  align-items: center;
}

.row-card__content-subtext {
  color: var(--gray-70);
  font-size: 14px;
}
.row-card__content-subtext-divider {
  color: var(--gray-70);
  font-size: 14px;
  margin: 0 0.5rem;
}
.row-card__balance {
  color: var(--navy);
  font-size: 16px;
  font-weight: 700;
}

.elipsis-more {
  height: 32px;
  width: 19px;
  cursor: pointer;
  margin-right: 0.5rem;
  fill: var(--navy);
  background: url("../../../../../core/assets/more-elipsis.svg") no-repeat
    center;
  position: relative;
  border: transparent;
}
.row-card__shared-indicator {
  height: 2.25rem;
  width: 2.25rem;
  background: url("../../../../../core/assets/shared-indicator.svg") no-repeat
    center;
  position: absolute;
  bottom: -0.75rem;
  right: -0.75rem;
}
.error-outline {
  margin: 0 0.5rem;
  height: 1.5rem;
  width: 1.5rem;
  fill: var(--red-01);
  display: inline-block;
  background: url("../../../../../core/assets/error-outline.svg") no-repeat
    center;
}
.error-outline path {
  fill: red;
}
.row-card__source-type {
  background-color: rgba(181, 190, 202, 0.3);
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0.25rem 0.5rem;
  font-size: 10px;
  font-weight: 600;
  border-radius: 2px;
  margin-right: 0.5rem;
}

.row-card__content-subtext-shared-by {
  color: var(--gray-70);
  font-size: 14px;
  display: flex;
  align-items: center;
}
.row-card__content-subtext-shared-by-text {
  margin-right: 0.25rem;
}
.row-card__content-subtext-shared-by-name {
  color: var(--navy);
  font-weight: 600;
}

.row-card__more {
  flex-grow: 0;
  flex-shrink: 0;
  align-self: center;
  cursor: pointer;
}

.row-card__linkable-area {
  width: 100%;
}

.card__one-day-change {
  text-align: right;
}

.row-card .row-card-recipients {
  display: flex;
}
.row-card__content-subtext.official-name {
  max-width: 26rem;
}
@media screen and (max-width: 992px) {
  .row-card-wrapper:last-child {
    border-bottom: none;
  }
  .row-card {
    -ms-flex-direction: column;
    flex-direction: column;
    align-items: flex-start;
    height: auto;
    justify-content: space-evenly;
    position: relative;
  }
  .row-card__title {
    align-self: flex-start;
  }
  .card__balance,
  .row-card__balance {
    font-family: "Montserrat" !important;
    font-weight: 400;
  }
  .card__one-day-change {
    font-size: 1rem;
    text-align: left;
    display: inline-flex;
    margin-left: 0.5rem;
  }
  .row-card .last-updated {
    display: none;
  }
  .row-card .row-card-recipients {
    display: none;
  }
  .row-card__content-subtext.official-name {
    max-width: 75vw;
  }

  .row-card__content-title-text {
    white-space: nowrap;
    overflow-x: hidden;
    text-overflow: ellipsis;
  }
  .row-card__content.table-cell.flex-row {
    width: 50% !important;
  }

  .Collapsible__contentInner {
    padding-left: 0 !important;
  }
  .table-cell.owner {
    display: none;
  }
}
