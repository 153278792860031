.side-tray__parent {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  width: 100vw;
  max-width: 100%;
}

.side-tray__show {
  display: flex;
}

.side-tray__hidden {
  display: hidden; /* Keep component mounted to not lose state/callabck/etc */
}

.side-tray__background {
  z-index: 1000;
  flex-grow: 1;
  background-color: transparent;
}

.side-tray {
  z-index: 1000;
  background-color: white;
  width: 0%;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
}

.side-tray > div {
  padding: 0 2rem;
}

.side-tray__inner-header {
  flex: 1 1 auto;
}

.tray-open {
  width: 35rem;
  box-shadow: -10px 0px 84px 1px var(--separator-01);
  background-color: white;
  background-repeat: repeat-x;
  background-size: 100%;
  background-position-y: 100%;
}

@media screen and (max-width: 992px) {
  .tray-open {
    width: 100%;
  }
  .side-tray > div {
    padding: 0 4vw;
  }
}

.side-tray {
  padding: 0;
}
.side-tray > div.side-tray__header {
  padding: 1.5rem;
  height: 7rem;
}
.side-tray__header {
  display: flex;
  justify-content: space-between;
}
.side-tray__header > div {
  align-self: center;
  margin-right: 1rem;
}

.side-tray__header > div:last-child {
  margin-right: 0rem;
  /* margin-top: 1.25rem; */
}

.side-tray__close {
  cursor: pointer;
  width: 38px;
  height: 38px;
  background: url("../../../core/assets/Close.svg") 0px center no-repeat;
  cursor: pointer;
}
.side-tray__back {
  cursor: pointer;
  width: 38px;
  height: 38px;
  background: url("../../../core/assets/Back.svg") 0px center no-repeat;
}

.back-hidden {
  visibility: hidden;
  display: none;
}
