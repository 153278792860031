.left-nav-item {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  cursor: pointer;
  padding-top: 1rem;
  padding-bottom: 1rem;
}

.left-nav-item > a,
.left-nav-item-container > a,
.left-nav-item__subsection-container > a {
  color: inherit;
  text-decoration: none;
}
left-nav-item-container-selected {
  border-radius: 0;
}
.left-nav-item > a:hover,
.left-nav-item-container > a:hover,
.left-nav-item__subsection-container > a:hover {
  color: inherit;
  text-decoration: none;
}

.left-nav-item-container:hover {
  background: var(--nav-hover-bg);
}
.left-nav-item-container:active {
  background: var(--nav-pressed-bg);
}

/* 
.left-nav-item:hover > .left-nav-item__section-header > div {
  border-bottom: 1px solid #000000;
  box-sizing: border-box;
} */

.left-nav-item > .left-nav-item__section-header > div:after {
  content: ""; /* This is necessary for the pseudo element to work. */
  position: block;
  display: block; /* This will put the pseudo element on its own line. */
  width: 0;
  padding-top: 0.25rem; /* This creates some space between the element and the border. */
  border-bottom: 1px solid #000000; /* This creates the border. Replace black with whatever color you want. */
  transition: 0.35s; /* This establishes the amount of time in seconds the animation should take from start to finish */
}

.left-nav-item > .left-nav-item__section-header > div:hover:after {
  width: 100%;
}

.left-nav-item-selected {
  background-color: var(--nav-selected-bg);
  font-weight: 600;
  color: var(--nav-selected-text);
  border-radius: 0;
}

.left-nav-item-selected:active {
  background-color: var(--nav-pressed-bg);
}
.left-nav-item__subsection-item {
  display: flex;
  font-size: smaller;
  /* margin: 1rem; */
  /* margin-left: 0; */
  cursor: pointer;
  width: fit-content;
  width: 100%;
  /* padding: 0.75rem; */
  padding-left: 1rem;
  /* border-radius: 0.5rem; */
}

.left-nav-item__section-header {
  font-weight: bold;
  display: flex;
  flex-direction: row;
  /* justify-content: center; */
  align-items: center;
  font-weight: 500;
  font-size: 0.875rem;
  color: var(--black);
  border-radius: 0;
  font-family: "Montserrat";
  margin: 0;
  border-left: 4px solid transparent;
  padding-left: 1rem;
}

.left-nav-item-selected .left-nav-item__section-header {
  color: var(--nav-selected-text);
  border-left: 4px solid var(--yellow-500);
}

.left-nav-item__subsection-container {
  width: 100%;
}

.left-nav-item__subsection-item-selected {
  background-color: #4c82e2;
  color: #000000;
  /* background-color: #ffffff;
  color: #4e4b66; */
}

.left-nav-item__subsection-item:hover {
  /* background-color: #ffffff; */
  /* color: #4e4b66; */
}
.left-nav-item__subsection-item-text {
  margin-left: 0.5rem;
}

.left-nav-item__subsection-item-text:after {
  content: ""; /* This is necessary for the pseudo element to work. */
  position: block;
  display: block; /* This will put the pseudo element on its own line. */
  width: 0;
  /* padding-top: 0.25rem; This creates some space between the element and the border. */
  border-bottom: 1px solid #000000; /* This creates the border. Replace black with whatever color you want. */
  transition: 0.35s; /* This establishes the amount of time in seconds the animation should take from start to finish */
}

.left-nav-item__subsection-item-text:hover:after {
  width: 100%;
}
