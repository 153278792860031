.confirmation-modal-content-wrapper {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  width: 100%;
  height: 100%;
  max-height: 100vh;
  overflow: auto;

  padding-top: 2rem;
  padding-bottom: 2rem;
  padding-left: 1.5rem;
  padding-right: 1.5rem;
}

@media screen and (max-width: 992px) {
  .modal-button.confirmation-modal-content-wrapper {
    min-width: 100%;
  }
}
