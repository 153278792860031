.document-row__section-wrapper {
  padding: unset;
}

.document-row__tag-wrapper {
  padding-left: unset;
  padding-bottom: unset;
  padding-right: 0.5rem;
}

.document-row__card {
  cursor: pointer;
  width: 100%;
  display: flex;
  border-radius: 10px;
  align-items: center;
  padding: 1rem 0.5rem;
}

.document-row__card:hover {
  background-color: var(--gray-02);
}
