.pylon-link {
  font-family: "Montserrat", sans-serif;
  color: #09274d;
  text-decoration: none;
}
.pylon-link.pylon-link__inherit-colour {
  color: inherit;
}
.pylon-link.pylon-link__inherit-colour:link {
  color: inherit;
}
.pylon-link.pylon-link__inherit-colour:visited {
  color: inherit;
}
.pylon-link.pylon-link__inherit-colour:hover {
  color: inherit;
  text-decoration: none;
}
.pylon-link.pylon-link__inherit-colour:focus {
  color: inherit;
}
.pylon-link.pylon-link__inherit-colour:active {
  color: inherit;
}

.pylon-link__default {
  font-style: normal;
  font-weight: 400;
  font-size: 0.875rem;
  line-height: 1.813rem;
}

.pylon-link__highlight {
  font-style: normal;
  font-weight: 600;
  font-size: 1rem;
  line-height: 2rem;
}

.pylon-link__hint {
  font-family: Helvetica;
  font-weight: 400;
  font-size: 0.875rem;
  color: #8493a6 !important;
}

.pylon-link__hint-blue {
  font-family: Helvetica;
  font-weight: 400;
  font-size: 0.875rem;
  color: var(--blue-01);
}

.pylon-link:link {
  color: var(--blue-01);
}
.pylon-link:visited {
  color: var(--blue-01);
}
.pylon-link:hover {
  color: var(--blue-01);
  text-decoration: none;
}
.pylon-link:focus {
  color: var(--blue-01);
}
.pylon-link:active {
  color: var(--blue-01);
}
