.login-form-input-wrapper {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}

.login-form-input-wrapper > input {
  height: 3.875rem;
}
.login-field-wrapper .pylon-input,
.login-form-input-wrapper .pylon-input,
.pylon-login-datepicker {
  height: 3.875rem;
  min-height: 3.875rem;
  font-size: 1.25rem;
}
