/* .pylon-input {
  padding-left: 1rem;
  line-height: 2.75rem;
  border-radius: 6px;
  width: 100%;
  height: 3rem;
  border: 2px solid var(--separator-01);
  margin-bottom: 1rem;
} */

input.pylon-input::placeholder {
  color: var(--gray-30);
}

input.pylon-input:disabled {
  background-color: white;
}

.pylon-input {
  /* styleName: montseratt/15/regular; */
  font-family: Montserrat;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  background: #f6f6f6;
  padding: 0.75rem 1rem;
  margin: 0px;
  margin-bottom: 0px;
  outline: none;
  border-radius: 0px;
  border: 2px solid transparent;
}

.react-datepicker__input-container > .pylon-input {
  border: 2px solid transparent;
  border-radius: 0px;
}

.pylon-input::placeholder {
  color: #757575 !important;
}

.pylon-input:hover {
  border: 2px solid #afafaf;
}

.pylon-input:active {
  background: #e2e2e2;
  border: 2px solid #afafaf;
}

.pylon-input:focus {
  border: 2px solid #000000;
}
