.table-cell.value,
.table-cell.owner {
  width: 6rem;
}
.table {
  margin-bottom: 40px;
}

.table.accounts {
  margin-bottom: 0px;
}
.table-head {
  border-bottom: 1px solid #eeeeee;
  border-top: 1px solid #eeeeee;
  height: 32px !important;
}

.table-row {
  min-height: 60px !important;
  border-bottom: 1px solid #eeeeee;
}
.table-row.no-border {
  border-bottom: none;
}
.table-row.disabled {
  background-color: white;
}
.table-row.disabled svg,
.table-row.disabled img {
  opacity: 0.2;
}

.table-row.disabled,
.table-row.disabled > div,
.table-row.disabled > div > div {
  color: var(--text-03);
}

.table-head,
.table-row {
  padding: 0 1rem !important;
  padding-left: 0.5rem !important;
  font-weight: 400;
  position: relative;
  color: var(--text-01);
}

.table-cell {
  font-size: 15px !important;
  line-height: 24px !important;
  white-space: nowrap;
}

.transactions-table .mt1 {
  display: none;
}

.row-card-wrapper {
  position: relative;
}
.row-card-wrapper.draggable {
  padding-left: 2rem;
}

.row-card:hover {
  background-color: transparent !important;
}

.Collapsible__trigger > div {
  height: 40px;
}

.Collapsible__trigger:hover > div {
  background-color: var(--yellow-50) !important;
}

.table.selectable .table-row:hover,
.table-row.selectable:hover,
.row-card-wrapper.selectable:hover {
  background-color: var(--yellow-50) !important;
  cursor: pointer;
}

.table.selectable .table-row.disabled:hover,
.table-row.selectable.disabled:hover,
.row-card-wrapper.selectable.disabled:hover {
  background-color: unset !important;
  cursor: unset;
}
.table.selectable .table-row:active,
.table-row.selectable:active,
.row-card-wrapper.selectable:active {
  background-color: var(--yellow-100) !important;
}

/* Collapsible and Draggable =============================== */

.Collapsible__contentInner {
  padding-left: 2rem;
}

.draggable .Collapsible__trigger > div {
  height: 40px;
  margin-left: -32px;
  padding-left: 32px;
  width: calc(100% + 48px) !important;
  padding-right: 0.5rem;
}

.table-body .Collapsible__trigger > div {
  padding: unset;
  margin: unset;
  width: unset;
  height: unset;
}

.draggable-table-rows.documents {
  margin: 0 !important;
  width: 100% !important;
}
.draggable-table-rows {
  margin-left: -2rem;
  width: calc(100% + 2rem);
}
.draggable-table-rows .table-row {
  padding-left: 4rem;
}

.draggable-table-rows .drag-handle {
  display: none;
  position: absolute;
  left: 8px;
  top: 20px;
  cursor: move;
}
.draggable-table-rows .row-card-wrapper.draggable:hover .drag-handle {
  display: flex;
}

/* Specific Tables ============================ */

/* Drag handle */
.account-section-header svg {
  cursor: move;
}

.table.accounts .row-card__title {
  align-self: center;
}

.table.accounts .table-cell:last-child {
  text-align: right;
}

.transactions-table .transaction-column > div:nth-child(3) {
  display: none;
}

.transactions-table b {
  font-weight: 400;
}

.table-head .table-cell {
  color: #757575 !important;
  font-weight: 400 !important;
  text-transform: capitalize !important;
  font-size: 11px !important;
  line-height: 14px;
}
.flex-row.label-small {
  font-size: 11px !important;
  line-height: 12px !important;
  font-weight: 400 !important;
  color: #757575 !important;
}
.row-card__content-subtext-wrapper {
  display: none;
}
.row-card {
  height: 60px !important;
  padding: 0;
  margin-left: 1rem;
}
.table .account__icon,
.transactions-table .account__icon,
.row-card-wrapper .account__icon,
.account-icon-column .account__icon {
  width: 16px !important;
  height: 16px !important;
  background-color: white !important;
  margin-right: 0 !important;
}

.table .account__icon img,
.transactions-table .account__icon img,
.row-card-wrapper .account__icon img,
.account-icon-column .account__icon img {
  width: 16px !important;
  height: 16px !important;
}

.row-card__balance {
  font-weight: 400;
  font-size: 15px;
  line-height: 24px;
}
.card__one-day-change,
.row-card__more {
  display: none;
}
.accounts__section > div:hover > div {
  background-color: transparent !important  ;
}

.accounts__section-category {
  font-size: 15px;
  padding: 0 0.5rem;
  line-height: 24px;
  font-weight: 600;
  border: none;
  display: flex !important;
  flex-direction: row !important;
}
.collapsible-wrapper {
  margin-bottom: 1rem;
}
.account-section-header > div {
  /* margin-top: 10px !important; */
  margin-left: -48px !important;
  /* height: 56px; */
}

@media screen and (max-width: 992px) {
  .mobile-last {
    text-align: right !important;
  }

  .draggable .Collapsible__trigger > div {
    width: calc(100% + 43px) !important;
  }
}
