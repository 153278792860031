.notification-modal-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 5rem;
  overflow: auto;
  flex-direction: column;
  background-color: var(--gray-02);
}

.notification-base {
  height: calc(100vh - 5rem);
  overflow: auto;
}
