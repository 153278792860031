.investment_real_estate_property_table .table-head,
.investment_real_estate_property_table .table-row {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.investment_real_estate_property_table .table-cell {
  border: none;
  padding: 0;
}

.investment_real_estate_property_table .table-head .table-cell {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: bold;
  font-size: 12px;
  line-height: 130%;
  letter-spacing: 0.04em;
  text-transform: uppercase;
  color: var(--text-02);
  padding: 0 rem 0;
  text-align: center;
}

.investment_real_estate_property_table .table-row .table-cell {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  padding: 1.5 rem 0;
  color: var(--text-01);
}

.investment_real_estate_property_table .table-body {
  display: flex;
  flex-direction: column;
}

.investment_real_estate_property_table .table-head,
.investment_real_estate_property_table .table-row {
}

.investment_real_estate_property_table .table-row {
  width: 100%;
  padding: 1.5rem 0 0;
  display: flex;
}

.investment_real_estate_property_table .table-body .table-row {
  padding-bottom: 1.5rem;
}

.investment_real_estate_property_table .table-body .table-row:last-child {
  border-bottom: unset;
}

/* Column to fill rest of row*/
.investment_real_estate_property_table .table-cell {
}
.investment_real_estate_property_table .table-cell:last-child {
  text-align: right;
}

.investment_real_estate_property_table .table-cell.account-icon-column {
}

.investment_real_estate_property_table .table-cell.name-column {
  display: flex;
  justify-content: flex-start;
  flex-basis: 40%;
}

.investment_real_estate_property_table .table-cell.purchase-price-column {
}

.investment_real_estate_property_table .table-cell.current-value-column {
}

.investment_real_estate_property_table .table-cell.period-column {
}

.investment_real_estate_property_table .table-cell.annualized-column {
}

.investment_real_estate_property_table .table-cell.total-return-column {
}

.investment_real_estate_property_table .negative {
  color: var(--red-01) !important;
}
.investment_real_estate_property_table .positive {
  color: var(--green-01) !important;
}

@media screen and (max-width: 992px) {
  .investment_real_estate_property_table .table-head,
  .investment_real_estate_property_table .table-row {
    gap: 1rem;
  }
  .investment_real_estate_property_table .table-row {
    padding: 1rem 0 0;
  }
  .investment_real_estate_property_table .table-cell.purchase-price-column {
    display: none;
  }
  .investment_real_estate_property_table .table-cell.period-column {
    display: none;
  }
  .investment_real_estate_property_table .table-cell.annualized-column {
    display: none;
  }
}
