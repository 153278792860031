.public-header {
  top: 0;
  display: flex;
  justify-content: space-between;
  height: 3rem;
  background: white;
  padding-top: 1.5rem;
  padding-bottom: 3rem;
  padding-right: 2rem;
  position: sticky;
  z-index: 1000;
}

.transparent-background {
  background: transparent !important;
}

.public-header .flat-back {
  width: 40vw;
  height: 100vh;
  background-color: var(--bg-01);
  z-index: 1;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
}
.public-header a {
  text-align: right;
  z-index: 3;
  margin-right: 1rem;
}

.public-header .bg {
  height: 100vh;
  width: 100vh;
  background-color: transparent;
  background-image: url("../../../../core/assets/landing-page.png");
  background-size: 100%;
  background-repeat: no-repeat;
  background-position-x: 100%;
  background-position-y: 100%;
  z-index: 2;
  position: absolute;
  right: 0;
  top: 0;
  overflow: hidden;
}
