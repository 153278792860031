.App {
  text-align: center;
  font-family: "Montserrat", sans-serif;
}

.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
  pointer-events: none;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.main-container {
  width: 100vw;
  overflow-y: auto;
  max-width: 100%;
  height: 100vh;
}
.main-container.marketing,
.main-container.onboarding {
  height: 100%;
}

.font-weight-500 {
  font-weight: 500 !important;
}

.font-weight-600 {
  font-weight: 600 !important;
}

/* Disables arrow on react popover*/

.popover {
  z-index: 1;
}

.arrow {
  display: none !important;
  margin: 0 !important;
}

/* Override default select option styles */
.pylon-select__control {
  border-color: var(--separator-01) !important;
  border-width: 2px !important;
  border-style: solid !important;
}

.pylon-select__single-value {
  line-height: 1.5rem;
  font-size: 1.25rem;
  font-weight: 500;
  color: var(--navy) !important;
}

.full-width {
  width: 100%;
}
