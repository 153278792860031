.networth-summary-brief .networth-summary-content > div {
  width: 33%;
  height: 10rem;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  text-align: center;
  display: flex;
}
.networth-summary-brief .networth-summary-content > div > div {
  border-right: 1px solid var(--separator-02);
  width: 100%;
  height: 90%;
  padding: 0;
  padding-top: 2rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  justify-content: space-between;
}
.networth-summary-brief .networth-summary-content > div:first-child {
  background-color: var(--bg-01);
  border-radius: 4px;
}

.networth-summary-brief .networth-summary-content > div:last-child > div,
.networth-summary-brief .networth-summary-content > div:first-child > div {
  border: none;
}

.networth-summary-brief .networth-summary-content .number {
  font-family: Montserrat;
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 37px;
  /* identical to box height, or 92% */
  display: flex;
  align-items: center;
  text-align: center;
  height: 30px;
}
.networth-summary-brief .networth-summary-content .label {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: bold;
  font-size: 12px;
  line-height: 130%;
  letter-spacing: 0.04em;
  text-transform: uppercase;
  color: var(--text-01);
  height: 20px;
  vertical-align: top;
}

.networth-summary-brief .networth-summary-content .holding-name {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: bold;
  font-size: 12px;
  line-height: 130%;
  letter-spacing: 0.04em;
  text-transform: uppercase;
  color: var(--text-03);
}

.networth-summary-brief .section-title {
  font-size: 1.25rem;
  font-weight: 700;
  color: var(--text-01);
}

@media screen and (max-width: 992px) {
  .networth-summary-brief .networth-summary-content > div {
    width: 100%;
    height: 8rem;
  }
  .networth-summary-brief .networth-summary-content > div > div {
    border-right: 0;
    border-bottom: 1px solid var(--separator-01);
  }
}
