.add-document-tray {
  margin: 1rem;
  margin-top: 5rem;
}

.add-document-tray__icon {
  height: 72px;
  width: 72px;
  background-color: #b5beca;
  border-radius: 10px;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 24px;
}

.add-document-tray__info-row {
  display: flex;
  flex-direction: column;
  margin: 1rem 0;
}
.add-document-tray__info-subrow {
  display: flex;
  justify-content: space-between;
  margin-bottom: 0.5rem;
}

.add-document-tray__info-subrow-bold-text {
  font-size: 1.25rem;
  font-weight: 600;
}

.add-document-tray__info-subrow-light-text {
  font-size: 0.875rem;
  font-weight: 400;
}
.add-document-tray__info-subrow-tag {
  background-color: rgba(181, 190, 202, 0.3);
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0.25rem 0.5rem;
  font-size: 0.625rem;
  font-weight: 600;
  border-radius: 2px;
}

.add-document-tray__content {
  margin: 1rem 0;
}

.add-document-tray__header {
  margin: 1rem 0;
}

.add-document-tray__header-text {
  font-size: 0.875rem;
  font-weight: 400;
  margin: 0.5rem 0;
}

.add-document-tray__step-action {
  font-size: 1.5rem;
  font-weight: 600;
}

.add-document-tray__select-button {
  font-size: 16px;
  font-weight: 600;
  height: 4rem;
  border: 2px solid #b5beca;
  border-radius: 6px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0.5rem 0;
  cursor: pointer;
  padding: 1rem;
}

.add-document-tray__select-button:hover {
  background-color: rgb(245, 243, 243);
}

.add-document-tray__step-continue-button-container {
  align-self: flex-end;
  margin-top: 2rem;
}

.add-document-tray__step-continue-button {
  cursor: pointer;
  height: 4rem;
  color: white;
  background: #2b2b3b;
  border-radius: 6px;
  padding: 0.5rem 2rem;
  display: flex;
  justify-content: center;
  font-weight: 600;
  font-size: 14px;
  align-items: center;
}
.disabled {
  cursor: not-allowed;
  background: #65656b;
}

.add-document-tray__input-wrapper {
  display: flex;
  flex-direction: column;
  margin: 1rem 0;
  flex-grow: 1;
  padding-left: 1rem;
}

.add-document-tray__input-label {
  font-size: 16px;
  font-weight: 600;
}

.add-document-tray__input {
  font-size: 16px;
  font-weight: 300;
  height: 4rem;

  border: 2px solid #b5beca;
  box-sizing: border-box;
  border-radius: 6px;
  padding: 1rem;
}

.add-document-tray__file-group {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.add-document-tray__file-name {
  color: var(--gray-10);
  padding: 0px 0.5rem;
}

.add-document__name-input {
  font-size: 1.25rem;
  font-weight: 600;
  padding: 0px 0.5rem;
}

.add-document__name-input:disabled {
  background-color: unset;
  padding: 0px 0.5rem;
  border: 0px;
}

.add-document__name-subrow-bold-text:focus {
  border: none;
  outline: 0px solid transparent;
}

.add-document__bottom-border {
  margin-top: 0.5rem;
  border: 1px solid var(--gray-10);
}
