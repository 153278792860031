.account-category-add {
  opacity: 0;
  transition: 0s opacity;
}

.account-category-add:hover {
  opacity: 1;
  transition-delay: 0.15s;
}

.account-category-add-item:hover {
  background-color: #e5eaff !important;
  cursor: pointer;
}

.account-category-add-item:active {
  background-color: #ccd4ff !important;
}

@media screen and (max-width: 992px) {
  .account-category-add {
    display: none !important;
  }
  .accounts__section > div {
    margin-bottom: 2rem;
  }
}
