:root {
  /* Text Colors */
  --navy: #000000;
  --text-01: #000000;

  --gray-70: #757575;
  --text-02: #757575;

  --gray-50: #f6f6f6;
  --text-03: #afafaf;

  --text-04: #ffffff;
  --text-05: ##141414;

  /* Background Colors */
  --gray-06: #f3f6fc;
  --bg-01: #f3f6fc;

  --gray-02: #f9fbff;
  --bg-02: #f9fbff;
  --bg-03: #e8efff;
  --gray-500: #757575;

  /* Separators */
  --gray-30: #e2e2e2;
  --separator-01: #e2e2e2;

  --gray-10: #eeeeee;
  --separator-02: #eeeeee;

  /* Blues */
  --blue-01: #3355ff;
  --blue-02: #667fff;
  --blue-03: #99a9ff;
  --blue-04: #ccd4ff;
  --alt-blue: #3355ff;
  --alt-blue-2: #0021cc;
  --blue-50: #e5eaff;

  /* Greens */
  --green-01: #3ba363;
  --green-02: #5fb881;
  --green-03: #8bcca4;
  --green-04: #abe0bf;

  --red-01: #fe2a1e;
  --red-02: #fe5047;
  --red-03: #fe7770;

  /* Purples */
  --purple-01: #751fff;
  --purple-02: #8e47ff;
  --purple-03: #a770ff;
  --purple-04: #c099ff;
  --purple-50: #efe6ff;
  --purple-300: #a770ff;
  --purple-600: #5d18cc;
  --purple-800: #461299;

  /* Others and Older */
  --orange: #fe741e;
  --orange-01: #fe8d47;
  --orange-02: #fea670;

  --pink-01: #fe9d98;
  --pink-02: #fec4c1;

  --yellow-01: #ffbf1f;
  --yellow-02: #ffcb47;

  --nav-bg: #ffffff;
  --nav-icon-color: #000000;

  --nav-hover-bg: #fff8e5;
  --nav-pressed-bg: #ffeec2;

  --nav-selected-icon-color: #000000;
  --nav-selected-bg: #fff8e5;
  --nav-selected-text: #000000;

  --black: #000000;
  --gray-900: #141414;
  --gray-800: #1f1f1f;
  --gray-700: #333333;
  --gray-600: #545454;
  --gray-500: #757575;
  --gray-400: #afafaf;
  --gray-300: #cbcbcb;
  --gray-200: #e2e2e2;
  --gray-100: #eeeeee;
  --gray-50: #f6f6f6;
  --white: #ffffff;

  --purple-800: #310080;
  --purple-700: #461299;
  --purple-600: #5d18cc;
  --purple-500: #751fff;
  --purple-400: #8e47ff;
  --purple-300: #a770ff;
  --purple-200: #c099ff;
  --purple-100: #d9c2ff;
  --purple-50: #efe5ff;

  --blue-800: #001066;
  --blue-700: #001999;
  --blue-600: #0021cc;
  --blue-500: #0029ff;
  --blue-400: #3355ff;
  --blue-300: #667fff;
  --blue-200: #99a9ff;
  --blue-100: #ccd4ff;
  --blue-50: #e5eaff;

  --cyan-800: #59aeb2;
  --cyan-700: #66c7cc;
  --cyan-600: #73e0e5;
  --cyan-500: #7ff8fe;
  --cyan-400: #98f9fe;
  --cyan-300: #b2fafe;
  --cyan-200: #cbfbfe;
  --cyan-100: #e5fdfe;

  --green-800: #163d25;
  --green-700: #1d5231;
  --green-600: #2c7a4a;
  --green-500: #3ba363;
  --green-400: #5fb881;
  --green-300: #8bcca4;
  --green-200: #abe0bf;
  --green-100: #cef5dd;
  --green-50: #e1f5e9;

  --yellow-800: #664c0c;
  --yellow-700: #997312;
  --yellow-600: #cc9918;
  --yellow-500: #ffbf1f;
  --yellow-400: #ffcb47;
  --yellow-300: #ffd670;
  --yellow-200: #ffe299;
  --yellow-100: #ffeec2;
  --yellow-50: #fff8e5;

  --orange-800: #662f0c;
  --orange-700: #994612;
  --orange-600: #cc5d18;
  --orange-500: #fe741e;
  --orange-400: #fe8d47;
  --orange-300: #fea670;
  --orange-200: #feb384;
  --orange-100: #fed8c1;

  --red-800: #66110c;
  --red-700: #991912;
  --red-600: #cc2118;
  --red-500: #fe2a1e;
  --red-400: #fe5047;
  --red-300: #fe7770;
  --red-200: #fe9d98;
  --red-100: #fec4c1;
  --red-50: #fee6e5;

  --z-index-left-nav: 30; /* this should be higher than page-header but lower than modals or other page level overlays */
  --z-index-page-header: 15; /* this should to be higher than page-content-menu but lower than left-nav */
  --z-index-page-content-menu: 10; /* this should be higher than page contents but lower than page header (so header overlays on menu) */
}

body {
  -webkit-font-smoothing: auto;
}

.wizard-header-right
  > div:hover
  .wizard-header-right
  > div:hover
  .wizard-header-name {
  fill: var(--blue-01);
  stroke: var(--blue-01);
  color: var(--blue-01);
}

#icon-notifications {
  fill: var(--text-02);
  stroke: var(--text-02);
}

.dashboard-container {
  display: flex;
  width: 100%;
  margin: 0;
  flex-direction: column;
}

.dashboard-content-wrapper {
  width: 100%;
  min-height: 100vh;
  max-width: 1072px;
  margin: 35px auto;
  padding: 0 2rem;
}

.dashboard-page__header-wrapper {
  min-width: 100%;
  width: fit-content;
  z-index: var(--z-index-page-header);
  background: rgba(255, 255, 255, 0.85);
  position: sticky;
  top: 0;
  border-bottom: 1px solid var(--gray-100);
  backdrop-filter: blur(20px);
}

.dashboard-page__header-content {
  padding-top: 2.125rem;
  padding-bottom: 2.125rem;
  display: flex;
  justify-content: center;
}

.dashboard-page__width-limit {
  width: 100%;
  max-width: 1072px;
  padding: 0 2rem;
}

@media screen and (max-width: 992px) {
  .dashboard-content-wrapper {
    width: 100%;
    flex-shrink: 0;
    flex-basis: auto;
  }

  .dashboard-page-content {
    flex-shrink: 0;
    flex-basis: auto;
  }

  .dashbaord-content {
    margin-left: 0;
  }

  .dashboard-page__header-wrapper {
    width: 100%;
    background: rgba(255, 255, 255, 0.85);
    border-bottom: 1px solid var(--separator-01);
    backdrop-filter: blur(20px);
    position: relative;
    z-index: var(--z-index-page-header);
    top: unset;
  }

  .dashboard-page__header-content {
    padding-top: 0.5rem;
    padding-bottom: 1rem;
  }

  .dashboard-page__width-limit {
    padding: 0 4vw;
  }
}

.dashboard-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-left: 17rem;
}

.marketing .dashboard-wrapper,
.onboarding .dashboard-wrapper {
  overflow: unset;
}
.layout-wide {
  width: 59rem;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Montserrat", sans-serif;
  font-style: normal;
  font-weight: 600;
  line-height: 1.5rem;
  color: var(--text-01);
}
h1 {
  font-size: 4rem;
  line-height: 4.375rem;
}
h2 {
  font-size: 3.5rem;
  line-height: 3.875rem;
}
h3 {
  font-size: 3rem;
  line-height: 3.313rem;
}
h4 {
  font-size: 35px;
  font-weight: bold;
  line-height: 3rem;
  margin-bottom: 0;
}
h5 {
  font-size: 2rem;
  line-height: 2.25rem;
}
h6 {
  font-size: 1.0625rem;
  line-height: 1.25rem;
  font-weight: 700;
}

h1 svg,
h2 svg,
h3 svg,
h4 svg,
h5 svg,
h6 svg {
  vertical-align: baseline;
}

.subtitle-01,
.subtitle-02 {
  font-family: Montserrat;
  font-style: normal;
  font-weight: normal;
  line-height: 1.5rem;
  color: var(--text-02);
}

.subtitle-01 {
  font-size: 1.5rem;
  line-height: 1.5rem;
}
.subtitle-02 {
  font-size: 1.25rem;
  line-height: 1.5rem;
}

body,
.body-large,
.body-medium,
.body-small,
p,
label,
.label,
.label-large,
.label-medium,
.label-small,
.overline {
  font-family: "Montserrat";
  font-style: normal;
  line-height: 1.5rem;
  color: var(--text-02);
}

body,
.body-large,
.body-medium,
.body-small,
p {
  font-weight: normal;
}

p,
body,
.body,
.body-medium {
  font-size: 1rem;
}
.body-small {
  font-size: 0.875rem;
}

.body-large {
  font-size: 1.125rem;
}

label,
.label,
.label-large,
.label-medium,
.label-small,
.overline {
  font-weight: 600;
}
.text-01 {
  color: var(--text-01) !important;
}
.text-02 {
  color: var(--text-02) !important;
}
.text-03 {
  color: var(--text-03) !important;
}
.blue-01 {
  color: var(--blue-01) !important;
}

label,
.label,
.label-medium {
  font-weight: 600;
  font-size: 0.75rem;
}

.label-large {
  font-size: 0.875rem;
}

.label-small {
  font-size: 0.75rem;
  text-transform: uppercase;
}
.overline {
  font-size: 0.625rem;
  text-transform: uppercase;
}

.text-highlight-blue {
  color: var(--blue-01);
}

input {
  line-height: 2rem;
  font-size: 1rem;
  font-weight: 500;
  border: 1px solid var(--gray-10);
  margin: 0.5rem 0;
  color: var(--navy);
  padding: 0.5rem 1rem;
  width: 100%;
}

textarea {
  width: 100%;
  min-height: 5.5rem;
  resize: none;
  border-radius: 10px;
  padding: 1rem;
  font-size: 0.875rem;
  margin-top: 2rem;
  border: 2px solid var(--separator-02);
}

.wizard-header-name {
  background: rgba(255, 255, 255, 1);
  -webkit-transition: background 0.2s, color 0.2s border-color 0.9s;
  -moz-transition: background 0.2s, color 0.2s border-color 0.9s;
  transition: background 0.2s, color 0.2s border-color 0.9s;
  outline-color: none;
}
.wizard-header-right {
  height: 2rem;
}
.wizard-header-right .collaborators .dropdown-menu.show {
  top: 0.5rem !important;
}
#top-menu-self-dropdown-collaborators:hover {
  background-color: var(--bg-01);
}
#top-menu-self-dropdown-collaborators {
  color: var(--text-02);
  border: 2px solid var(--separator-02);
  font-family: "Montserrat", sans-serif;
  font-weight: 600;
  color: white;
  padding: 0.375rem 2rem;
  font-size: 0.75rem;
}
.wizard-header-name:focus,
#top-menu-self-dropdown:focus {
  box-shadow: none;
}

.wizard-header-name:hover {
  background: var(--bg-01);
  color: #ffffff;
  color: var(--text-02);
}

.notification-bell__base:hover {
  background: var(--bg-01);
}

.document-download-icon:hover {
  background: var(--bg-01);
}

.notification-bell__base:hover svg {
  fill: white;
}

.notification-bell__base svg {
  fill: var(--text-02);
}

a,
a:not([href]):not([tabindex]) {
  color: var(--blue-01);
}

.nothing-here {
  background: #ffffff;
  border-radius: 0.375rem;
  text-align: center;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  font-size: 1.25rem;
  line-height: 2rem;
  color: var(--navy);
  margin-bottom: 1rem;
  width: 100%;
  margin: 0 auto;
}

.button-as-link {
  background: none !important;
  border: none;
  padding: 0 !important;

  color: var(--blue-01);
  display: inline;
  cursor: pointer;
}

input[disabled] {
  background: var(--bg-01);
}
.flex-row {
  display: flex;
  flex-direction: row;
}

.flex-row.between {
  justify-content: space-between;
}
.flex-row.center {
  justify-content: center;
}

.flex-row.evenly {
  justify-content: space-evenly;
}

.flex-row.around {
  justify-content: space-around;
}

.flex-column {
  display: flex;
  flex-direction: column;
}

.flex-column.center {
  justify-content: center;
}
.mt1 {
  margin-top: 1rem;
}
.mt2 {
  margin-top: 2rem;
}
.mt3 {
  margin-top: 3rem;
}
.mt4 {
  margin-top: 4rem;
}
.mt5 {
  margin-top: 5rem;
}
.ml1 {
  margin-left: 1rem;
}
.ml2 {
  margin-left: 2rem;
}
.ml3 {
  margin-left: 3rem;
}
.ml4 {
  margin-left: 4rem;
}
.ml5 {
  margin-left: 5rem;
}
.mr1 {
  margin-right: 1rem;
}
.mr2 {
  margin-right: 2rem;
}
.mr3 {
  margin-right: 3rem;
}
.mr4 {
  margin-right: 4rem;
}
.mr5 {
  margin-right: 5rem;
}
.clickable {
  cursor: pointer;
}
.bg-01 {
  background: var(--bg-01);
}
.highlight {
  background: var(--bg-01);
  border-radius: 0.5rem;
}
.green-01 {
  color: var(--green-01);
}
.red-01 {
  color: var(--red-01);
}

.mobile-only-display-flex {
  display: none;
}

@media screen and (max-width: 992px) {
  .layout-wide {
    width: 100%;
  }
  .mobile-flex-column {
    flex-direction: column !important;
    -ms-flex-direction: column !important;
  }
  .flex-row.mobile-flex-column {
    flex-direction: column !important;
    -ms-flex-direction: column !important;
  }

  .mobile-flex-column-reverse {
    flex-direction: column-reverse !important;
    -ms-flex-direction: column-reverse !important;
  }
  .flex-row.mobile-flex-column-reverse {
    flex-direction: column-reverse !important;
    -ms-flex-direction: column-reverse !important;
  }

  .mobile-only-display-flex {
    display: flex;
  }

  .no-mobile {
    display: none !important;
  }
}

/* classes to selectively choose color for certain parts of the svg using currentColor on the attributes */
svg.green-01 {
  color: var(--green-01);
}

svg.text-03 {
  color: var(--text-03);
}

.summary-box {
  box-shadow: 0px 1px 8px rgba(230, 233, 237, 0.8),
    0px 1px 4px rgba(181, 190, 202, 0.64);
  padding: 1rem 2rem;
  border-radius: 0.5rem;
}

.justify-center {
  justify-content: center;
}

.apexcharts-legend-text {
  color: var(--navy) !important;
}
.ellipsis {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.align-center {
  align-items: center;
}

.justify-center {
  justify-content: center;
}

/* ============================================================= */

body,
.row-card__content-title-text {
  font-size: 15px;
  font-weight: 400;
  line-height: 24px;
}
