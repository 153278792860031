.recipient-list__row {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0.5rem 0;
}

.action-text {
  display: none;
  cursor: pointer;
}
.sharing {
  background: white;
}
.recipient-list__row:hover .action-text {
  display: flex !important;
}

.not-sharing {
  background: var(--gray-10);
  color: var(--gray-50);
}
