.document-tags__section {
  display: flex;
  align-items: center;
  padding: 0.5rem 0;
}

.document-tags__add-account {
  display: flex;
  align-items: center;
  flex-grow: 0;
  flex-shrink: 0;
  position: relative;
}

.document-tags__add-account-button {
  border: 1px solid #0c98d5;
  width: 1rem;
  height: 1rem;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 2px;
  box-sizing: border-box;
}

.document-tags__account-tag-wrapper {
  display: flex;
  align-items: center;
  padding-left: 0.5rem;
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
}

.document-tags__account-pickers {
  width: 20rem;
  padding-top: 0.5rem;
  padding-right: 0.5rem;
  top: 0;
  left: 0;
  position: absolute;
}

.document-tags__account-pickers-inactive {
  display: none;
}

.document-tags__space-buttons {
  justify-content: space-between;
}
