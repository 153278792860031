.settings-section h2 {
  font-family: Montserrat;
  font-weight: 600;
  font-size: 1.25rem;
  color: var(--navy);
}

.label {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 16px;
  width: 100%;

  color: var(--gray-70);
  margin-bottom: 8px;
}

.table-wrapper {
  color: var(--navy);
  background: #ffffff;

  background: #ffffff;
  box-shadow: 0px 1px 8px rgba(230, 233, 237, 0.8),
    0px 1px 4px rgba(181, 190, 202, 0.64);
  border-radius: 6px;
}

.table-cell {
  font-size: 0.875rem;
  flex-grow: 1;
  flex-basis: 0;
  /* overflow: hidden; */
}
.table-cell.no-border {
  border-left: none;
}
.settings-section.sessions p {
  margin-top: -1rem;
}
.settings-section.sessions a {
  cursor: pointer;
  float: right;
  margin-top: -2.5rem;
}
.head .table-cell {
  font-size: 0.8125rem;
}
input {
  width: 100%;
  padding: 4px 8px;
  border-radius: 4px;
  font-size: 16px;
}

h2 {
  font-size: 1.5rem;
  font-weight: bold;
  margin-bottom: 1.5em;
}
p {
  margin: 1rem 0;
}
h2 p {
  font-size: 16px;
  font-weight: normal;
}

.field-data {
  font-weight: bold;
}

.verified {
  float: right;
  white-space: nowrap;
  font-size: 12px;
  font-weight: normal;
}

.settings__green-check {
  display: inline-block;
  vertical-align: middle;
  margin-right: 0.5rem;

  width: 1rem !important;
  height: 1rem !important;

  background: url("../../../../../core/assets/greencheck.svg") no-repeat center;
  background-size: contain;
}

.end-sessions-link {
  float: right;
  background: none !important;
  border: none;
  padding: 0 !important;

  margin-top: -2.5rem;
  color: var(--blue-01);
  display: inline;
  cursor: pointer;
}

.settings-section h2 {
  margin-bottom: 1rem;
  line-height: 2rem;
}

@media screen and (max-width: 992px) {
  .settings-section .head {
    display: none;
  }
  .settings-section .sessions-table .table-cell {
    padding: 0rem 4vw;
  }

  .settings-section .sessions-table .table-cell:first-child {
    padding-top: 1rem;
  }
  .settings-section .sessions-table .table-cell:last-child {
    padding-bottom: 1rem;
  }
  .settings-section .status {
    font-weight: 600;
    text-transform: uppercase;
    color: var(--text-02);
    font-size: 0.75rem;
  }
  .settings-section .device {
    font-size: 1rem;
    color: var(--text-01);
  }
  .settings-section .last-activity {
    font-size: 0.875rem;
    color: var(--text-02);
    font-weight: 600;
  }
}
