.pylon-logo {
  font-size: 35px;
  font-weight: 700;
  line-height: 3rem;
  color: var(--black);
  width: calc(100% - 3rem);
  padding: 10px 0;
  margin: 1.5rem;
}
.pylon-logo .early-access {
  font-size: 11px;
  line-height: 16px;
  font-weight: 600;
  text-transform: uppercase;
  color: var(--yellow-500);
  margin-left: 0;
}
.pylon-logo a {
  color: var(--black);
  text-decoration: none;
}

@media screen and (max-width: 992px) {
  .pylon-logo {
    margin: 0;
    padding: 0;
    white-space: nowrap;
  }
}
