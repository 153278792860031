.pylon-textarea {
  width: 100%;
  border: none;
  padding: 0.75rem 1rem;
  background: #f6f6f6;
  outline: none;
  border-radius: 0;
  margin-top: 0px;
}

input.pylon-textarea::placeholder {
  color: var(--gray-30);
}
