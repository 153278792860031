.cashflow td {
  border-bottom: 1px solid var(--separator-01);
  padding: 1rem;
}
.cashflow table {
  width: 100%;
}
.cashflow .values {
  font-weight: bold;
}
.cashflow .values.ignored {
  color: var(--text-03);
}

.cashflow tr.ignored td,
.cashflow td.ignored {
  color: var(--text-03);
}

.cashflow tr.income td,
.cashflow td.income {
  color: var(--green-02);
}

.cashflow .categories table td,
.cashflow .categories table th {
  text-align: center;
}

.cashflow table th {
  color: var(--text-02);
  font-size: 0.75rem;
  text-transform: uppercase;
}

.cashflow .categories table td:first-child,
.cashflow .categories table th:first-child {
  text-align: left;
  padding-left: 1rem;
  width: 25%;
}

.cashflow table .categories th {
  padding-left: 0;
}
.cashflow table td:last-child,
.cashflow table th:last-child {
  text-align: right;
  white-space: nowrap;
  padding-right: 1rem;
}

.cashflow .categories .transactions-table table td,
.cashflow .categories .transactions-table table th {
  text-align: left;
  padding: 1rem;
}
.cashflow .categories .transactions-table table td:first-child,
.cashflow .categories .transactions-table table th:first-child {
  width: auto;
  padding-left: 1rem;
}
.cashflow .categories .transactions-table table td:last-child,
.cashflow .categories .transactions-table table th:last-child {
  text-align: right;
  padding-right: 1rem;
}

.cashflow .chart-area {
  position: relative;
}
.cashflow .chart-area h6 {
  margin: 0;
}
.cashflow .chart-main-number {
  position: absolute;
  top: 0rem;
  right: 2rem;
  text-align: right;
}
.cashflow .category-row.selected {
  font-weight: bold;
}

.month-selector {
  cursor: pointer;
  font-size: 0.75rem;
  text-transform: uppercase;
}

.month-selector.selected {
  font-weight: bold;
  color: var(--blue-01);
}
