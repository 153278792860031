.share-widget {
  height: 70vh;
  display: flex;
  flex: 1;
  flex-direction: center;
  justify-content: "center";
}

.share-widget__icon {
  height: 72px;
  width: 72px;
  background-color: #b5beca;
  border-radius: 10px;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 24px;
}

.share-widget__info-row {
  display: flex;
  flex-direction: column;
  margin: 1rem 0;
}
.share-widget__info-subrow {
  display: flex;
  justify-content: space-between;
  margin-bottom: 0.5rem;
}

.share-widget__info-subrow-bold-text {
  font-size: 20px;
  font-weight: 600;
}

.share-widget__info-subrow-light-text {
  font-size: 14px;
  font-weight: 400;
}
.share-widget__info-subrow-tag {
  background-color: rgba(181, 190, 202, 0.3);
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0.25rem 0.5rem;
  font-size: 10px;
  font-weight: 600;
  border-radius: 2px;
}

.share-widget__content {
  display: flex;
  width: 100%;
}

.share-widget__header {
  margin: 1rem 0;
}

.share-widget__header-text {
  font-size: 14px;
  font-weight: 400;
  margin: 0.5rem 0;
}

.share-widget__step-action {
  font-size: 24px;
  font-weight: 600;
}

.share-widget__step-selection {
  display: flex;
  flex-direction: column;
  padding: 0.5rem 0;
}

.share-widget__select-button {
  font-size: 16px;
  font-weight: 600;
  height: 4rem;
  border: 2px solid #b5beca;
  border-radius: 6px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0.5rem 0;
  cursor: pointer;
  padding: 1rem;
}

.share-widget__select-button:hover {
  background-color: rgb(245, 243, 243);
}

.share-widget__step-continue-button-container {
  align-self: flex-end;
  margin-top: 5rem;
}

.share-widget__step-continue-button {
  cursor: pointer;
  height: 4rem;
  color: white;
  background: #2b2b3b;
  border-radius: 6px;
  padding: 0.5rem 2rem;
  display: flex;
  justify-content: center;
  font-weight: 600;
  font-size: 14px;
  align-items: center;
}
.disabled {
  cursor: not-allowed;
  background: #65656b;
}

.share-widget__upload-form {
  margin: 1rem 0;
  height: 5rem;
  border: 2px dashed #b5beca;
  border-radius: 6px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.share-widget__input-wrapper {
  display: flex;
  margin: 1rem 0;
}

.share-widget__input-label {
  font-size: 16px;
  font-weight: 600;
}

.share-widget__input {
  font-size: 16px;
  font-weight: 300;
  height: 4rem;

  border: 2px solid #b5beca;
  box-sizing: border-box;
  border-radius: 6px;
  padding: 1rem;

  flex-grow: 1;
  margin-right: 1rem;
}

.share-widget__share-button {
  cursor: pointer;
  height: 4rem;
  color: white;
  background: #2b2b3b;
  border-radius: 6px;
  padding: 0.5rem 2rem;
  display: flex;
  justify-content: center;
  font-weight: 600;
  font-size: 14px;
  align-items: center;
}

.share-widget__recipients {
  display: flex;
  flex-direction: column;
}

.share-widget__recipients-item {
  display: flex;
  align-items: center;
}

.share-widget__recipients-name {
  flex-grow: 1;
}

.share-widget__recipients-buttons {
  display: flex;
  flex-direction: row;
}

.share-widget__recipients-button {
  cursor: pointer;
  margin: 0 0.25rem;
  height: 3rem;
  padding: 0 0.5rem;
  border: 1px solid #d9d9d9;
  border-radius: 6px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.share-widget__empty-state {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
  margin: auto;
  height: 22rem;
  justify-content: space-evenly;
}
.share-widget__empty-state-share {
  font-size: 1rem;
  font-weight: 600;
  margin: 2rem 0;
  margin-bottom: 0.5rem;
}

.share-modal__content {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;

  height: 100%;
}

.share-modal__input {
  margin: 0.5rem 0;
}
.share-widget__user-dropdown {
  width: 100%;
  border: 1px solid #f5f5f5;
}

.share-widget__divider-wrapper {
  display: flex;
  width: 100%;
  height: 5rem;
  align-items: center;
  justify-content: space-evenly;
}

.share-widget__divider-or {
  margin: 0 1rem;
}
.share-widget__divider {
  border-top: 1px solid #b5beca;
  height: 1px;
  width: 100%;
}
.share-widget__dropdown-wrapper {
  display: flex;
}
.share-widget__shared-state {
  width: 100%;
}
