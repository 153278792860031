.expenses .category-row {
  padding: 0.75rem 0.5rem;
  border-bottom: 1px solid #eeeeee;
}
.expenses .category-row:first-child {
  padding-top: 0;
}

.expense-filter-title-wrapper {
  display: flex;
  gap: 3rem;
  justify-content: center;
}

.expense-filter-selector {
  cursor: pointer;
  font-size: 0.875rem;
  text-transform: capitalize;
}

.expense-filter-selector.selected {
  font-weight: bold;
  color: var(--text-01);
}
.expenses .mobile-flex-column > div {
  width: 50%;
}
.expenses .month-selector-group {
  margin: 0 auto;
  padding: 0rem 4rem;

  width: 50%;
  margin-bottom: 3rem;
}

.expenses-chart-row > div {
  width: 50%;
}

@media screen and (max-width: 992px) {
  .expenses .mobile-flex-column > div {
    width: 100%;
    margin-top: 2rem;
  }
  .expenses .mobile-flex-column > div:first-child {
    width: 100%;
    margin-top: 0;
  }
  .expenses .month-selector-group {
    width: 100%;
  }
  .expenses .category-row {
    padding: 1rem 2vw;
  }
  .expenses .category-header {
    padding-left: 2vw;
  }
  .expenses .category-header-right {
    padding-right: 2vw;
  }
  .expenses-chart-row > div {
    width: 100%;
  }
}

.expense-chart-item-main-cell {
  flex-grow: 1;
  flex-shrink: 1;
  display: flex;
  flex-direction: column;
  overflow: hidden;
}
