.wizard-header {
  color: #000000;
  font-size: 14px;
  width: 100%;
  max-width: 80rem;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin: 0 auto;
  margin-bottom: 2rem;
  height: 5rem;
}
.wizard-header > div {
  align-self: center;
}
.wizard-header-left {
  display: flex;
  flex-direction: row;
  width: 16rem;
}
.wizard-header-left > div,
.wizard-header-left > div > div {
  align-self: center;
  font-size: 1rem;
}
.wizard-header-center {
  flex: 1;
  display: flex;
}
@media screen and (max-width: 992px) {
  .wizard-header {
    display: none;
  }
}

.wizard-header-right {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.wizard-header-name {
  border-radius: 100%;
  border: 2px solid var(--separator-02);
  background: white;
  color: var(--text-02);
  text-transform: uppercase;
  font-size: 0.75rem;
  height: 2.25rem;
  width: 2.25rem;
  font-weight: 700;
  line-height: 1.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
}
