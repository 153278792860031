.collaborator-edit-tab {
  display: inline-block;
  position: relative;
  list-style: none;
  margin: 0.5rem 1rem 0;
  cursor: pointer;

  font-family: "Montserrat", sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 0.875rem;
  line-height: 1.5rem;
  color: var(--text-03);

  text-transform: uppercase;
}

.collaborator-edit-tab__selected {
  color: var(--blue-01);
  border-bottom: solid var(--blue-01) 3px;
}

.collaborator-edit-tab__account-name {
  min-width: 14rem;
  width: 80%;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

@media screen and (max-width: 992px) {
  .collaborator-edit-tab__account-name {
    width: 45% !important;
  }
  .collaborator-edit-tab {
    margin: 0 4vw;
  }
}
