.document-cards .folder-layout {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
}

.document-cards .folder-layout.light-up,
.document-cards .folder.light-up {
  border: 2px dashed var(--green-01);
}

.document-cards .folder {
  min-width: 15rem;
  max-width: 15rem;
  height: 15rem;
  padding: 1rem;
  border: 2px solid var(--gray-10);
  border-radius: 0.5rem;
  margin-right: 1rem;
  margin-bottom: 1rem;

  overflow: hidden;
  text-overflow: ellipsis;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  position: relative;
  cursor: pointer;
}

.document-cards .folder-name {
  font-family: "Montserrat";
  font-size: 1.25rem;
  line-height: 1.5rem;
}
.document-cards .folder-count {
  font-family: "Montserrat";
  font-size: 1rem;
  line-height: 1.75rem;
}
.document-cards .folder-count,
.document-cards .folder-name {
  color: var(--navy);
}
.document-cards a:hover,
.document-cards a:hover {
  text-decoration: none;
}
.document-cards .section-header {
  font-family: "Montserrat";
  font-size: 1.25rem;
  line-height: 1.5rem;
  font-weight: bold;
  color: var(--navy);
  margin-bottom: 1.625rem;
  margin-top: 2rem;
}
.document-cards .add-folder {
  text-align: center;
  background-color: var(--gray-06);
  justify-content: space-around;
}

.folder .icon-row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.folder .hover-action .on-hover {
  display: none;
}
.folder:hover .hover-action .on-hover {
  display: block;
  fill: blue;
  cursor: pointer;
}

.folder:hover {
  border: 2px solid var(--blue-01) !important;
}

.document-modal-wrapper {
  padding: 2rem;
}
.documents-modal {
  width: 554px;
  height: 426px;
  padding: 3rem 3rem;
  display: flex;
  align-content: center;
  text-align: center;
}
.document-modal-wrapper div {
  width: 100%;
  text-align: center;
}
.document-cards .loose-documents .folder-layout {
  display: block;
}

.document-cards .hover-action {
  position: absolute;
  top: 1rem;
  right: 1rem;
}

.document-cards > div:first-child > div:first-child {
  margin-right: 0.5rem;
}

.document-cards .actions {
  margin-top: 1rem;
  justify-content: flex-end;
}
@media screen and (max-width: 992px) {
  .document-cards > div:first-child > div:first-child {
    margin-right: 0;
    align-items: center;
    justify-content: center;
  }
  .document-cards .folder {
    min-width: 100% !important;
    max-width: 100% !important;
    margin-right: 0 !important;
    min-height: 12rem !important;
    height: unset !important;
  }
  .folder:hover,
  .document-cards .folder:hover {
    border: 2px solid var(--gray-10) !important;
  }
  .document-cards .actions {
    margin-top: 1rem;
    justify-content: center;
    align-items: center;
  }
  .document-cards .actions > div {
    margin-bottom: 0.5rem;
  }

  .folder .hover-action .on-hover {
    display: block;
    fill: blue;
    cursor: pointer;
  }
  .folder:hover .category__icon {
    border: 2px solid transparent;
  }
}
