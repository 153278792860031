.pick-chart {
  font-size: 0.75rem;
  font-weight: bolder;
  background: none !important;
  border: none;
  padding: 0 !important;
  text-transform: uppercase;
}

.account-combo .vertical {
  display: flex;
  justify-content: center;
}
