.category__icon {
  background-color: #ffffff;
  border-radius: 10px;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 2px solid transparent;
}

.category__icon .row-card__shared-indicator {
  zoom: 0.75;
}

.folder:hover .category__icon {
  border: 2px solid var(--gray-30);
}
