.plan-option {
  margin: 0.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 8rem;
  width: 10.5rem;
  border-radius: 10px;
  border-style: solid;
  border-width: 2px;
  border-color: #bfcce4;
  background: #f8fbff;
  cursor: pointer;
  flex-direction: column;
}

.plan-option-selected {
  border-color: #57ba8d !important;
  background: #e1fff1 !important;
}

.plan-option__icon {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-grow: 1;
  flex-shrink: 1;
}

.plan-option__name {
  padding-bottom: 0.5rem;
  flex-wrap: wrap;
  text-align: center;
}
