.account-tag {
  cursor: pointer;
  display: flex;
  align-items: center;
  border-radius: 6px;
}

.account-tag__account {
  max-width: 12rem;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;

  font-style: normal;
  font-weight: 500;
  font-size: 0.75rem;
}

.account-tag__remove {
  height: 1rem;
  /* border-left: 1px dotted #8493a6; */
  padding-left: 0.25rem;
  padding-right: 0.25rem;
  display: flex;
  align-items: center;
}

.account-tag__remove:hover {
  background: #f35151;
}
