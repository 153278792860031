.twofa-field-wrapper {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}

.twofa-button-wrapper {
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;
}

.twofa-input-with-resend {
  display: flex;
}

.twofa-resend-code-disabled {
  color: var(--gray-50);
}
