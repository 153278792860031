.login-button-wrapper {
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;
}

.account-page > form > p.pylon-text-error {
  margin: 0rem;
  margin-top: -1.5rem;
  text-align: center;
}
