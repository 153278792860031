.pylon-dropdown {
  color: var(--black);
  cursor: pointer;
  background: #f6f6f6;
  border: 1px solid transparent;
}

.pylon-dropdown:hover {
  background: var(--gray-100);
}

.pylon-dropdown:active {
  background: var(--gray-200);
}
