.pylon-collapsible {
  flex-grow: 1;
}
.collapsible-wrapper {
  margin-bottom: 3rem;
}
.collapsible-wrapper .highlight-account-row {
  position: relative;
  height: 23rem;
}
.collapsible-wrapper .highlight-account-row > div:first-child {
  border: 1px solid var(--separator-01);
  padding: 1rem;
  z-index: 99999999;
  border-radius: 0.5rem;
  position: relative;
  padding: 1rem;
}

.collapsible-wrapper .fake-tooltip {
  background: var(--text-01);
  position: absolute;
  left: 2rem;
  bottom: 0rem;
  border-radius: 0.5rem;
  padding: 1rem;
  color: white;
  padding: 2rem;
  z-index: 99999999;
}
.collapsible-wrapper .fake-tooltip > div {
  text-align: center;
  width: 100%;
}
.collapsible-wrapper .fake-tooltip > div {
  text-align: center;
  width: 100%;
}
.collapsible-wrapper .fake-tooltip .arrow-up {
  width: 0;
  height: 0;
  border-left: 1rem solid transparent;
  border-right: 1rem solid transparent;

  border-bottom: 1rem solid var(--text-01);
  position: absolute;
  top: -1rem;
  left: calc(50% - 1rem);
}

@media screen and (max-width: 992px) {
}
