.account__icon {
  height: 72px;
  width: 72px;
  background-color: #b5beca;
  border-radius: 10px;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 24px;
}
