.email-reset-page-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 2rem;
}

.email-reset-page-border {
  min-height: 50vh;
  padding: 1rem 2rem;
}

.email-reset-page-field {
  min-width: 20rem;
}

@media screen and (max-width: 992px) {
  .email-reset-page-border svg {
    position: absolute;
    top: 2vh;
    left: 4vw;
  }
  .email-reset-page-field {
    min-width: unset;
  }
  .email-reset-page-wrapper {
    align-items: unset !important;
  }
}
