.units-label {
  font-size: 2rem;
}
.key-block {
  height: 1rem;
  width: 1rem;
  background-color: var(--gray-10);
  border-radius: 1rem;
}
.best {
  background-color: var(--green-01);
  fill: var(--green-01);
}
.better {
  background-color: var(--yellow-01);
  fill: var(--yellow-01);
}
.good {
  background-color: var(--orange-01);
  fill: var(--orange-01);
}
.bad {
  background-color: var(--red-01);
  fill: var(--red-01);
}

.best2 {
  background-color: var(--green-02);
  fill: var(--green-02);
}
.better2 {
  background-color: var(--yellow-02);
  fill: var(--yellow-02);
}
.good2 {
  background-color: var(--orange-02);
  fill: var(--orange-02);
}
.bad2 {
  background-color: var(--red-02);
  fill: var(--red-02);
}

.line {
  background-color: var(--navy);
  fill: var(--navy);
}

.normal {
  background-color: var(--gray-10);
  fill: var(--gray-10);
}
.chart-key {
  display: flex;
  flex-direction: column;
}
.chart-key div {
  margin: 0 0.5rem;
  align-self: center;
}
.chart {
  display: flex;
  justify-content: center;
  margin-top: 0.5rem;
}
.overview-card.credit-utilization {
  min-width: calc(50% - 4rem);
  max-width: 40rem;
}
.chart > div {
  align-self: center;
}

@media screen and (max-width: 692px) {
  .networth-series .chart {
    flex-direction: column;
  }
  .overview-card.credit-utilization {
    height: auto !important;
  }
  .chart-key:first-child {
    margin-top: 1rem;
  }
}
