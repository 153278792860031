.income-and-taxes-2 {
  color: var(--text-01);
}
.income-and-taxes-2 .table-cell:last-child {
  text-align: right;
}

.income-and-taxes-2 .Collapsible__contentInner .left {
  padding-left: 1rem;
  margin-top: 2rem;
}

.income-and-taxes-2 .Collapsible__contentInner .right {
  padding-right: 1rem;
  margin-top: 2rem;
}

.income-and-taxes-2 .body {
  margin-bottom: 3rem;
  display: flex;
  flex-direction: column;
}
.income-and-taxes-2 .body .component {
  display: flex;
  flex-direction: column;
  box-shadow: 0px 1px 8px rgba(230, 233, 237, 0.8),
    0px 1px 4px rgba(181, 190, 202, 0.64);
  padding: 1rem 2rem;
  border-radius: 0.5rem;
  margin-top: 1rem;
}
.income-and-taxes-2 .row {
  border-bottom: 1px solid var(--separator-01);
  margin-bottom: 1rem;
  padding-bottom: 1rem;
  margin-left: 0;
  margin-right: 0;
}

.income-and-taxes-2 .Collapsible__contentInner,
.income-and-taxes-2 .body .input-row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.income-and-taxes-2 .Collapsible__contentInner .left,
.income-and-taxes-2 .body .input-row .left {
  display: flex;
  flex-direction: column;
  width: 32%;
}

.income-and-taxes-2 .Collapsible__contentInner .right,
.income-and-taxes-2 .body .input-row .right {
  width: 66%;
}

.income-and-taxes-2 .Collapsible__contentInner .right > div > div,
.income-and-taxes-2 .body .input-row .right > div > div {
  width: 48%;
}
.row.total > div {
  font-weight: 600;
  color: var(--text-01);
}

.row.head {
  border: none;
  padding: 0;
  margin-bottom: 0;
  color: var(--text-01);
}
.row:last-child {
  border: none;
}
.row.annotation > div {
  color: var(--text-03);
}
.row.head > div {
  font-weight: 600;
  margin-bottom: 1rem;
  border: none;
  color: var(--text-01);
  font-size: 1rem;
}
.row > div {
  text-align: center;
  width: 13%;
  color: var(--text-01);
  font-size: 0.875rem;
}
.income-and-taxes-2 .Collapsible__contentInner > div,
.input-row > div {
  width: 32.25%;
}
.row > div:first-child {
  text-align: left;
  width: 32.25%;
}
.row > div:last-child {
  text-align: right;
}
.income-and-taxes-2 .pylon-input {
  height: 3rem;
  font-size: 1rem;
  margin-top: 0;
}
.income-and-taxes-2 .label {
  line-height: 1rem;
  margin-bottom: 0.5rem;
  text-transform: uppercase;
  color: var(--text-03);
}
.income-and-taxes-2 .label p {
  text-transform: none;
}
.income-and-taxes-2 .component > div {
  width: 50%;
}

.income-and-taxes-2 .component > div:nth-child(2) {
  text-align: left;
  align-self: flex-start;
}

.federal-summary > div {
  padding: 1rem;
  box-shadow: 0px 1px 8px rgba(230, 233, 237, 0.8),
    0px 1px 4px rgba(181, 190, 202, 0.64);
  border-radius: 0.375rem;
  min-height: 10rem;
  margin-top: 1rem;
}
.federal-summary > div > div:first-child > div {
  background: var(--bg-01);
  border-radius: 0.5rem;
}

.federal-summary > div > div > div {
  height: 7rem;
  align-self: center;
  min-width: 15rem;
  border-right: 2px solid var(--separator-02);
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
}

.federal-summary > div > div:first-child > div {
  height: auto;
  min-height: 10rem;
}
.federal-summary > div > div > div:last-child {
  border-right: none;
}

.income-and-taxes-2 .Collapsible__contentInner .right > div:nth-child(n + 2),
.income-and-taxes-2 .body .input-row .right > div:nth-child(n + 2) {
  margin-top: 2rem;
}

.tax-breakdown {
  display: flex;
  flex-direction: column;
  margin-top: 3rem;
}

.income-and-taxes-2 .Collapsible .accounts__section-category {
  margin-right: 0.5rem !important;
}

.income-and-taxes-2 .Collapsible.overview {
  margin-left: 0 !important;
}

.income-and-taxes-2 .large-group {
  margin-top: 2rem;
}
.income-and-taxes-2 .large-group .Collapsible {
  margin-left: 0rem;
}

.income-and-taxes-2 .large-group .Collapsible__contentOuter {
  display: none;
}
.income-and-taxes-2 .large-group .Collapsible .accounts__section-category {
  border: none;
}
.income-and-taxes-2 .Collapsible .label {
  display: flex;
}
.income-and-taxes-2 .body h4 {
  margin-top: 0rem !important;
}
.add-income-source button {
  margin-right: 1rem;
}
.default-closed.Collapsible__contentOuter {
  display: none;
}
.income-and-taxes-2 .accounts__section-balance .label {
  justify-content: flex-end;
}
.table-cell.long-term-amount,
.table-cell.short-term-amount,
.table-cell.state-amount,
.table-cell.federal-amount .table-cell:last-child {
  width: 10rem;
}

.table-cell:first-child,
.table-cell.name {
  width: 60%;
  flex: 60%;
  white-space: nowrap;
  overflow-x: hidden;
  text-overflow: ellipsis;
  text-align: left;
}

@media screen and (max-width: 992px) {
  .income-and-taxes-2 .Collapsible .left {
    display: none;
  }
  .income-and-taxes-2 .Collapsible .right,
  .income-and-taxes-2 .body .input-row .right {
    width: 100% !important;
    margin-top: 2rem;
  }
  .income-and-taxes-2 .Collapsible .right > div > div:last-child,
  .income-and-taxes-2 .body .input-row .right > div > div:last-child {
    margin-top: 0;
  }
  .income-and-taxes-2 .Collapsible .right > div > div,
  .income-and-taxes-2 .body .input-row .right > div > div {
    width: 100% !important;
    margin-bottom: 1rem;
  }
  .income-and-taxes-2 .row > div:last-child {
    width: 50% !important;
  }
  .income-and-taxes-2 .row > div:first-child {
    width: 50% !important;
    padding-left: 0 !important;
  }
  .income-and-taxes-2 .Collapsible .right > div:nth-child(2),
  .income-and-taxes-2 .body .input-row .right > div:nth-child(2) {
    margin-top: 0rem;
  }
  .tax-breakdown {
    display: none;
  }

  .income-and-taxes-2 .Collapsible .accounts__section-category {
    flex-direction: column;
    position: relative;
  }

  .income-and-taxes-2 .Collapsible .accounts__section-balance > div {
    text-align: center !important;
    margin-right: 0 !important;
  }
  .income-and-taxes-2
    .Collapsible
    .accounts__section-balance
    > div:nth-child(2) {
    margin-left: 1rem;
  }

  .income-and-taxes-2 .Collapsible {
    margin-left: 0rem;
  }

  .income-and-taxes-2 .large-group {
    display: none;
  }

  .income-and-taxes-2 .Collapsible__contentInner {
    margin-top: 0;
    margin-bottom: 0;
    padding-bottom: 2rem;
  }
}
