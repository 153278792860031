.pylon__currency-input {
  display: flex;
  flex-direction: column;
}

.pylon__currency-input__numeric-input {
  font-size: 0.875rem;
  font-weight: 400;
  border: 1px solid var(--gray-30);
  margin: 0;
  color: var(--navy);
  max-width: 30rem;
  height: 3rem;
}
