.document-cards {
  padding-left: 1rem;
}

.document-cards .folder-layout {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
}
.document-cards .folder {
  min-width: 15rem;
  max-width: 15rem;
  height: 15rem;
  padding: 1rem;
  border: 2px solid var(--gray-10);
  border-radius: 0.5rem;
  margin-right: 1rem;
  margin-bottom: 1rem;

  overflow: hidden;
  text-overflow: ellipsis;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  cursor: pointer;
}
.document-cards .folder-name {
  font-family: "Montserrat";
  font-size: 1.25rem;
  line-height: 1.5rem;
}
.document-cards .folder-count {
  font-family: "Montserrat";
  font-size: 1rem;
  line-height: 1.75rem;
}
.document-cards .section-header {
  font-family: "Montserrat";
  font-size: 1.25rem;
  line-height: 1.5rem;
  font-weight: bold;
  color: var(--navy);
  margin-bottom: 1.625rem;
  margin-top: 2rem;
}
.document-cards .add-folder {
  text-align: center;
  background-color: var(--gray-06);
  justify-content: space-around;
}

.folder .icon-row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.folder .icon-row .on-hover {
  display: none;
}
.folder:hover .icon-row .on-hover {
  display: block;
  fill: blue;
  cursor: pointer;
}

.document-modal-wrapper {
  padding: 2rem;
}
.documents-modal {
  top: 30%;
}
.document-modal-wrapper div {
  width: 100%;
}
.document-cards .loose-documents .folder-layout {
  display: block;
}

.document-folder__recommendation-item {
  padding: 2rem 0;
  border-bottom: 1px solid var(--separator-01);
}
