html {
  height: 100%;
  box-sizing: border-box;
}

body {
  font-family: "Montserrat", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  position: relative;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.pylon-toast-container {
  position: fixed;

  overflow: hidden;
  color: white;
  right: 0;
}

/* override default toast borders */
.Toastify__toast--default {
  box-shadow: none !important;
  padding: 0 !important;
  background: none !important;
}

/* replace the default progress bar with a hidden one */
.Toastify__progress-bar--default {
  background: linear-gradient(
    to right,
    #5856
  ); /* color does not matter but we need something */
  visibility: hidden;
}

.Toastify__close-button:focus {
  border: none;
  outline: none;
}

.dropdown-item:active {
  background-color: #f8f8fa;
  color: black;
}

.pylon-dropdown-menu {
  cursor: pointer;
  padding: 0;
  background: #ffffff;
  border: none;
  color: var(--yellow-50);
  font-weight: 500;
  font-size: 0.875rem;
  box-shadow: 0px 1px 8px rgba(230, 233, 237, 0.8),
    0px 1px 4px rgba(181, 190, 202, 0.64);
  border-radius: 6px;
  min-width: 14.375rem;
  z-index: var(--z-index-page-content-menu);
}

.dropdown-menu {
  box-shadow: 0px 4px 12px 0px #1326371f;
  z-index: var(--z-index-page-content-menu);
  margin: 0;
  padding: 0;
  border: none !important;
}

.flex-row {
  display: flex;
  flex-direction: row;
}

.flex-column {
  display: flex;
  flex-direction: column;
}

.__react_component_tooltip {
  border-radius: 8px !important;
}

.__react_component_tooltip p {
  color: var(--text-04);
}

.__react_component_tooltip.show {
  opacity: 1 !important;
}

@media screen and (max-width: 992px) {
  .__react_component_tooltip {
    width: calc(100% - 2rem) !important;
    margin: 1rem !important;
  }
  .__react_component_tooltip > div {
    max-width: 100% !important;
  }
}

.width-fit-content {
  width: fit-content;
}

.flex-no-grow-shrink {
  flex-shrink: 0;
  flex-grow: 0;
}

@font-face {
  font-family: SharpGroteskMedium25;
  src: url("core/fonts/sharp-grotesk-medium-25-regular.otf") format("opentype");
}

@font-face {
  font-family: SharpGroteskBMBold20;
  font-weight: bold;
  src: url("core/fonts/sharp-grotesk-smbold-20-regular.otf") format("opentype");
}

@font-face {
  font-family: SharpGroteskBook20;
  src: url("core/fonts/sharp-grotesk-book-20-regular.ttf") format("truetype");
}

.font-sg-m25 {
  font-family: SharpGroteskMedium25;
}

.font-sgb-20 {
  font-family: SharpGroteskBook20;
}

.font-sg-smb20 {
  font-family: SharpGroteskBook20;
}

.gap-half {
  gap: 0.5rem;
}
.gap-1 {
  gap: 1rem;
}
