.confirmation-modal__wrapper {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  max-height: 95vh;
  padding: 0;
  overflow: auto;
}

@media screen and (max-width: 992px) {
  .modal-button.confirmation-modal__wrapper {
    min-width: 100%;
  }
}
