.collaborators-wrapper {
  cursor: pointer;
  margin-right: 0.5rem;
}
.trustee-user-card {
  width: 15.125rem;
  height: 17.75rem;
  border-radius: 8px;
  border: 2px solid var(--gray-10);
  display: flex;
  flex-direction: column;
  padding: 0px 1rem;
  justify-content: space-between;
}

.trustee-user-card-editable {
  width: 15.125rem;
  height: 17.75rem;
  border-radius: 8px;
  border: 2px solid var(--gray-10);
  display: flex;
  flex-direction: column;
  background-color: white;
}

.trustee-user-card-selected {
  width: 15.125rem;
  height: 17.75rem;
  border-radius: 8px;
  border: 2px solid var(--gray-10);
  display: flex;
  flex-direction: column;
  background-color: var(--blue-01);
  color: white;
}

.trustee-user-card > ul {
  padding: 0 1rem;
  vertical-align: auto;
  display: flex;
}

.trustee-user-card > ul li {
  display: list-item;
  text-transform: capitalize;
}

.trustee-user-card > ul li::marker {
  color: var(--blue-01);
  font-size: xx-large;
}

.trustee-user-card > ul li::before {
  margin: 0;
  padding: 0;
}

.trustee-user-card__dot {
  background: var(--blue-01);
  border-radius: 50%;
  display: inline-block;
  height: 0.5rem;
  width: 0.5rem;
  margin-right: 0.5rem;
  align-self: flex-start;
  margin-top: 0.5rem;
}

.trustee-user-card__gray-dot {
  background: var(--gray-30);
  border-radius: 50%;
  display: inline-block;
  height: 0.5rem;
  width: 0.5rem;
  margin-right: 0.5rem;
  align-self: flex-start;
  margin-top: 0.5rem;
}

.trustee-user-card__dot-selected {
  background: white;
  border-radius: 50%;
  display: inline-block;
  height: 0.5rem;
  width: 0.5rem;
  margin-right: 0.5rem;
  align-self: flex-start;
  margin-top: 0.5rem;
}

@media screen and (max-width: 992px) {
  .manage-trusted-contact-wrapper {
    margin: 0 !important;
    margin-bottom: 1rem !important;
  }
  .trustee-user-card {
    position: relative;
    width: 100%;
    height: 10rem;
  }
  .delete-button {
    position: absolute;
    bottom: 1rem;
    right: 1rem;
  }
}
