.transaction-month-selector__toggle {
  user-select: none;
}

.transaction-month-selector__menu-items {
  cursor: pointer;
  background-color: white;
  box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.24);
  border-radius: 2px;
  width: 13rem;
  max-height: 50vh;
  overflow: auto;
  user-select: none;
}
