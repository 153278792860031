.taxes__state_label {
  width: 5rem;
}

.taxes__cell-2 {
  flex: 1 0 40%;
}

.taxes__cell-2:nth-child(2n + 0) {
  padding-right: unset;
}

.taxes__state-dropdown {
  max-width: 30rem;
  width: 100%;
}

.taxes__state-dropdown > div {
  border: 2px solid var(--separator-01);
  border-radius: 6px;
}

.taxes__state-dropdownn > div input {
  margin: 2px;
}
.taxes__state-dropdown > div span {
  display: none;
}
.taxes__state-dropdown > div svg {
  fill: var(--blue-01);
}

.taxes__state-label {
  font-family: "Montserrat", sans-serif;
  font-size: 0.75rem;
  font-style: normal;
  font-weight: 700;
  line-height: 150%;
  letter-spacing: 0.04em;
  color: var(--text-03);
  text-transform: uppercase;
  padding: 0.25rem 0;
}

.taxes__state-input {
  height: 3.75rem;
  border: 2px solid var(--separator-01);
}

.taxes__state-title {
  flex-basis: 50%;
}

.taxes__state-section-header {
  font-family: "Montserrat", sans-serif;
  font-size: 1.5rem;
  font-style: normal;
  font-weight: 600;
  line-height: 130%;
  letter-spacing: 0em;
  color: var(--navy);
}

.taxes__state-section-description {
  font-family: "Montserrat", sans-serif;
  font-size: 1.125rem;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
  color: var(--text-01);
}

@media screen and (max-width: 992px) {
  .taxes__state-title {
    flex-basis: 100%;
    padding-bottom: 1rem;
  }

  .taxes__state-dropdown {
    max-width: 30rem;
    width: 100%;
  }
}
