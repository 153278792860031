.credit-card-value-details {
  font-size: 1.25rem;
  line-height: 1.25rem;
  font-weight: 700;
  color: var(--navy);
  font-family: "Montserrat", sans-serif;
  border: none;
  width: 10rem !important;
  padding: 0 0.5rem;
  margin: unset;
  text-align: center;
  border: unset !important;
}

.credit-card-value-details:disabled {
  background-color: unset;
  user-select: all;
}
