.pylon-datepicker__month-adjust-nav {
  border-radius: 6px;
  background-color: var(--blue-01);
  box-shadow: 0px 1px 8px rgba(230, 233, 237, 0.8),
    0px 1px 4px rgba(181, 190, 202, 0.64);
}

.react-datepicker__header--custom {
  background-color: var(--gray-10) !important;
}
