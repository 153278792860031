.mobile-login-menu {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background-color: white;
  color: black;
  z-index: 2;
  background-repeat: repeat-x;
  background-size: 100vh;
  background-position-y: 80vh;
  background-image: url("../../../../../core/assets/full-wire-background.svg");
}

.mobile-login-menu,
.mobile-login-menu-closed {
  padding: 2vh 4vw;
}
.mobile-login-menu .links {
  margin-top: 17vh;
}
.mobile-login-menu .links > div {
  padding: 2.25vh 4vw;
  display: flex;
  flex-direction: row;
}
.mobile-login-menu .links h3 {
  color: black;
}
.mobile-login-menu .links a {
  text-decoration: none;
}
.mobile-login-menu .links .spacer {
  border-bottom: 2px solid var(--yellow-500);
  width: 6vw;
  height: 28px;
  margin-right: 8vw;
  opacity: 0.5;
}

.login-link a {
  font-weight: bold;
  font-size: 1rem;
  line-height: 1.6875rem;
  color: var(--text-03);
  right: 3vw;
  top: 3vh;
  text-align: right;
  z-index: 3;
  margin: 0 1rem;
  text-transform: uppercase;
}
