.overview-component.credit-summary {
  padding-bottom: 0.5rem;
}
.credit-summary-score,
.credit-summary-utilization {
  padding: 0 4rem;
  margin-top: 1rem;
}
.credit-summary-score {
  padding-bottom: 0rem;
}

.chart-section {
  width: 70%;
}
.summary-section-title {
  width: 30%;
  align-self: center;
}
.summary-data-section {
  align-items: center;
}
.summary-data-section > div:first-child {
  align-items: center;
  text-align: center;
  width: 30%;
}
.summary-data-section > div:first-child h3 {
  line-height: 3rem;
  font-size: 1.5rem;
  margin: 2rem 1rem;
}
.summary-data-section > div:nth-child(2) {
  width: 61%;
  margin-left: 0%;
}
@media (max-width: 992px) {
  .overview-component.credit-summary {
    flex-direction: column;
  }
  .credit-summary-score,
  .credit-summary-utilization {
    padding: 0 1rem;
    margin-top: 1rem;
  }
  .chart-section {
    width: 100% !important;
  }
  .summary-section-title {
    width: 100%;
    align-self: flex-start;
    margin-top: 1rem;
  }
  .summary-data-section {
    align-items: left;
  }
  .summary-data-section > div,
  .summary-data-section > div:first-child {
    align-items: flex-start;
    text-align: left;
    width: 100%;
  }
  .summary-data-section > div:nth-child(2) {
    width: 100%;
    margin-left: 0;
    align-items: flex-start;
    text-align: left;
  }
  .summary-data-section > div:first-child h3 {
    line-height: 3rem;
    font-size: 2rem;
    margin: 2rem 0;
  }

  .modal-base.credit-score-modal {
    width: 100% !important;
  }

  .credit-summary-mobile-row {
    margin: 0 1rem;
    margin-bottom: 1rem;
    border-bottom: 1px solid var(--separator-02);
  }

  .credit-summary-mobile-row:first-child {
    margin-top: 1rem;
  }

  .credit-summary-mobile-row:last-child {
    margin-bottom: 0;
    border-bottom: none;
  }
}
