.terms {
  color: var(--navy) !important;
}

@media screen and (max-width: 992px) {
  .public-page-content-wrapper .terms.dashboard-content.inner {
    padding: 0;
  }
}

.terms .lst-kix_list_4-1 > li {
  counter-increment: lst-ctn-kix_list_4-1;
}

.terms ol.lst-kix_list_7-0 {
  list-style-type: none;
}

.terms .lst-kix_list_2-1 > li {
  counter-increment: lst-ctn-kix_list_2-1;
}

.terms ol.lst-kix_list_9-0.start {
  counter-reset: lst-ctn-kix_list_9-0 0;
}

.terms .lst-kix_list_6-1 > li {
  counter-increment: lst-ctn-kix_list_6-1;
}

.terms .lst-kix_list_8-1 > li {
  counter-increment: lst-ctn-kix_list_8-1;
}

.terms ol.lst-kix_list_8-2.start {
  counter-reset: lst-ctn-kix_list_8-2 0;
}

.terms ol.lst-kix_list_3-1.start {
  counter-reset: lst-ctn-kix_list_3-1 0;
}

.terms ol.lst-kix_list_6-6.start {
  counter-reset: lst-ctn-kix_list_6-6 0;
}

.terms ol.lst-kix_list_7-4.start {
  counter-reset: lst-ctn-kix_list_7-4 0;
}

.terms .lst-kix_list_5-0 > li {
  counter-increment: lst-ctn-kix_list_5-0;
}

.terms .lst-kix_list_7-0 > li {
  counter-increment: lst-ctn-kix_list_7-0;
}

.terms .lst-kix_list_9-0 > li {
  counter-increment: lst-ctn-kix_list_9-0;
}

.terms ol.lst-kix_list_2-3.start {
  counter-reset: lst-ctn-kix_list_2-3 0;
}

.terms ol.lst-kix_list_7-5 {
  list-style-type: none;
}

.terms ol.lst-kix_list_7-6 {
  list-style-type: none;
}

.terms ol.lst-kix_list_7-7 {
  list-style-type: none;
}

.terms ol.lst-kix_list_7-8 {
  list-style-type: none;
}

.terms ol.lst-kix_list_7-1 {
  list-style-type: none;
}

.terms ol.lst-kix_list_1-5.start {
  counter-reset: lst-ctn-kix_list_1-5 0;
}

.terms ol.lst-kix_list_7-2 {
  list-style-type: none;
}

.terms ol.lst-kix_list_7-3 {
  list-style-type: none;
}

.terms ol.lst-kix_list_7-4 {
  list-style-type: none;
}

.terms ol.lst-kix_list_9-6.start {
  counter-reset: lst-ctn-kix_list_9-6 0;
}

.terms ol.lst-kix_list_5-3.start {
  counter-reset: lst-ctn-kix_list_5-3 0;
}

.terms .lst-kix_list_2-3 > li {
  counter-increment: lst-ctn-kix_list_2-3;
}

.terms .lst-kix_list_4-3 > li {
  counter-increment: lst-ctn-kix_list_4-3;
}

.terms ol.lst-kix_list_4-5.start {
  counter-reset: lst-ctn-kix_list_4-5 0;
}

.terms .lst-kix_list_1-2 > li {
  counter-increment: lst-ctn-kix_list_1-2;
}

.terms ol.lst-kix_list_3-7.start {
  counter-reset: lst-ctn-kix_list_3-7 0;
}

.terms .lst-kix_list_5-2 > li {
  counter-increment: lst-ctn-kix_list_5-2;
}

.terms ol.lst-kix_list_8-8.start {
  counter-reset: lst-ctn-kix_list_8-8 0;
}

.terms .lst-kix_list_3-2 > li {
  counter-increment: lst-ctn-kix_list_3-2;
}

.terms ol.lst-kix_list_10-4.start {
  counter-reset: lst-ctn-kix_list_10-4 0;
}

.terms .lst-kix_list_7-2 > li {
  counter-increment: lst-ctn-kix_list_7-2;
}

.terms .lst-kix_list_9-2 > li {
  counter-increment: lst-ctn-kix_list_9-2;
}

.terms ol.lst-kix_list_8-7.start {
  counter-reset: lst-ctn-kix_list_8-7 0;
}

.terms .lst-kix_list_5-0 > li:before {
  content: "" counter(lst-ctn-kix_list_5-0, decimal) ". ";
}

.terms ol.lst-kix_list_6-0 {
  list-style-type: none;
}

.terms ol.lst-kix_list_6-1 {
  list-style-type: none;
}

.terms .lst-kix_list_5-4 > li {
  counter-increment: lst-ctn-kix_list_5-4;
}

.terms .lst-kix_list_1-4 > li {
  counter-increment: lst-ctn-kix_list_1-4;
}

.terms ol.lst-kix_list_1-6.start {
  counter-reset: lst-ctn-kix_list_1-6 0;
}

.terms .lst-kix_list_5-3 > li:before {
  content: "" counter(lst-ctn-kix_list_5-3, decimal) ". ";
}

.terms ol.lst-kix_list_9-5.start {
  counter-reset: lst-ctn-kix_list_9-5 0;
}

.terms .lst-kix_list_5-2 > li:before {
  content: "" counter(lst-ctn-kix_list_5-2, lower-roman) ". ";
}

.terms .lst-kix_list_8-3 > li {
  counter-increment: lst-ctn-kix_list_8-3;
}

.terms .lst-kix_list_5-1 > li:before {
  content: "" counter(lst-ctn-kix_list_5-1, lower-latin) ". ";
}

.terms .lst-kix_list_5-7 > li:before {
  content: "" counter(lst-ctn-kix_list_5-7, lower-latin) ". ";
}

.terms .lst-kix_list_5-6 > li:before {
  content: "" counter(lst-ctn-kix_list_5-6, decimal) ". ";
}

.terms .lst-kix_list_5-8 > li:before {
  content: "" counter(lst-ctn-kix_list_5-8, lower-roman) ". ";
}

.terms .lst-kix_list_9-4 > li {
  counter-increment: lst-ctn-kix_list_9-4;
}

.terms ol.lst-kix_list_6-6 {
  list-style-type: none;
}

.terms ol.lst-kix_list_6-7 {
  list-style-type: none;
}

.terms .lst-kix_list_5-4 > li:before {
  content: "" counter(lst-ctn-kix_list_5-4, lower-latin) ". ";
}

.terms ol.lst-kix_list_6-8 {
  list-style-type: none;
}

.terms .lst-kix_list_5-5 > li:before {
  content: "" counter(lst-ctn-kix_list_5-5, lower-roman) ". ";
}

.terms ol.lst-kix_list_6-2 {
  list-style-type: none;
}

.terms ol.lst-kix_list_6-3 {
  list-style-type: none;
}

.terms ol.lst-kix_list_6-4 {
  list-style-type: none;
}

.terms ol.lst-kix_list_6-5 {
  list-style-type: none;
}

.terms .lst-kix_list_10-3 > li {
  counter-increment: lst-ctn-kix_list_10-3;
}

.terms ol.lst-kix_list_1-0.start {
  counter-reset: lst-ctn-kix_list_1-0 0;
}

.terms .lst-kix_list_6-1 > li:before {
  content: "" counter(lst-ctn-kix_list_6-1, lower-latin) ". ";
}

.terms .lst-kix_list_6-3 > li:before {
  content: "" counter(lst-ctn-kix_list_6-3, decimal) ". ";
}

.terms .lst-kix_list_6-5 > li {
  counter-increment: lst-ctn-kix_list_6-5;
}

.terms .lst-kix_list_6-8 > li {
  counter-increment: lst-ctn-kix_list_6-8;
}

.terms .lst-kix_list_6-0 > li:before {
  content: "" counter(lst-ctn-kix_list_6-0, decimal) ". ";
}

.terms .lst-kix_list_6-4 > li:before {
  content: "" counter(lst-ctn-kix_list_6-4, lower-latin) ". ";
}

.terms .lst-kix_list_3-0 > li {
  counter-increment: lst-ctn-kix_list_3-0;
}

.terms ol.lst-kix_list_4-0.start {
  counter-reset: lst-ctn-kix_list_4-0 0;
}

.terms .lst-kix_list_3-6 > li {
  counter-increment: lst-ctn-kix_list_3-6;
}

.terms .lst-kix_list_6-2 > li:before {
  content: "" counter(lst-ctn-kix_list_6-2, lower-roman) ". ";
}

.terms .lst-kix_list_2-5 > li {
  counter-increment: lst-ctn-kix_list_2-5;
}

.terms .lst-kix_list_2-8 > li {
  counter-increment: lst-ctn-kix_list_2-8;
}

.terms ol.lst-kix_list_3-2.start {
  counter-reset: lst-ctn-kix_list_3-2 0;
}

.terms .lst-kix_list_6-8 > li:before {
  content: "" counter(lst-ctn-kix_list_6-8, lower-roman) ". ";
}

.terms .lst-kix_list_6-5 > li:before {
  content: "" counter(lst-ctn-kix_list_6-5, lower-roman) ". ";
}

.terms .lst-kix_list_6-7 > li:before {
  content: "" counter(lst-ctn-kix_list_6-7, lower-latin) ". ";
}

.terms ol.lst-kix_list_2-4.start {
  counter-reset: lst-ctn-kix_list_2-4 0;
}

.terms .lst-kix_list_6-6 > li:before {
  content: "" counter(lst-ctn-kix_list_6-6, decimal) ". ";
}

.terms ol.lst-kix_list_1-3 {
  list-style-type: none;
}

.terms ol.lst-kix_list_10-6.start {
  counter-reset: lst-ctn-kix_list_10-6 0;
}

.terms ol.lst-kix_list_1-4 {
  list-style-type: none;
}

.terms .lst-kix_list_2-7 > li:before {
  content: "" counter(lst-ctn-kix_list_2-7, lower-latin) ". ";
}

.terms .lst-kix_list_2-7 > li {
  counter-increment: lst-ctn-kix_list_2-7;
}

.terms ol.lst-kix_list_1-5 {
  list-style-type: none;
}

.terms .lst-kix_list_7-4 > li:before {
  content: "" counter(lst-ctn-kix_list_7-4, lower-latin) ". ";
}

.terms .lst-kix_list_7-6 > li:before {
  content: "" counter(lst-ctn-kix_list_7-6, decimal) ". ";
}

.terms ol.lst-kix_list_1-6 {
  list-style-type: none;
}

.terms ol.lst-kix_list_1-0 {
  list-style-type: none;
}

.terms .lst-kix_list_2-5 > li:before {
  content: "" counter(lst-ctn-kix_list_2-5, lower-roman) ". ";
}

.terms ol.lst-kix_list_6-2.start {
  counter-reset: lst-ctn-kix_list_6-2 0;
}

.terms ol.lst-kix_list_1-1 {
  list-style-type: none;
}

.terms ol.lst-kix_list_1-2 {
  list-style-type: none;
}

.terms .lst-kix_list_7-2 > li:before {
  content: "" counter(lst-ctn-kix_list_7-2, lower-roman) ". ";
}

.terms .lst-kix_list_7-6 > li {
  counter-increment: lst-ctn-kix_list_7-6;
}

.terms .lst-kix_list_8-6 > li {
  counter-increment: lst-ctn-kix_list_8-6;
}

.terms ol.lst-kix_list_10-3.start {
  counter-reset: lst-ctn-kix_list_10-3 0;
}

.terms ol.lst-kix_list_9-4.start {
  counter-reset: lst-ctn-kix_list_9-4 0;
}

.terms ol.lst-kix_list_4-6.start {
  counter-reset: lst-ctn-kix_list_4-6 0;
}

.terms ol.lst-kix_list_9-7 {
  list-style-type: none;
}

.terms .lst-kix_list_10-1 > li:before {
  content: "" counter(lst-ctn-kix_list_10-1, lower-latin) ". ";
}

.terms ol.lst-kix_list_9-8 {
  list-style-type: none;
}

.terms ol.lst-kix_list_3-0.start {
  counter-reset: lst-ctn-kix_list_3-0 0;
}

.terms ol.lst-kix_list_9-3 {
  list-style-type: none;
}

.terms ol.lst-kix_list_9-4 {
  list-style-type: none;
}

.terms .lst-kix_list_5-7 > li {
  counter-increment: lst-ctn-kix_list_5-7;
}

.terms .lst-kix_list_7-7 > li {
  counter-increment: lst-ctn-kix_list_7-7;
}

.terms ol.lst-kix_list_9-5 {
  list-style-type: none;
}

.terms .lst-kix_list_7-8 > li:before {
  content: "" counter(lst-ctn-kix_list_7-8, lower-roman) ". ";
}

.terms ol.lst-kix_list_9-6 {
  list-style-type: none;
}

.terms ol.lst-kix_list_4-3.start {
  counter-reset: lst-ctn-kix_list_4-3 0;
}

.terms ol.lst-kix_list_1-7 {
  list-style-type: none;
}

.terms .lst-kix_list_4-7 > li {
  counter-increment: lst-ctn-kix_list_4-7;
}

.terms ol.lst-kix_list_9-0 {
  list-style-type: none;
}

.terms ol.lst-kix_list_1-8 {
  list-style-type: none;
}

.terms ol.lst-kix_list_9-1 {
  list-style-type: none;
}

.terms ol.lst-kix_list_9-2 {
  list-style-type: none;
}

.terms .lst-kix_list_10-7 > li:before {
  content: "" counter(lst-ctn-kix_list_10-7, lower-latin) ". ";
}

.terms .lst-kix_list_7-8 > li {
  counter-increment: lst-ctn-kix_list_7-8;
}

.terms ol.lst-kix_list_2-5.start {
  counter-reset: lst-ctn-kix_list_2-5 0;
}

.terms .lst-kix_list_10-5 > li:before {
  content: "" counter(lst-ctn-kix_list_10-5, lower-roman) ". ";
}

.terms .lst-kix_list_9-8 > li {
  counter-increment: lst-ctn-kix_list_9-8;
}

.terms .lst-kix_list_10-3 > li:before {
  content: "" counter(lst-ctn-kix_list_10-3, decimal) ". ";
}

.terms .lst-kix_list_2-6 > li {
  counter-increment: lst-ctn-kix_list_2-6;
}

.terms .lst-kix_list_4-1 > li:before {
  content: "" counter(lst-ctn-kix_list_4-1, lower-latin) ". ";
}

.terms ol.lst-kix_list_7-3.start {
  counter-reset: lst-ctn-kix_list_7-3 0;
}

.terms .lst-kix_list_9-2 > li:before {
  content: "" counter(lst-ctn-kix_list_9-2, lower-roman) ". ";
}

.terms .lst-kix_list_4-3 > li:before {
  content: "" counter(lst-ctn-kix_list_4-3, decimal) ". ";
}

.terms .lst-kix_list_4-5 > li:before {
  content: "" counter(lst-ctn-kix_list_4-5, lower-roman) ". ";
}

.terms ol.lst-kix_list_5-7.start {
  counter-reset: lst-ctn-kix_list_5-7 0;
}

.terms .lst-kix_list_1-8 > li {
  counter-increment: lst-ctn-kix_list_1-8;
}

.terms .lst-kix_list_10-5 > li {
  counter-increment: lst-ctn-kix_list_10-5;
}

.terms ol.lst-kix_list_1-4.start {
  counter-reset: lst-ctn-kix_list_1-4 0;
}

.terms .lst-kix_list_5-5 > li {
  counter-increment: lst-ctn-kix_list_5-5;
}

.terms .lst-kix_list_3-5 > li {
  counter-increment: lst-ctn-kix_list_3-5;
}

.terms ol.lst-kix_list_1-1.start {
  counter-reset: lst-ctn-kix_list_1-1 0;
}

.terms .lst-kix_list_9-0 > li:before {
  content: "" counter(lst-ctn-kix_list_9-0, decimal) ". ";
}

.terms .lst-kix_list_3-4 > li {
  counter-increment: lst-ctn-kix_list_3-4;
}

.terms ol.lst-kix_list_4-4.start {
  counter-reset: lst-ctn-kix_list_4-4 0;
}

.terms .lst-kix_list_6-4 > li {
  counter-increment: lst-ctn-kix_list_6-4;
}

.terms ol.lst-kix_list_10-7 {
  list-style-type: none;
}

.terms ol.lst-kix_list_9-2.start {
  counter-reset: lst-ctn-kix_list_9-2 0;
}

.terms .lst-kix_list_9-6 > li:before {
  content: "" counter(lst-ctn-kix_list_9-6, decimal) ". ";
}

.terms ol.lst-kix_list_10-8 {
  list-style-type: none;
}

.terms .lst-kix_list_9-3 > li {
  counter-increment: lst-ctn-kix_list_9-3;
}

.terms ol.lst-kix_list_10-3 {
  list-style-type: none;
}

.terms .lst-kix_list_9-4 > li:before {
  content: "" counter(lst-ctn-kix_list_9-4, lower-latin) ". ";
}

.terms ol.lst-kix_list_10-4 {
  list-style-type: none;
}

.terms ol.lst-kix_list_10-5 {
  list-style-type: none;
}

.terms ol.lst-kix_list_10-6 {
  list-style-type: none;
}

.terms .lst-kix_list_6-3 > li {
  counter-increment: lst-ctn-kix_list_6-3;
}

.terms ol.lst-kix_list_10-0 {
  list-style-type: none;
}

.terms ol.lst-kix_list_10-1 {
  list-style-type: none;
}

.terms ol.lst-kix_list_1-3.start {
  counter-reset: lst-ctn-kix_list_1-3 0;
}

.terms ol.lst-kix_list_10-2 {
  list-style-type: none;
}

.terms ol.lst-kix_list_2-8.start {
  counter-reset: lst-ctn-kix_list_2-8 0;
}

.terms ol.lst-kix_list_8-8 {
  list-style-type: none;
}

.terms ol.lst-kix_list_1-2.start {
  counter-reset: lst-ctn-kix_list_1-2 0;
}

.terms ol.lst-kix_list_7-6.start {
  counter-reset: lst-ctn-kix_list_7-6 0;
}

.terms ol.lst-kix_list_6-1.start {
  counter-reset: lst-ctn-kix_list_6-1 0;
}

.terms ol.lst-kix_list_8-4 {
  list-style-type: none;
}

.terms ol.lst-kix_list_8-5 {
  list-style-type: none;
}

.terms ol.lst-kix_list_8-6 {
  list-style-type: none;
}

.terms ol.lst-kix_list_8-7 {
  list-style-type: none;
}

.terms ol.lst-kix_list_8-0 {
  list-style-type: none;
}

.terms .lst-kix_list_9-8 > li:before {
  content: "" counter(lst-ctn-kix_list_9-8, lower-roman) ". ";
}

.terms ol.lst-kix_list_8-1 {
  list-style-type: none;
}

.terms .lst-kix_list_1-1 > li:before {
  content: "" counter(lst-ctn-kix_list_1-1, lower-latin) ". ";
}

.terms ol.lst-kix_list_8-2 {
  list-style-type: none;
}

.terms ol.lst-kix_list_8-3 {
  list-style-type: none;
}

.terms .lst-kix_list_8-5 > li {
  counter-increment: lst-ctn-kix_list_8-5;
}

.terms .lst-kix_list_1-3 > li:before {
  content: "" counter(lst-ctn-kix_list_1-3, decimal) ". ";
}

.terms .lst-kix_list_10-4 > li {
  counter-increment: lst-ctn-kix_list_10-4;
}

.terms ol.lst-kix_list_10-5.start {
  counter-reset: lst-ctn-kix_list_10-5 0;
}

.terms .lst-kix_list_4-8 > li {
  counter-increment: lst-ctn-kix_list_4-8;
}

.terms .lst-kix_list_1-7 > li:before {
  content: "" counter(lst-ctn-kix_list_1-7, lower-latin) ". ";
}

.terms ol.lst-kix_list_5-8.start {
  counter-reset: lst-ctn-kix_list_5-8 0;
}

.terms ol.lst-kix_list_2-7.start {
  counter-reset: lst-ctn-kix_list_2-7 0;
}

.terms .lst-kix_list_1-3 > li {
  counter-increment: lst-ctn-kix_list_1-3;
}

.terms .lst-kix_list_1-5 > li:before {
  content: "" counter(lst-ctn-kix_list_1-5, lower-roman) ". ";
}

.terms ol.lst-kix_list_9-1.start {
  counter-reset: lst-ctn-kix_list_9-1 0;
}

.terms .lst-kix_list_5-6 > li {
  counter-increment: lst-ctn-kix_list_5-6;
}

.terms ol.lst-kix_list_7-5.start {
  counter-reset: lst-ctn-kix_list_7-5 0;
}

.terms .lst-kix_list_2-1 > li:before {
  content: "" counter(lst-ctn-kix_list_2-1, lower-latin) ". ";
}

.terms ol.lst-kix_list_6-0.start {
  counter-reset: lst-ctn-kix_list_6-0 0;
}

.terms .lst-kix_list_2-3 > li:before {
  content: "" counter(lst-ctn-kix_list_2-3, decimal) ". ";
}

.terms .lst-kix_list_4-2 > li {
  counter-increment: lst-ctn-kix_list_4-2;
}

.terms ol.lst-kix_list_3-1 {
  list-style-type: none;
}

.terms ol.lst-kix_list_3-2 {
  list-style-type: none;
}

.terms .lst-kix_list_3-1 > li {
  counter-increment: lst-ctn-kix_list_3-1;
}

.terms ol.lst-kix_list_3-3 {
  list-style-type: none;
}

.terms ol.lst-kix_list_3-4.start {
  counter-reset: lst-ctn-kix_list_3-4 0;
}

.terms .lst-kix_list_5-1 > li {
  counter-increment: lst-ctn-kix_list_5-1;
}

.terms ol.lst-kix_list_3-4 {
  list-style-type: none;
}

.terms ol.lst-kix_list_3-0 {
  list-style-type: none;
}

.terms .lst-kix_list_1-1 > li {
  counter-increment: lst-ctn-kix_list_1-1;
}

.terms .lst-kix_list_7-1 > li {
  counter-increment: lst-ctn-kix_list_7-1;
}

.terms .lst-kix_list_9-1 > li {
  counter-increment: lst-ctn-kix_list_9-1;
}

.terms ol.lst-kix_list_2-6.start {
  counter-reset: lst-ctn-kix_list_2-6 0;
}

.terms .lst-kix_list_3-0 > li:before {
  content: "" counter(lst-ctn-kix_list_3-0, decimal) ". ";
}

.terms ol.lst-kix_list_7-7.start {
  counter-reset: lst-ctn-kix_list_7-7 0;
}

.terms .lst-kix_list_3-1 > li:before {
  content: "" counter(lst-ctn-kix_list_3-1, lower-latin) ". ";
}

.terms .lst-kix_list_3-2 > li:before {
  content: "" counter(lst-ctn-kix_list_3-2, lower-roman) ". ";
}

.terms .lst-kix_list_8-1 > li:before {
  content: "" counter(lst-ctn-kix_list_8-1, decimal) ". ";
}

.terms ol.lst-kix_list_1-8.start {
  counter-reset: lst-ctn-kix_list_1-8 0;
}

.terms .lst-kix_list_4-0 > li {
  counter-increment: lst-ctn-kix_list_4-0;
}

.terms .lst-kix_list_8-2 > li:before {
  content: "" counter(lst-ctn-kix_list_8-2, lower-roman) ". ";
}

.terms .lst-kix_list_6-0 > li {
  counter-increment: lst-ctn-kix_list_6-0;
}

.terms .lst-kix_list_8-0 > li {
  counter-increment: lst-ctn-kix_list_8-0;
}

.terms .lst-kix_list_3-5 > li:before {
  content: "" counter(lst-ctn-kix_list_3-5, lower-roman) ". ";
}

.terms .lst-kix_list_10-0 > li {
  counter-increment: lst-ctn-kix_list_10-0;
}

.terms .lst-kix_list_3-4 > li:before {
  content: "" counter(lst-ctn-kix_list_3-4, lower-latin) ". ";
}

.terms .lst-kix_list_3-3 > li:before {
  content: "" counter(lst-ctn-kix_list_3-3, decimal) ". ";
}

.terms ol.lst-kix_list_3-5 {
  list-style-type: none;
}

.terms ol.lst-kix_list_3-6 {
  list-style-type: none;
}

.terms .lst-kix_list_8-0 > li:before {
  content: "" counter(lst-ctn-kix_list_8-0, upper-roman) ". ";
}

.terms ol.lst-kix_list_3-7 {
  list-style-type: none;
}

.terms ol.lst-kix_list_3-8 {
  list-style-type: none;
}

.terms .lst-kix_list_8-7 > li:before {
  content: "" counter(lst-ctn-kix_list_8-7, lower-latin) ". ";
}

.terms .lst-kix_list_3-8 > li:before {
  content: "" counter(lst-ctn-kix_list_3-8, lower-roman) ". ";
}

.terms .lst-kix_list_8-5 > li:before {
  content: "" counter(lst-ctn-kix_list_8-5, lower-roman) ". ";
}

.terms ol.lst-kix_list_10-7.start {
  counter-reset: lst-ctn-kix_list_10-7 0;
}

.terms .lst-kix_list_8-6 > li:before {
  content: "" counter(lst-ctn-kix_list_8-6, decimal) ". ";
}

.terms .lst-kix_list_2-0 > li {
  counter-increment: lst-ctn-kix_list_2-0;
}

.terms .lst-kix_list_8-3 > li:before {
  content: "" counter(lst-ctn-kix_list_8-3, decimal) ". ";
}

.terms .lst-kix_list_3-6 > li:before {
  content: "" counter(lst-ctn-kix_list_3-6, decimal) ". ";
}

.terms .lst-kix_list_3-7 > li:before {
  content: "" counter(lst-ctn-kix_list_3-7, lower-latin) ". ";
}

.terms .lst-kix_list_8-4 > li:before {
  content: "" counter(lst-ctn-kix_list_8-4, lower-latin) ". ";
}

.terms ol.lst-kix_list_5-0.start {
  counter-reset: lst-ctn-kix_list_5-0 0;
}

.terms .lst-kix_list_10-2 > li {
  counter-increment: lst-ctn-kix_list_10-2;
}

.terms ol.lst-kix_list_8-5.start {
  counter-reset: lst-ctn-kix_list_8-5 0;
}

.terms ol.lst-kix_list_4-2.start {
  counter-reset: lst-ctn-kix_list_4-2 0;
}

.terms ol.lst-kix_list_9-3.start {
  counter-reset: lst-ctn-kix_list_9-3 0;
}

.terms .lst-kix_list_8-8 > li:before {
  content: "" counter(lst-ctn-kix_list_8-8, lower-roman) ". ";
}

.terms ol.lst-kix_list_2-2 {
  list-style-type: none;
}

.terms ol.lst-kix_list_2-3 {
  list-style-type: none;
}

.terms ol.lst-kix_list_2-4 {
  list-style-type: none;
}

.terms ol.lst-kix_list_7-2.start {
  counter-reset: lst-ctn-kix_list_7-2 0;
}

.terms ol.lst-kix_list_2-5 {
  list-style-type: none;
}

.terms ol.lst-kix_list_10-1.start {
  counter-reset: lst-ctn-kix_list_10-1 0;
}

.terms .lst-kix_list_4-4 > li {
  counter-increment: lst-ctn-kix_list_4-4;
}

.terms ol.lst-kix_list_2-0 {
  list-style-type: none;
}

.terms ol.lst-kix_list_2-1 {
  list-style-type: none;
}

.terms .lst-kix_list_4-8 > li:before {
  content: "" counter(lst-ctn-kix_list_4-8, lower-roman) ". ";
}

.terms ol.lst-kix_list_6-4.start {
  counter-reset: lst-ctn-kix_list_6-4 0;
}

.terms .lst-kix_list_4-7 > li:before {
  content: "" counter(lst-ctn-kix_list_4-7, lower-latin) ". ";
}

.terms ol.lst-kix_list_5-6.start {
  counter-reset: lst-ctn-kix_list_5-6 0;
}

.terms ol.lst-kix_list_4-1.start {
  counter-reset: lst-ctn-kix_list_4-1 0;
}

.terms .lst-kix_list_7-3 > li {
  counter-increment: lst-ctn-kix_list_7-3;
}

.terms ol.lst-kix_list_4-8.start {
  counter-reset: lst-ctn-kix_list_4-8 0;
}

.terms .lst-kix_list_8-4 > li {
  counter-increment: lst-ctn-kix_list_8-4;
}

.terms ol.lst-kix_list_3-3.start {
  counter-reset: lst-ctn-kix_list_3-3 0;
}

.terms ol.lst-kix_list_10-8.start {
  counter-reset: lst-ctn-kix_list_10-8 0;
}

.terms ol.lst-kix_list_2-6 {
  list-style-type: none;
}

.terms ol.lst-kix_list_2-7 {
  list-style-type: none;
}

.terms ol.lst-kix_list_2-8 {
  list-style-type: none;
}

.terms ol.lst-kix_list_7-8.start {
  counter-reset: lst-ctn-kix_list_7-8 0;
}

.terms ol.lst-kix_list_7-1.start {
  counter-reset: lst-ctn-kix_list_7-1 0;
}

.terms ol.lst-kix_list_8-6.start {
  counter-reset: lst-ctn-kix_list_8-6 0;
}

.terms .lst-kix_list_3-3 > li {
  counter-increment: lst-ctn-kix_list_3-3;
}

.terms ol.lst-kix_list_6-3.start {
  counter-reset: lst-ctn-kix_list_6-3 0;
}

.terms ol.lst-kix_list_10-2.start {
  counter-reset: lst-ctn-kix_list_10-2 0;
}

.terms ol.lst-kix_list_5-5.start {
  counter-reset: lst-ctn-kix_list_5-5 0;
}

.terms ol.lst-kix_list_8-0.start {
  counter-reset: lst-ctn-kix_list_8-0 0;
}

.terms .lst-kix_list_7-0 > li:before {
  content: "" counter(lst-ctn-kix_list_7-0, decimal) ". ";
}

.terms .lst-kix_list_2-2 > li {
  counter-increment: lst-ctn-kix_list_2-2;
}

.terms ol.lst-kix_list_4-7.start {
  counter-reset: lst-ctn-kix_list_4-7 0;
}

.terms .lst-kix_list_6-2 > li {
  counter-increment: lst-ctn-kix_list_6-2;
}

.terms ol.lst-kix_list_5-0 {
  list-style-type: none;
}

.terms .lst-kix_list_2-6 > li:before {
  content: "" counter(lst-ctn-kix_list_2-6, decimal) ". ";
}

.terms .lst-kix_list_3-7 > li {
  counter-increment: lst-ctn-kix_list_3-7;
}

.terms ol.lst-kix_list_5-1 {
  list-style-type: none;
}

.terms ol.lst-kix_list_9-7.start {
  counter-reset: lst-ctn-kix_list_9-7 0;
}

.terms ol.lst-kix_list_5-2 {
  list-style-type: none;
}

.terms .lst-kix_list_2-4 > li:before {
  content: "" counter(lst-ctn-kix_list_2-4, lower-latin) ". ";
}

.terms .lst-kix_list_2-8 > li:before {
  content: "" counter(lst-ctn-kix_list_2-8, lower-roman) ". ";
}

.terms .lst-kix_list_7-1 > li:before {
  content: "" counter(lst-ctn-kix_list_7-1, lower-latin) ". ";
}

.terms .lst-kix_list_7-5 > li:before {
  content: "" counter(lst-ctn-kix_list_7-5, lower-roman) ". ";
}

.terms .lst-kix_list_9-6 > li {
  counter-increment: lst-ctn-kix_list_9-6;
}

.terms .lst-kix_list_6-6 > li {
  counter-increment: lst-ctn-kix_list_6-6;
}

.terms ol.lst-kix_list_5-4.start {
  counter-reset: lst-ctn-kix_list_5-4 0;
}

.terms .lst-kix_list_7-3 > li:before {
  content: "" counter(lst-ctn-kix_list_7-3, decimal) ". ";
}

.terms .lst-kix_list_10-0 > li:before {
  content: "" counter(lst-ctn-kix_list_10-0, decimal) ". ";
}

.terms .lst-kix_list_9-7 > li {
  counter-increment: lst-ctn-kix_list_9-7;
}

.terms ol.lst-kix_list_5-1.start {
  counter-reset: lst-ctn-kix_list_5-1 0;
}

.terms ol.lst-kix_list_5-7 {
  list-style-type: none;
}

.terms .lst-kix_list_6-7 > li {
  counter-increment: lst-ctn-kix_list_6-7;
}

.terms ol.lst-kix_list_5-8 {
  list-style-type: none;
}

.terms .lst-kix_list_10-6 > li {
  counter-increment: lst-ctn-kix_list_10-6;
}

.terms ol.lst-kix_list_5-3 {
  list-style-type: none;
}

.terms .lst-kix_list_8-7 > li {
  counter-increment: lst-ctn-kix_list_8-7;
}

.terms ol.lst-kix_list_5-4 {
  list-style-type: none;
}

.terms .lst-kix_list_1-7 > li {
  counter-increment: lst-ctn-kix_list_1-7;
}

.terms ol.lst-kix_list_3-8.start {
  counter-reset: lst-ctn-kix_list_3-8 0;
}

.terms ol.lst-kix_list_5-5 {
  list-style-type: none;
}

.terms ol.lst-kix_list_5-6 {
  list-style-type: none;
}

.terms .lst-kix_list_7-7 > li:before {
  content: "" counter(lst-ctn-kix_list_7-7, lower-latin) ". ";
}

.terms ol.lst-kix_list_10-0.start {
  counter-reset: lst-ctn-kix_list_10-0 0;
}

.terms ol.lst-kix_list_8-1.start {
  counter-reset: lst-ctn-kix_list_8-1 0;
}

.terms .lst-kix_list_7-5 > li {
  counter-increment: lst-ctn-kix_list_7-5;
}

.terms .lst-kix_list_9-5 > li {
  counter-increment: lst-ctn-kix_list_9-5;
}

.terms .lst-kix_list_5-8 > li {
  counter-increment: lst-ctn-kix_list_5-8;
}

.terms .lst-kix_list_10-4 > li:before {
  content: "" counter(lst-ctn-kix_list_10-4, lower-latin) ". ";
}

.terms .lst-kix_list_10-8 > li:before {
  content: "" counter(lst-ctn-kix_list_10-8, lower-roman) ". ";
}

.terms .lst-kix_list_4-0 > li:before {
  content: "" counter(lst-ctn-kix_list_4-0, decimal) ". ";
}

.terms .lst-kix_list_3-8 > li {
  counter-increment: lst-ctn-kix_list_3-8;
}

.terms ol.lst-kix_list_6-8.start {
  counter-reset: lst-ctn-kix_list_6-8 0;
}

.terms .lst-kix_list_10-2 > li:before {
  content: "" counter(lst-ctn-kix_list_10-2, lower-roman) ". ";
}

.terms .lst-kix_list_4-6 > li {
  counter-increment: lst-ctn-kix_list_4-6;
}

.terms ol.lst-kix_list_1-7.start {
  counter-reset: lst-ctn-kix_list_1-7 0;
}

.terms .lst-kix_list_4-4 > li:before {
  content: "" counter(lst-ctn-kix_list_4-4, lower-latin) ". ";
}

.terms ol.lst-kix_list_2-2.start {
  counter-reset: lst-ctn-kix_list_2-2 0;
}

.terms .lst-kix_list_1-5 > li {
  counter-increment: lst-ctn-kix_list_1-5;
}

.terms ol.lst-kix_list_6-5.start {
  counter-reset: lst-ctn-kix_list_6-5 0;
}

.terms .lst-kix_list_4-2 > li:before {
  content: "" counter(lst-ctn-kix_list_4-2, lower-roman) ". ";
}

.terms .lst-kix_list_4-6 > li:before {
  content: "" counter(lst-ctn-kix_list_4-6, decimal) ". ";
}

.terms .lst-kix_list_9-3 > li:before {
  content: "" counter(lst-ctn-kix_list_9-3, decimal) ". ";
}

.terms ol.lst-kix_list_7-0.start {
  counter-reset: lst-ctn-kix_list_7-0 0;
}

.terms .lst-kix_list_10-8 > li {
  counter-increment: lst-ctn-kix_list_10-8;
}

.terms .lst-kix_list_10-6 > li:before {
  content: "" counter(lst-ctn-kix_list_10-6, decimal) ". ";
}

.terms .lst-kix_list_9-1 > li:before {
  content: "" counter(lst-ctn-kix_list_9-1, lower-latin) ". ";
}

.terms ol.lst-kix_list_4-0 {
  list-style-type: none;
}

.terms ol.lst-kix_list_4-1 {
  list-style-type: none;
}

.terms ol.lst-kix_list_4-2 {
  list-style-type: none;
}

.terms ol.lst-kix_list_4-3 {
  list-style-type: none;
}

.terms .lst-kix_list_9-7 > li:before {
  content: "" counter(lst-ctn-kix_list_9-7, lower-latin) ". ";
}

.terms .lst-kix_list_2-4 > li {
  counter-increment: lst-ctn-kix_list_2-4;
}

.terms ol.lst-kix_list_6-7.start {
  counter-reset: lst-ctn-kix_list_6-7 0;
}

.terms ol.lst-kix_list_3-6.start {
  counter-reset: lst-ctn-kix_list_3-6 0;
}

.terms .lst-kix_list_9-5 > li:before {
  content: "" counter(lst-ctn-kix_list_9-5, lower-roman) ". ";
}

.terms .lst-kix_list_5-3 > li {
  counter-increment: lst-ctn-kix_list_5-3;
}

.terms ol.lst-kix_list_4-8 {
  list-style-type: none;
}

.terms .lst-kix_list_7-4 > li {
  counter-increment: lst-ctn-kix_list_7-4;
}

.terms .lst-kix_list_1-0 > li:before {
  content: "" counter(lst-ctn-kix_list_1-0, decimal) ". ";
}

.terms ol.lst-kix_list_4-4 {
  list-style-type: none;
}

.terms ol.lst-kix_list_4-5 {
  list-style-type: none;
}

.terms .lst-kix_list_1-2 > li:before {
  content: "" counter(lst-ctn-kix_list_1-2, lower-roman) ". ";
}

.terms ol.lst-kix_list_2-0.start {
  counter-reset: lst-ctn-kix_list_2-0 0;
}

.terms ol.lst-kix_list_4-6 {
  list-style-type: none;
}

.terms ol.lst-kix_list_4-7 {
  list-style-type: none;
}

.terms ol.lst-kix_list_8-4.start {
  counter-reset: lst-ctn-kix_list_8-4 0;
}

.terms .lst-kix_list_1-4 > li:before {
  content: "" counter(lst-ctn-kix_list_1-4, lower-latin) ". ";
}

.terms ol.lst-kix_list_3-5.start {
  counter-reset: lst-ctn-kix_list_3-5 0;
}

.terms .lst-kix_list_10-1 > li {
  counter-increment: lst-ctn-kix_list_10-1;
}

.terms .lst-kix_list_1-0 > li {
  counter-increment: lst-ctn-kix_list_1-0;
}

.terms .lst-kix_list_8-8 > li {
  counter-increment: lst-ctn-kix_list_8-8;
}

.terms .lst-kix_list_1-6 > li {
  counter-increment: lst-ctn-kix_list_1-6;
}

.terms .lst-kix_list_1-6 > li:before {
  content: "" counter(lst-ctn-kix_list_1-6, decimal) ". ";
}

.terms li.li-bullet-0:before {
  margin-left: -36pt;
  white-space: nowrap;
  display: inline-block;
  min-width: 36pt;
}

.terms .lst-kix_list_10-7 > li {
  counter-increment: lst-ctn-kix_list_10-7;
}

.terms .lst-kix_list_2-0 > li:before {
  content: "" counter(lst-ctn-kix_list_2-0, decimal) ". ";
}

.terms ol.lst-kix_list_2-1.start {
  counter-reset: lst-ctn-kix_list_2-1 0;
}

.terms ol.lst-kix_list_8-3.start {
  counter-reset: lst-ctn-kix_list_8-3 0;
}

.terms .lst-kix_list_4-5 > li {
  counter-increment: lst-ctn-kix_list_4-5;
}

.terms ol.lst-kix_list_9-8.start {
  counter-reset: lst-ctn-kix_list_9-8 0;
}

.terms .lst-kix_list_1-8 > li:before {
  content: "" counter(lst-ctn-kix_list_1-8, lower-roman) ". ";
}

.terms .lst-kix_list_2-2 > li:before {
  content: "" counter(lst-ctn-kix_list_2-2, lower-roman) ". ";
}

.terms ol.lst-kix_list_5-2.start {
  counter-reset: lst-ctn-kix_list_5-2 0;
}

.terms .lst-kix_list_8-2 > li {
  counter-increment: lst-ctn-kix_list_8-2;
}

ol {
  margin: 0;
  padding: 0;
}

table td,
table th {
  padding: 0;
}

.terms .c5 {
  margin-left: 0pt;
  padding-top: 0pt;
  list-style-position: inside;
  text-indent: 36pt;
  padding-bottom: 0pt;
  font-family: "Montserrat";
  orphans: 2;
  widows: 2;
  text-align: justify;
}

.terms .c2 {
  margin-left: 36pt;
  padding-top: 0pt;
  padding-bottom: 0pt;
  line-height: 1;
  orphans: 2;
  widows: 2;
  text-align: left;
  height: 12pt;
}

.terms .c4 {
  margin-left: 90pt;
  padding-top: 0pt;
  padding-left: 18pt;
  padding-bottom: 0pt;
  line-height: 1.1500000000000001;
  font-family: "Montserrat";
  orphans: 2;
  widows: 2;
  text-align: justify;
}

.terms .c3 {
  padding-top: 0pt;
  padding-bottom: 0pt;
  line-height: 1;
  orphans: 2;
  widows: 2;
  text-align: left;
  height: 12pt;
}

.terms .c1 {
  padding-top: 0pt;
  padding-bottom: 0pt;
  line-height: 1.1500000000000001;
  font-family: "Montserrat";
  orphans: 2;
  widows: 2;
  text-align: justify;
  height: 12pt;
}

.terms .c17 {
  -webkit-text-decoration-skip: none;
  color: #0563c1;
  font-weight: 400;
  text-decoration: underline;
  text-decoration-skip-ink: none;
  font-size: 11pt;
  font-family: "Montserrat";
}

.terms .c0 {
  color: var(--navy) !important;
  font-weight: 400;
  text-decoration: none;
  vertical-align: baseline;
  font-size: 11pt;
  font-family: "Montserrat";
  font-style: normal;
}

.terms .c9 {
  color: var(--navy) !important;
  font-weight: 700;
  text-decoration: none;
  vertical-align: baseline;
  font-size: 11pt;
  font-family: "Montserrat";
  font-style: normal;
}

.terms .c8 {
  padding-top: 0pt;
  padding-bottom: 0pt;
  line-height: 1.1500000000000001;
  font-family: "Montserrat";
  orphans: 2;
  widows: 2;
  text-align: justify;
}

.terms .c19 {
  padding-top: 0pt;
  padding-bottom: 0pt;
  line-height: 1.1500000000000001;
  font-family: "Montserrat";
  orphans: 2;
  widows: 2;
  text-align: center;
}

.terms .c15 {
  font-size: 11pt;
  font-family: "Montserrat";
  color: var(--navy) !important;
  font-weight: 400;
}

.terms .c11 {
  font-size: 11pt;
  font-family: "Montserrat";
  color: var(--navy) !important;
  font-weight: 700;
}

.terms .c12 {
  margin-left: 0pt;
  list-style-position: inside;
  text-indent: 72pt;
}

.terms .c16 {
  font-size: 11pt;
  font-family: "Montserrat";
  font-weight: 700;
}

.terms .c18 {
  background-color: #ffffff;
  max-width: 468pt;
  padding: 72pt 72pt 72pt 72pt;
}

.terms .c6 {
  padding: 0;
  margin: 0;
}

.terms .c10 {
  margin-left: 18pt;
  padding-left: 18pt;
}

.terms .c14 {
  color: inherit;
  text-decoration: inherit;
}

.terms .c13 {
  margin-left: 72pt;
}

.terms .c7 {
  margin-left: 36pt;
}

.terms .title {
  padding-top: 24pt;
  color: var(--navy) !important;
  font-weight: 700;
  font-size: 36pt;
  padding-bottom: 6pt;
  font-family: "Montserrat";
  line-height: 1;
  page-break-after: avoid;
  orphans: 2;
  widows: 2;
  text-align: left;
}

.terms .subtitle {
  padding-top: 18pt;
  color: #666666;
  font-size: 24pt;
  padding-bottom: 4pt;
  font-family: "Montserrat";
  line-height: 1;
  page-break-after: avoid;
  font-style: italic;
  orphans: 2;
  widows: 2;
  text-align: left;
}

.terms li {
  color: var(--navy) !important;
  font-size: 12pt;
  font-family: "Montserrat";
}

.terms p {
  margin: 0;
  color: var(--navy) !important;
  font-size: 12pt;
  font-family: "Montserrat";
}

.terms h1 {
  padding-top: 24pt;
  color: var(--navy) !important;
  font-weight: 700;
  font-size: 24pt;
  padding-bottom: 6pt;
  font-family: "Montserrat";
  line-height: 1;
  page-break-after: avoid;
  orphans: 2;
  widows: 2;
  text-align: left;
}

.terms h2 {
  padding-top: 18pt;
  color: var(--navy) !important;
  font-weight: 700;
  font-size: 18pt;
  padding-bottom: 4pt;
  font-family: "Montserrat";
  line-height: 1;
  page-break-after: avoid;
  orphans: 2;
  widows: 2;
  text-align: left;
}

.terms h3 {
  padding-top: 14pt;
  color: var(--navy) !important;
  font-weight: 700;
  font-size: 14pt;
  padding-bottom: 4pt;
  font-family: "Montserrat";
  line-height: 1;
  page-break-after: avoid;
  orphans: 2;
  widows: 2;
  text-align: left;
}

.terms h4 {
  padding-top: 12pt;
  color: var(--navy) !important;
  font-weight: 700;
  font-size: 12pt;
  padding-bottom: 2pt;
  font-family: "Montserrat";
  line-height: 1;
  page-break-after: avoid;
  orphans: 2;
  widows: 2;
  text-align: left;
}

.terms h5 {
  padding-top: 11pt;
  color: var(--navy) !important;
  font-weight: 700;
  font-size: 11pt;
  padding-bottom: 2pt;
  font-family: "Montserrat";
  line-height: 1;
  page-break-after: avoid;
  orphans: 2;
  widows: 2;
  text-align: left;
}

.terms h6 {
  padding-top: 10pt;
  color: var(--navy) !important;
  font-weight: 700;
  font-size: 10pt;
  padding-bottom: 2pt;
  font-family: "Montserrat";
  line-height: 1;
  page-break-after: avoid;
  orphans: 2;
  widows: 2;
  text-align: left;
}
