.add-account__input-title {
  color: var(--gray-70);
  font-weight: 600;
  font-size: 0.875rem;
  margin-bottom: 0.5rem;
}

.add-account-details__input {
  font-size: 1rem;
  font-weight: 500;
  border: 2px solid var(--separator-01);
  margin: 0;
  color: var(--navy);
  padding: 0.5rem 1rem;
  width: 100%;
}

.add-account-tray__step-selection {
  margin-bottom: 1rem;
}
