.pylon-input__label {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  margin: 0;
  cursor: pointer;
  user-select: none;
}
.pylon-input__checkbox > input[type="checkbox"] {
  display: none;
}

.pylon-input__checkbox > input[type="checkbox"] + label span {
  display: inline-block;
  vertical-align: middle;
  width: 24px;
  height: 24px;
  background: url(./unchecked.svg);
}
.pylon-input__checkbox > input[type="checkbox"]:checked + label span {
  background: url(./checked.svg);
}
