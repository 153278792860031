.collaboration-page-divider-wrapper {
  display: flex;
  width: 100%;
  height: 3rem;
  align-items: center;
  justify-content: space-evenly;
}

.sharing-type-title {
  margin: 0 1rem;
  text-transform: uppercase;
  font-size: 14px;
  font-weight: 600;
  color: var(--gray-70);
}

.sharing-section {
  margin-top: 2rem;
}
