.landing-page__register_leaf {
  padding: 0 2rem;
  width: fit-content !important;
  background-color: #000000 !important;
  height: 4.5rem !important;
  border: unset !important;
  border-radius: 36px 0px !important;
}

.landing-page__register_leaf:hover {
  background-color: #264501 !important;
}
