.table-row-modal {
  border: 2px;
  box-shadow: 0px 24px 48px rgba(0, 0, 0, 0.07);
  background: rgb(255, 255, 255);
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) !important;
}

.table-row__open-as-page {
  font-family: Montserrat;
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
  line-height: 24px;
  text-decoration: none;
  color: black;
}
