.collab-img {
  width: 44vw;
  height: 54vh;
  max-width: 976px;
  max-height: 668px;
  box-shadow: -10px 0px 84px 1px var(--separator-01);
  background-image: url("../../../core/assets/onboarding-collab.png");
  background-repeat: no-repeat;
  background-size: contain;
  border-radius: 24px;
}

.taxes-img {
  width: 44vw;
  height: 54vh;
  max-width: 976px;
  max-height: 668px;
  box-shadow: -10px 0px 84px 1px var(--separator-01);
  background-image: url("../../../core/assets/onboarding-taxes.png");
  background-repeat: no-repeat;
  background-size: contain;
  border-radius: 24px;
}
.onboarding-pill {
  width: 7.37rem;
  height: 2.19rem;
  background-color: var(--bg-01);
  border-radius: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 0.5rem;
}

.onboarding-page__bg-wrapper {
  background-color: white;
  background-image: url("../../../core/assets/full-wire-background.svg");
  background-repeat: repeat-x;
  background-size: 100%;
  background-position-y: 100%;
}

.onboarding-page-wrapper {
  width: 100%;
  height: 100%;
  margin: 0 auto;
  max-width: 1280px;
}

.onboarding-page-wrapper > div:first-child h3 {
  font-size: 8vh;
  line-height: 8vh;
}
.onboarding-page-wrapper > div:first-child h4 {
  font-size: 6vh;
  line-height: 8vh;
}
.onboarding {
  margin: auto auto;
}

.onboarding-top {
  height: 15vh;
}
.onboarding-top > div {
  font-size: 4.5vh;
  line-height: 5vh;
  font-family: Montserrat;
  font-weight: bold;
  text-align: center;
  vertical-align: bottom;
  height: 100%;
  display: flex;
  align-items: flex-end;
  color: var(--text-01);
}

.onboarding-top > div > div {
  width: 100%;
  text-align: center;
}

.onboarding-middle-summary {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 70vh;
}

.onboarding-middle-summary > div {
  font-size: 4vh;
  font-family: Montserrat;
  font-weight: 600;
  text-align: center;
  align-self: center;
  justify-content: center;
  width: 100%;
  padding: 0 2vw;
  color: var(--text-01);
}

.onboarding-middle {
  height: 70vh;
  display: flex;
  justify-content: center;
  align-items: center;
}
.onboarding-middle > div {
  font-size: 5vh;
  font-family: Montserrat;
  font-weight: 600;
  line-height: 6vh;
  text-align: center;
  align-self: center;
  justify-content: center;
  width: 100%;
  padding: 0 2vw;
  color: var(--text-01);
}

.onboarding-middle > div.onboarding-screenshots {
  width: 40%;
  text-align: left;
}

.onboarding-middle > div.manage-img > img {
  height: 50vh;
  box-shadow: -10px 0px 84px 1px var(--separator-02);
  border: 1px solid var(--separator-02);
  border-radius: 24px;
  align-self: center;
}
.onboarding-middle > div.manage-img {
  width: 60%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.onboarding-screenshots-paragraph {
  font-size: 2vh;
  line-height: 3vh;
  font-weight: normal;
  color: var(--text-01);
}
.onboarding-bottom {
  height: 15vh;
  display: flex;
  flex-direction: row;
  justify-content: center;
}
.onboarding-bottom button {
  height: 3rem !important;
  align-self: flex-start;
}

.security-section {
  height: 70vh;
  max-width: 1026px;
  margin: auto;
}

.security-info-boxes {
  display: flex;
  margin: 1rem 0;
  justify-content: space-between;
  font-size: 1rem;
  height: 37vh;
  margin-bottom: 3vh;
}

.security-statement {
  display: flex;
  justify-content: space-between;
  margin: 1rem 0;
  align-items: center;
}

.security-statement > div:first-child {
  width: 30%;
  display: flex;
  justify-content: flex-start;
}
.security-statement > div:nth-child(2) {
  width: 70%;
  display: flex;
  align-content: center;
}

.thanks-section {
  display: flex;
  justify-content: center;
  margin-bottom: 1rem;
}
.thanks-section > div:first-child {
  display: flex;
  flex-direction: column;
  width: 25%;
  padding: 0.5rem 0;
  text-align: left;
}
.thanks-section > div:nth-child(2) {
  display: flex;
  width: 75%;
  flex-wrap: wrap;
  justify-content: left;
}

/* -------  ------ -------------- */
/* -------  MOBILE -------------- */
/* -------  ------ -------------- */

@media screen and (max-width: 992px) {
  .onboarding-page-wrapper {
    overflow-x: hidden;
  }
  .onboarding-top {
    padding: 4vw;
    height: auto;
  }
  .onboarding-top > div {
    font-size: 2rem;
    line-height: 2.5rem;
    text-align: left;
    width: 60%;
  }
  .onboarding-top > div > div {
    text-align: left;
  }

  .onboarding-middle,
  .onboarding-middle.buttons-full-wide {
    height: auto;
    flex-wrap: wrap;
    padding: 0 4vw;
  }

  .onboarding-middle.buttons {
    padding: 0 2vw !important;
  }
  .onboarding-middle hr {
    display: none;
  }

  .onboarding-card.large,
  .onboarding-card.smaller,
  .onboarding-card__selected.large,
  .onboarding-card__selected.smaller {
    width: 45% !important;
    height: 45vw !important;
  }

  .buttons-full-wide .onboarding-card.large,
  .buttons-full-wide .onboarding-card__selected.large {
    width: 100% !important;
    height: 25vw !important;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-left: 0;
    margin-right: 0;
    font-size: 1.25rem;
  }

  .buttons-full-wide .onboarding-card__icon {
    width: 40%;
    margin-top: 0;
  }
  .buttons-full-wide .onboarding-card__text {
    width: 60%;
    position: relative;
    text-align: left;
    height: auto;
    margin-top: 1.75rem;
  }

  .onboarding-bottom {
    margin-top: 3vh;
  }

  button.next-step {
    width: 80%;
  }
  .security-section {
    height: auto;
    max-width: 1026px;
    margin: auto;
  }
  .security-info-boxes {
    display: flex;
    padding: 0 4vw;
    justify-content: space-between;
    font-size: 1rem;
    flex-wrap: wrap;
    height: auto;
  }

  .security-info-box {
    margin-bottom: 1rem;
  }

  .security-statement {
    display: flex;
    justify-content: space-between;
    margin: 4vh 4vw;
    align-items: center;
    flex-direction: column;
  }

  .security-statement > div:first-child {
    width: 100%;
    display: flex;
    justify-content: flex-start;
  }

  .security-statement > div:nth-child(2) {
    width: 100%;
    display: flex;
    align-content: flex-start;
  }
  .thanks-section {
    display: flex;
    justify-content: center;
    margin-top: 1rem;
    flex-direction: column;
    padding: 0 2vw;
    margin-bottom: 2vh;
  }
  .thanks-section h5:nth-child(2) {
    display: none;
  }
  .thanks-section > div:first-child {
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 0.5rem 0;
    text-align: left;
  }
  .thanks-section > div:nth-child(2) {
    display: flex;
    width: 100%;
    flex-wrap: wrap;
    justify-content: left;
  }
  .thanks-section .onboarding-card__selected.summary,
  .thanks-section .onboarding-card.summary {
    width: 45%;
    height: 40vw;
  }
  .thanks-section p {
    margin: 0;
  }

  .onboarding-middle > div.manage-img > img {
    height: 35vh;
  }

  .onboarding-middle > div.manage-img {
    width: 100%;
    margin-top: 2vh;
    margin-bottom: 2vh;
  }
  .onboarding-middle > div.onboarding-screenshots {
    width: 100%;
  }
  .onboarding-middle > div.onboarding-screenshots h4 {
    width: 80%;
  }
}
