.pdf-container-size {
  overflow: auto;
  max-height: calc(100vh - 9.5rem);
}

.preview .react-pdf__Page__canvas {
  height: 3rem !important;
  width: 3rem !important;
  object-fit: contain;
}

.preview .react-pdf__Page__textContent {
  display: none;
}

.document-icon__file-preview.preview
  > .react-pdf__message.react-pdf__message--error {
  height: 100%;
}
