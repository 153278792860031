.units-label {
  font-size: 2rem;
}
.credit-utilization .key-block {
  height: 0.6875rem;
  width: 0.6875rem;
  background-color: var(--gray-10);
  border-radius: 1rem;
  margin-top: 0.35rem !important;
}
.credit-utilization .best {
  background-color: var(--green-01);
  fill: var(--green-01);
}
.credit-utilization .better {
  background-color: var(--yellow-01);
  fill: var(--yellow-01);
}
.credit-utilization .good {
  background-color: var(--orange-01);
  fill: var(--orange-01);
}
.credit-utilization .bad {
  background-color: var(--red-01);
  fill: var(--red-01);
}

.line {
  background-color: var(--navy);
  fill: var(--navy);
}

.normal {
  background-color: var(--gray-10);
  fill: var(--gray-10);
}
.credit-utilization .chart-key {
  display: flex;
  flex-direction: row;
  margin-top: 0rem;
  margin-bottom: 2rem;
  align-self: center;
}
.credit-utilization .chart-key div {
  margin: 0 0.25rem;
  align-self: flex-start;
}
.credit-utilization .chart {
  display: flex;
  justify-content: center;
  margin-top: 0.5rem;
  width: 30%;
}
.overview-card.credit-utilization {
  min-width: calc(50% - 4rem);
  max-width: 40rem;
}
.chart > div {
  align-self: center;
}

.credit-utilization .keys {
  width: 65%;
  justify-content: flex-start;
  margin-top: 2rem;
}
@media screen and (max-width: 992px) {
  .chart {
    flex-direction: column;
    margin-bottom: 1.5rem;
  }
  .overview-card.credit-utilization {
    height: auto !important;
  }
  .credit-utilization .chart-key:first-child {
    margin-top: 0;
    margin-right: 1rem;
  }
  .credit-utilization .keys {
    width: 100%;
    justify-content: center;
    margin-top: 0;
  }
  .empty-credit-utilization > div:nth-child(2) {
    margin-top: 1rem;
  }
}
