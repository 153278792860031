.contact-support-modal {
  width: 35rem;
  min-height: 25rem;
  max-height: 40rem;
  padding: 3rem 3rem;
  display: flex;
  align-content: center;
  text-align: center;
  overflow: auto;
}

.contact-support-modal > * {
  width: 100%;
}
.contact-support-modal textarea {
  margin: 1rem;
  height: 15rem;
  width: calc(100% - 2rem);
}

.contact-support-modal .subtitle-02 {
  margin-top: 1.5rem;
  color: var(--text-01);
  font-weight: 500;
  font-size: 1.125rem;
}

.contact-support-modal button.button-small-width {
  width: 9.625rem;
  font-family: "Montserrat", sans-serif;
  font-size: 0.875rem;
  font-weight: 500;
}

.contact-support-modal__selected_file {
  position: relative;
  padding-right: 1rem;
  padding-bottom: 0.5rem;
}

.contact-support-modal__selected_file:nth-child(5n + 0) {
  padding-right: unset;
}
.contact-support-modal__file-text {
  width: 60px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

@media screen and (max-width: 992px) {
  .contact-support-modal.modal-base {
    width: 100%;
    max-height: unset;
    padding: 3rem 1rem;
    height: 100vh;
    transform: unset !important;
    left: 0;
    border-radius: 0;
    top: 0;
    bottom: 0;
  }
}
