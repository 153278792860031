.caret {
  height: 1.25rem;
  width: 1.25rem;
  margin-left: 0.25rem;
  display: inline-block;
  vertical-align: middle;
  fill: var(--navy);
  background: url("../../../../../core/assets/down-arrow.svg") 0px center
    no-repeat;
}
.caret-closed {
  height: 1.25rem;
  width: 1.25rem;
  margin-left: 0.25rem;
  display: inline-block;
  vertical-align: middle;
  background: url("../../../../../core/assets/down-arrow.svg") 0px center
    no-repeat;
  fill: var(--navy);
  transform: rotate(-90deg);
}

.collapsible {
  transition: font-size 100ms;
  font-size: 0;
  max-height: 0;
}

.collapsible.open {
  font-size: 0.875rem;
  max-height: fit-content;
}

.security-info-boxes {
  display: flex;
  margin: 3rem 0;
  justify-content: space-between;
}
.security-info-box {
  border: 2px solid var(--gray-30);
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  width: 21rem;
  justify-content: space-between;
}

@media screen and (max-width: 992px) {
  .security-info-boxes {
    padding: 0 !important;
  }
  .security-info-box {
    width: 100%;
  }
  .public-page-background .dashboard-content-wrapper {
    background: transparent;
  }
}
