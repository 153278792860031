.plan-input-box {
  width: 20.75rem;
}

.plan_select-option {
  width: 20.75rem;
}

.plan-tabs {
  width: 100%;
}

.plan-tab-list {
  padding-inline-start: 0 !important;
  padding-bottom: 1rem;
}

.plan-tab__tab-title {
  display: inline-block;
  position: relative;
  list-style: none;
  margin-top: 1rem;
  margin-right: 2rem;
  cursor: pointer;
  font-family: "Montserrat", sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 0.875rem;
  line-height: 1.5rem;
  color: var(--text-03);
  text-transform: uppercase;
}

.plan-tab__tab-title-selected {
  color: var(--blue-01);
}

.react-tabs__tab-panel--selected.plan-panel__content {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.scenario-plan__save-disabled {
  cursor: not-allowed !important;
}
