.detail-header {
  width: 100%;
  padding: 0;
}

.detail-body {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  background: var(--bg-01);
  padding: 3.5rem 4rem;
  border-radius: 0.75rem;
}

.top-line {
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 2rem;
}
.bottom-line {
  display: flex;
  justify-content: space-between;
  padding-bottom: 1rem;
}
.bottom-line > div {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.bottom-line > div.left > div {
  margin-right: 3rem;
}
.middle-line {
  display: flex;
  justify-content: flex-end;
  padding-bottom: 1rem;
  border-bottom: 1px solid var(--separator-02);
  margin-bottom: 1rem;
}
.right-side,
.left-side {
  display: flex;
  flex: 1;
}

.detail-body .right-side,
.detail-body .left-side {
  display: none;
}

.detail-body > div {
  width: 100%;
  margin-bottom: 1rem;
  background: white;
  padding: 1rem 2rem;
  border-radius: 0.75rem;
  box-shadow: 0px 1px 8px rgba(230, 233, 237, 0.8),
    0px 1px 4px rgba(181, 190, 202, 0.64);
}

.detail-body .investment-holdings-section > div {
  margin-top: 0 !important;
}

.detail-body .investment-holdings-highlights .section-title,
.detail-body .investment-holdings-section .section-title {
  font-family: "Montserrat";
  font-style: normal;
  line-height: 1.5rem;
  color: var(--text-02);
  font-size: 0.625rem;
  text-transform: uppercase;
  font-weight: 800;
}
.overview-component.investment-holdings-section.mt3 {
  margin-top: 0;
}
.detail-body .investment-holdings-highlights .summary-box {
  box-shadow: none;
  padding: 0;
}
.right-side {
  text-align: right;
  flex-direction: row-reverse;
}

.detail-header .right-side {
  white-space: nowrap;
}

.right-side > div {
  margin-bottom: 1rem;
}

.right-side .manage-account-tray__content-notes {
  padding: 0;
  margin: 0;
  margin-bottom: 2rem;
}

.detail-header .row-balance {
  font-family: "Montserrat", sans-serif;
  border: none;
  text-align: right;
  font-weight: 600;
  font-size: 2rem;
  color: var(--text-01);
  margin: 0;
  padding: 0;
}

.source-type {
  background-color: var(--bg-01);
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0.25rem 0.5rem;
  font-size: 10px;
  font-weight: 600;
  border-radius: 2px;
  margin-right: 0.5rem;
  text-transform: uppercase;
}

.info-cards {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  width: 100%;
  background: transparent !important;
  box-shadow: none !important;
  border: 0 !important;
  padding: 0rem !important;
  margin-bottom: 0 !important;
}

.info-cards > div {
  min-width: 31%;
  min-height: 8.75rem;
  background: white;
  padding: 2rem;
  border-radius: 0.5rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  box-shadow: 0px 1px 8px rgba(230, 233, 237, 0.8),
    0px 1px 4px rgba(181, 190, 202, 0.64);
  margin-bottom: 1rem;
}

.info-cards h6 {
  margin-bottom: 0;
}

.detail-body .transactions-table table {
  width: 100%;
}

.detail-body .expenses > h6,
.detail-body .transactions-table h6.mt3 {
  margin-top: 0;
  font-size: 0.625rem;
  text-transform: uppercase;
  font-weight: 700;
  color: var(--text-02);
}

@media screen and (max-width: 992px) {
  .account-details.layout-wide {
    padding: 0;
  }
  .detail-header {
    padding: 0 4vw;
  }

  .detail-body {
    padding: 2vh 4vw;
    border-radius: 0;
  }
  .detail-body > div {
    width: 100%;
    padding: 2vh 2vw;
  }
  .detail-header .top-line {
    flex-direction: column;
    align-items: center;
  }
  .detail-header .top-line .left-side,
  .detail-header .top-line .left-side > div,
  .detail-header .top-line .left-side > div > div,
  .detail-header .top-line .right-side > div,
  .detail-header .top-line .right-side > div > div {
    text-align: center;
    justify-content: center;
  }
  .detail-header .top-line .left-side {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-bottom: 2rem;
  }
  .detail-header .top-line .left-side .account__icon {
    margin-right: 0 !important;
    margin-bottom: 0.5rem;
  }
  .detail-header .middle-line {
    justify-content: center;
    overflow-x: scroll;
    flex-direction: column;
    align-items: center;
  }
  .detail-header .middle-line > div {
    margin-bottom: 0.5rem !important;
  }

  .detail-header .middle-line > div:last-child {
    margin-bottom: 0;
  }

  .detail-card__wrapper {
    min-width: 100%;
    min-height: 5.5rem;
  }

  .detail-card__value {
    height: 1.5rem;
  }

  .bottom-line .left {
    justify-content: space-between;
    width: 100%;
  }
  .bottom-line > div.left > div:last-child {
    margin-right: 0;
  }
  .balance-history .summary-box {
    background-color: var(--bg-01);
    margin-right: 0.5rem;
  }

  .balance-history .summary-box div:last-child div {
    background-color: white;
    width: 20rem;
    height: 5rem;
    padding: 0.5rem;
  }
}
