.manage-account-menu__option {
  height: 3.5rem;
  display: flex;
  justify-content: left;
  align-items: center;
  font-weight: 500;
  font-size: 0.875rem;
}

.manage-account-menu__option:hover {
  color: var(--navy);
}

.option-border {
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}

.spin__left-to-right {
  -webkit-animation-name: spin__left-to-right;
  -webkit-animation-duration: 2000ms;
  -webkit-animation-iteration-count: infinite;
  -webkit-animation-timing-function: linear;
  -moz-animation-name: spin__left-to-right;
  -moz-animation-duration: 2000ms;
  -moz-animation-iteration-count: infinite;
  -moz-animation-timing-function: linear;
  -ms-animation-name: spin__left-to-right;
  -ms-animation-duration: 2000ms;
  -ms-animation-iteration-count: infinite;
  -ms-animation-timing-function: linear;
  animation-name: spin__left-to-right;
  animation-duration: 2000ms;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
}

@-ms-keyframes spin__left-to-right {
  from {
    -ms-transform: rotate(360deg);
  }
  to {
    -ms-transform: rotate(0deg);
  }
}

@-moz-keyframes spin__left-to-right {
  from {
    -moz-transform: rotate(360deg);
  }
  to {
    -moz-transform: rotate(0deg);
  }
}

@-webkit-keyframes spin__left-to-right {
  from {
    -webkit-transform: rotate(360deg);
  }
  to {
    -webkit-transform: rotate(0deg);
  }
}

@keyframes spin__left-to-right {
  from {
    transform: rotate(360deg);
  }
  to {
    transform: rotate(0deg);
  }
}
