.budget-table .table-head,
.budget-table .table-row {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.budget-table .table-cell {
  border: none;
  flex-grow: 0;
  flex-shrink: 0;
  padding: 0;
  flex-basis: unset;
}

.budget-table .table-head .table-cell {
  font-family: "Montserrat", sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 0.75rem;
  line-height: 130%;

  letter-spacing: 0.04em;
  text-transform: uppercase;

  color: var(--text-03);
}

.budget-table .table-row .table-cell {
  font-family: "Montserrat", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 1rem;
  line-height: 130%;

  color: var(--navy);
}

.budget-table .table-body {
  display: flex;
  flex-direction: column;
}

.budget-table .table-head,
.budget-table .table-row {
  gap: 1.5rem;
}

.budget-table .table-row {
  width: 100%;
  padding: 1.5rem 0 0;
  display: flex;
}

.budget-row-wrapper {
  border-bottom: 1px solid var(--separator-02);
}

/* Column to fill rest of row*/
.budget-table .table-cell.category-column {
  width: 10rem;
  display: flex;
  justify-content: flex-start;
}

.budget-table .table-cell.budget-column {
  width: 6rem;
  display: flex;
  justify-content: flex-end;
}

.budget-table .table-cell.spend-column {
  width: 12rem;
  display: flex;
  justify-content: flex-end;
}

.budget-table .table-cell.filler-column {
  flex: 1 1;
}

.budget-table .table-cell.delete-column {
  width: 4.5rem;
  display: flex;
  justify-content: center;
  text-align: center;
}

@media screen and (max-width: 992px) {
  .budget-table .table-head,
  .budget-table .table-row {
    gap: 1rem;
  }
  .budget-table .table-row {
    padding: 1rem 0 0;
  }
  .budget-table .table-cell.category-column {
    width: 30%;
    flex: 30%;
  }

  .budget-table .table-cell.spend-column {
    width: 6rem;
  }
}

.budget-table__section-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: black;
  padding: 0.5rem;
}

.budget-table__add-more {
  padding: 0.5rem;
  cursor: pointer;
}

.budget-table__add-more:hover {
  background-color: var(--blue-50);
}
