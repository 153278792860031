.manage-trusted-contact-wrapper {
  cursor: pointer;
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
  margin-left: 0.5rem;
  margin-right: 0.5rem;
}

.manage-trusted-contact-wrapper:nth-child(4n + 1) {
  margin-left: 0;
}

.manage-trusted-contact-wrapper:nth-child(4n + 4) {
  margin-right: 0;
}

.suggested-contacts {
  border: 2px solid var(--separator-02);
  box-sizing: border-box;
  border-radius: 8px;
  width: 20.75rem;
  height: 17.5rem;
  display: flex;
  flex-direction: column;
  padding: 1.5rem;
  position: relative;
}

.suggested-contacts-cross-out {
  position: absolute;
  top: 0.5rem;
  right: 1rem;
  cursor: pointer;
}

.suggested-contact-invite {
  cursor: pointer;
  width: fit-content;
}

.suggested-contacts__contact-wrapper {
  gap: 2rem;
  flex-shrink: 0;
  flex-grow: 0;
}

.suggested-contacts__icon-background {
  height: 6.75rem;
  width: 5.5rem;
  border-radius: 9px;
  background: var(--bg-01);
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  flex-grow: 0;
}

.suggested-contacts__separator {
  margin-left: 1.5rem;
  flex-grow: 1;
  flex-shrink: 1;
  border-top: 1px solid var(--separator-01);
}

.suggested-contacts__suggestion-wrapper {
  flex-wrap: wrap;
  gap: 1.375rem;
  padding-top: 1rem;
}

.collaborator-ad {
}

.collab-title {
  width: 60%;
}

.collab-body {
  width: 80%;
}
@media screen and (max-width: 992px) {
  .collab-title {
    width: 65%;
  }

  .collab-body {
    width: 100%;
  }
}
