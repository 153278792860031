.accounts__header {
  display: flex;
  justify-content: space-between;
  margin: 0 1rem;
  margin-bottom: 1rem;
  align-items: center;
}

.accounts__section-category {
  color: var(--navy);
  border-bottom: 1px solid #c7cfe0;
  font-size: 1.25rem;
  padding: 1.5rem 0;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-family: "Montserrat", sans-serif;
  font-weight: 700;
}

h1 svg {
  vertical-align: baseline;
}
.accounts__section-balance {
  display: flex;
  align-items: center;
}

.account-filtering {
  display: flex;
  font-family: "Montserrat", sans-serif;
}
.account-filtering > div {
  margin-right: 2.5rem;
}

.accounts__section-carat > svg {
  animation-name: open;
  animation-duration: 0.5s;
  transform: rotate(0deg);
}

.accounts__section-carat-closed > svg {
  animation-name: close;
  animation-duration: 0.5s;
  transform: rotate(-90deg);
}
@keyframes close {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(-90deg);
  }
}
@keyframes open {
  0% {
    transform: rotate(-90deg);
  }
  100% {
    transform: rotate(0deg);
  }
}
.accounts__sub-section {
  margin-top: 1rem;
}

.accounts__sub-section-title {
  margin: 1rem 0rem;
  font-size: 13px;
  font-weight: 600;
}

.accounts__sub-section-content {
  margin-top: 1rem;
}
.accounts__sub-section-content-title {
  font-size: 1.25rem;
  font-weight: 600;
}
.accounts__sub-section-rows {
}

.nothing-here-icon {
  margin-bottom: 1.5rem;
}
.nothing-here-text {
  font-family: "Montserrat", sans-serif;
  font-size: 0.875rem;
  line-height: 2rem;
  margin-bottom: 1rem;
  font-weight: normal;
}
.nothing-here-line {
  border-bottom: 1px solid var(--gray-30);
}

.highlight-add-account {
  background: white;
  z-index: 99999999;
  position: relative;
}

.fake-tooltip {
  background: var(--text-01);
  position: absolute;
  left: 2rem;
  bottom: -15rem;
  border-radius: 0.5rem;
  padding: 1rem;
  color: white;
  padding: 2rem;
}
.fake-tooltip > div {
  text-align: center;
  width: 100%;
}
.fake-tooltip > div {
  text-align: center;
  width: 100%;
}
.fake-tooltip .arrow-up {
  width: 0;
  height: 0;
  border-left: 1rem solid transparent;
  border-right: 1rem solid transparent;

  border-bottom: 1rem solid var(--text-01);
  position: absolute;
  top: -1rem;
  left: calc(50% - 1rem);
}
.highlight-add-account .account-picker-option.selected,
.highlight-add-account .account-picker-option:hover {
  background: white;
  color: var(--navy);
  cursor: default;
}
