.balance-sheet-statement-row {
  display: flex;
  justify-content: space-between;
  cursor: pointer;
  padding: 0 0.5rem;
  margin-left: 1rem;
}

.balance-sheet-statement-row:nth-child(2n + 1) {
  background-color: #f6f6f6;
}
/* 
.balance-sheet-statement-row:hover {
  background-color: var(--blue-04);
} */

.balance-sheet-statement-row-header {
  /* background-color: #eeeeee; */
  padding: 0 0.5rem;
  background-color: #e2e2e2;
}
