.tax-state-results {
  display: flex;
  width: 100%;

  gap: 2rem;
}

.tax-state-results > div {
  height: 8rem;
  flex-basis: 50%;
  background-color: #f4f6fb;

  border: 2px solid var(--separator-01);
  border-radius: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
}

@media screen and (max-width: 992px) {
  .tax-state-results {
    display: flex;
    flex-direction: column;
  }

  .main-tax-result h6 {
    text-align: center;
  }
}
