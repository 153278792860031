.saved-scenario-card {
  display: flex;
  align-items: center;
  margin-left: -2.5rem;
  cursor: pointer;
}

.scenario-card-main {
  padding: 1rem 1rem;
  width: 17rem;
}

.saved-scenario-card:hover .scenario-card-main {
  background: var(--bg-01);
  border-radius: 6px;
}

.delete-scenario {
  visibility: hidden;
  padding-right: 0.5rem;

  cursor: pointer;
}

.saved-scenario-card:hover .delete-scenario.disabled {
  visibility: hidden;
}

.saved-scenario-card:hover .delete-scenario {
  visibility: visible;
}

.saved-scenario-wrapper {
  display: flex;
  flex-direction: column;
  margin-right: 2rem;
  width: 20rem;
}
