.pylon_button__leaf-base {
  cursor: pointer;
  user-select: none;
  position: relative; /* needed to make the loading spinner trick work */
  width: fit-content;
  height: fit-content;
}

.pylon_button__leaf-base.pylon_button__leaf-full-width {
  width: 100%;
}

.pylon_button__leaf-base.pylon_button__leaf-disabled {
  cursor: not-allowed;
}

.pylon-button__leaf-small-dense {
  border-radius: 0;
  padding: 0.5rem 1rem;

  /* montseratt/15/regular */
  font-family: Montserrat;
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
  line-height: 24px;
}

.pylon-button__leaf-small {
  border-radius: 0;
  padding: 1rem 1.5rem;

  /* montseratt/15/regular */
  font-family: Montserrat;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0px;
  text-align: center;
}

.pylon-button__leaf-large {
  border-radius: 0;
  padding: 1.5rem 2rem;

  /* montseratt/17/regular */
  font-family: Montserrat;
  font-size: 17px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0px;
  text-align: center;
}

.pylon-button__color-black {
  background: black;
  color: white;
}
.pylon-button__color-black:hover {
  background: #1f1f1f;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.24);
}
.pylon-button__color-black:active {
  /* button/pressed */
  background: #1f1f1f;
  box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.24);
}
.pylon-button__color-black.pylon_button__leaf-disabled {
  color: #757575;
  background: #f6f6f6;
}
.pylon-button__color-black.pylon_button__leaf-disabled:hover {
  box-shadow: unset;
}

.pylon-button__color-transparent {
  background: transparent;
  color: black;
}
.pylon-button__color-transparent:hover {
  background: transparent;
  color: #0029ff;
}
.pylon-button__color-transparent:active {
  background: #e6eaff;
  color: #001066;
}
.pylon-button__color-transparent:disabled {
  background: transparent;
  color: #757575;
}

.pylon-button__leaf-loading-spinner {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  justify-content: center;
  align-items: center;
}

.pylon-button__leaf-loading-spinner .spinner-border {
  width: 1.5rem;
  height: 1.5rem;
}

.pylon-button__leaf-small-dense
  .pylon-button__leaf-loading-spinner
  .spinner-border {
  width: 1rem;
  height: 1rem;
}
