.private-stock-section th {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: bold;
  font-size: 12px;
  line-height: 130%;
  /* identical to box height, or 16px */
  letter-spacing: 0.04em;
  text-transform: uppercase;
  color: var(--text-02);
  padding: 0rem 0;
  text-align: center;
}
.private-stock-section td {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  border-bottom: 1px solid var(--separator-01);
  padding: 1.5rem 0;
  color: var(--text-01);
  text-align: center;
}

.private-stock-section tr:last-child td {
  border: none;
}

.private-stock-section td:last-child,
.private-stock-section th:last-child {
  text-align: right;
}

.private-stock-section td:first-child,
.private-stock-section th:first-child {
  padding-right: 0.5rem;
  text-align: left;
}

.private-stock-section tr > *:nth-child(2) {
  text-align: left;
}

.private-stock-section table {
  margin-top: 1rem;
}

.private-stock-section .account__icon {
  margin: 0 !important;
  width: unset !important;
  height: 3rem !important;
}

.overview-component.private-stock-section {
  padding: 2rem;
  box-shadow: none;
  border: 1px dashed var(--separator-01);
}
.overview-component.private-stock-section > div {
  justify-content: center;
}
.overview-component.private-stock-section > div {
  justify-content: center;
  width: 60%;
}
.overview-component.private-stock-section > div:first-child {
  justify-content: center;
  width: 40%;
}
.overview-component.private-stock-section button {
  min-height: 2rem !important;
  width: 15rem;
}

.private-stock-section .section-title {
  font-size: 1.25rem;
  font-weight: 700;
  color: var(--text-01);
}

.table.private-stock .table-cell:last-child {
  text-align: right;
}

.table.private-stock .table-cell.owned {
  flex-basis: 10%;
}
.table.private-stock .table-cell.shares {
  flex-basis: 10%;
}
.table.private-stock .table-cell.fmv {
  flex-basis: 10%;
}
.table.private-stock .table-cell.current {
  flex-basis: 10%;
}

@media screen and (max-width: 992px) {
  .private-stock-section table {
    width: 100%;
  }
  .private-stock-section td:nth-last-child(2) {
    width: 8rem;
  }
  .private-stock-section .account-icon-cell {
    width: 1rem;
  }
  .private-stock-section table .shares {
    width: 6rem;
  }
  .private-stock-section > div {
    width: 100% !important;
    text-align: center;
  }
}
