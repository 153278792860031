.chart.balance-history > div {
  flex: 1;
  align-self: center;
}
@media screen and (max-width: 992px) {
  .chart.balance-history {
    flex-direction: unset;
    margin-bottom: unset;
  }
}
