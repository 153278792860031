@media screen and (max-width: 992px) {
  .public-page-content-wrapper .privacy.dashboard-content-inner {
    padding: 0;
    word-break: break-word;
  }
}

.privacy ol.lst-kix_list_7-0 {
  list-style-type: none;
}

.privacy ul.lst-kix_list_9-3 {
  list-style-type: none;
}

.privacy ul.lst-kix_list_9-4 {
  list-style-type: none;
}

.privacy ul.lst-kix_list_9-1 {
  list-style-type: none;
}

.privacy ul.lst-kix_list_9-2 {
  list-style-type: none;
}

.privacy ul.lst-kix_list_9-7 {
  list-style-type: none;
}

.privacy ol.lst-kix_list_7-4.start {
  counter-reset: lst-ctn-kix_list_7-4 0;
}

.privacy ul.lst-kix_list_9-8 {
  list-style-type: none;
}

.privacy ul.lst-kix_list_9-5 {
  list-style-type: none;
}

.privacy .lst-kix_list_7-0 > li {
  counter-increment: lst-ctn-kix_list_7-0;
}

.privacy ul.lst-kix_list_9-6 {
  list-style-type: none;
}

.privacy ol.lst-kix_list_7-5 {
  list-style-type: none;
}

.privacy ol.lst-kix_list_7-6 {
  list-style-type: none;
}

.privacy ol.lst-kix_list_7-7 {
  list-style-type: none;
}

.privacy ol.lst-kix_list_7-8 {
  list-style-type: none;
}

.privacy ol.lst-kix_list_7-1 {
  list-style-type: none;
}

.privacy ol.lst-kix_list_1-5.start {
  counter-reset: lst-ctn-kix_list_1-5 0;
}

.privacy ol.lst-kix_list_7-2 {
  list-style-type: none;
}

.privacy ul.lst-kix_list_9-0 {
  list-style-type: none;
}

.privacy ol.lst-kix_list_7-3 {
  list-style-type: none;
}

.privacy ol.lst-kix_list_7-4 {
  list-style-type: none;
}

.privacy .lst-kix_list_1-2 > li {
  counter-increment: lst-ctn-kix_list_1-2;
}

.privacy .lst-kix_list_7-2 > li {
  counter-increment: lst-ctn-kix_list_7-2;
}

.privacy .lst-kix_list_5-0 > li:before {
  content: "\0025cf  ";
}

.privacy .lst-kix_list_1-4 > li {
  counter-increment: lst-ctn-kix_list_1-4;
}

.privacy ol.lst-kix_list_1-6.start {
  counter-reset: lst-ctn-kix_list_1-6 0;
}

.privacy .lst-kix_list_5-3 > li:before {
  content: "\0025cf  ";
}

.privacy .lst-kix_list_5-2 > li:before {
  content: "\0025aa  ";
}

.privacy .lst-kix_list_5-1 > li:before {
  content: "o  ";
}

.privacy .lst-kix_list_5-7 > li:before {
  content: "o  ";
}

.privacy ul.lst-kix_list_8-4 {
  list-style-type: none;
}

.privacy ul.lst-kix_list_8-5 {
  list-style-type: none;
}

.privacy .lst-kix_list_5-6 > li:before {
  content: "\0025cf  ";
}

.privacy .lst-kix_list_5-8 > li:before {
  content: "\0025aa  ";
}

.privacy ul.lst-kix_list_8-2 {
  list-style-type: none;
}

.privacy ul.lst-kix_list_8-3 {
  list-style-type: none;
}

.privacy ul.lst-kix_list_8-8 {
  list-style-type: none;
}

.privacy ul.lst-kix_list_8-6 {
  list-style-type: none;
}

.privacy ul.lst-kix_list_8-7 {
  list-style-type: none;
}

.privacy .lst-kix_list_5-4 > li:before {
  content: "o  ";
}

.privacy .lst-kix_list_5-5 > li:before {
  content: "\0025aa  ";
}

.privacy ul.lst-kix_list_8-0 {
  list-style-type: none;
}

.privacy ul.lst-kix_list_8-1 {
  list-style-type: none;
}

.privacy ol.lst-kix_list_1-0.start {
  counter-reset: lst-ctn-kix_list_1-0 0;
}

.privacy .lst-kix_list_6-1 > li:before {
  content: "o  ";
}

.privacy .lst-kix_list_6-3 > li:before {
  content: "\0025cf  ";
}

.privacy .lst-kix_list_6-0 > li:before {
  content: "\0025cf  ";
}

.privacy .lst-kix_list_6-4 > li:before {
  content: "o  ";
}

.privacy .lst-kix_list_6-2 > li:before {
  content: "\0025aa  ";
}

.privacy .lst-kix_list_6-8 > li:before {
  content: "\0025aa  ";
}

.privacy .lst-kix_list_6-5 > li:before {
  content: "\0025aa  ";
}

.privacy .lst-kix_list_6-7 > li:before {
  content: "o  ";
}

.privacy .lst-kix_list_6-6 > li:before {
  content: "\0025cf  ";
}

.privacy ol.lst-kix_list_1-3 {
  list-style-type: none;
}

.privacy ol.lst-kix_list_1-4 {
  list-style-type: none;
}

.privacy .lst-kix_list_2-7 > li:before {
  content: "o  ";
}

.privacy ol.lst-kix_list_1-5 {
  list-style-type: none;
}

.privacy .lst-kix_list_7-4 > li:before {
  content: "" counter(lst-ctn-kix_list_7-4, lower-latin) ". ";
}

.privacy .lst-kix_list_7-6 > li:before {
  content: "" counter(lst-ctn-kix_list_7-6, decimal) ". ";
}

.privacy ol.lst-kix_list_1-6 {
  list-style-type: none;
}

.privacy ol.lst-kix_list_1-0 {
  list-style-type: none;
}

.privacy .lst-kix_list_2-5 > li:before {
  content: "\0025aa  ";
}

.privacy ol.lst-kix_list_1-1 {
  list-style-type: none;
}

.privacy ol.lst-kix_list_1-2 {
  list-style-type: none;
}

.privacy .lst-kix_list_7-2 > li:before {
  content: "" counter(lst-ctn-kix_list_7-2, lower-roman) ". ";
}

.privacy .lst-kix_list_7-6 > li {
  counter-increment: lst-ctn-kix_list_7-6;
}

.privacy ul.lst-kix_list_3-7 {
  list-style-type: none;
}

.privacy ul.lst-kix_list_3-8 {
  list-style-type: none;
}

.privacy .lst-kix_list_10-1 > li:before {
  content: "o  ";
}

.privacy ul.lst-kix_list_3-1 {
  list-style-type: none;
}

.privacy ul.lst-kix_list_3-2 {
  list-style-type: none;
}

.privacy .lst-kix_list_7-7 > li {
  counter-increment: lst-ctn-kix_list_7-7;
}

.privacy .lst-kix_list_7-8 > li:before {
  content: "" counter(lst-ctn-kix_list_7-8, lower-roman) ". ";
}

.privacy ul.lst-kix_list_3-0 {
  list-style-type: none;
}

.privacy ol.lst-kix_list_1-7 {
  list-style-type: none;
}

.privacy ul.lst-kix_list_3-5 {
  list-style-type: none;
}

.privacy ol.lst-kix_list_1-8 {
  list-style-type: none;
}

.privacy ul.lst-kix_list_3-6 {
  list-style-type: none;
}

.privacy ul.lst-kix_list_3-3 {
  list-style-type: none;
}

.privacy ul.lst-kix_list_3-4 {
  list-style-type: none;
}

.privacy .lst-kix_list_10-7 > li:before {
  content: "o  ";
}

.privacy .lst-kix_list_7-8 > li {
  counter-increment: lst-ctn-kix_list_7-8;
}

.privacy .lst-kix_list_10-5 > li:before {
  content: "\0025aa  ";
}

.privacy .lst-kix_list_10-3 > li:before {
  content: "\0025cf  ";
}

.privacy .lst-kix_list_4-1 > li:before {
  content: "o  ";
}

.privacy ol.lst-kix_list_7-3.start {
  counter-reset: lst-ctn-kix_list_7-3 0;
}

.privacy .lst-kix_list_9-2 > li:before {
  content: "\0025aa  ";
}

.privacy .lst-kix_list_4-3 > li:before {
  content: "\0025cf  ";
}

.privacy .lst-kix_list_4-5 > li:before {
  content: "\0025aa  ";
}

.privacy .lst-kix_list_1-8 > li {
  counter-increment: lst-ctn-kix_list_1-8;
}

.privacy ol.lst-kix_list_1-4.start {
  counter-reset: lst-ctn-kix_list_1-4 0;
}

.privacy ol.lst-kix_list_1-1.start {
  counter-reset: lst-ctn-kix_list_1-1 0;
}

.privacy .lst-kix_list_9-0 > li:before {
  content: "\0025cf  ";
}

.privacy .lst-kix_list_9-6 > li:before {
  content: "\0025cf  ";
}

.privacy .lst-kix_list_9-4 > li:before {
  content: "o  ";
}

.privacy ol.lst-kix_list_1-3.start {
  counter-reset: lst-ctn-kix_list_1-3 0;
}

.privacy ul.lst-kix_list_2-8 {
  list-style-type: none;
}

.privacy ol.lst-kix_list_1-2.start {
  counter-reset: lst-ctn-kix_list_1-2 0;
}

.privacy ol.lst-kix_list_7-6.start {
  counter-reset: lst-ctn-kix_list_7-6 0;
}

.privacy ul.lst-kix_list_2-2 {
  list-style-type: none;
}

.privacy ul.lst-kix_list_2-3 {
  list-style-type: none;
}

.privacy ul.lst-kix_list_2-0 {
  list-style-type: none;
}

.privacy ul.lst-kix_list_2-1 {
  list-style-type: none;
}

.privacy .lst-kix_list_9-8 > li:before {
  content: "\0025aa  ";
}

.privacy ul.lst-kix_list_2-6 {
  list-style-type: none;
}

.privacy .lst-kix_list_1-1 > li:before {
  content: "" counter(lst-ctn-kix_list_1-1, lower-latin) ". ";
}

.privacy ul.lst-kix_list_2-7 {
  list-style-type: none;
}

.privacy ul.lst-kix_list_2-4 {
  list-style-type: none;
}

.privacy ul.lst-kix_list_2-5 {
  list-style-type: none;
}

.privacy ul.lst-kix_list_10-0 {
  list-style-type: none;
}

.privacy .lst-kix_list_1-3 > li:before {
  content: "" counter(lst-ctn-kix_list_1-3, decimal) ". ";
}

.privacy ul.lst-kix_list_10-8 {
  list-style-type: none;
}

.privacy ul.lst-kix_list_10-7 {
  list-style-type: none;
}

.privacy .lst-kix_list_1-7 > li:before {
  content: "" counter(lst-ctn-kix_list_1-7, lower-latin) ". ";
}

.privacy ul.lst-kix_list_10-6 {
  list-style-type: none;
}

.privacy ul.lst-kix_list_10-5 {
  list-style-type: none;
}

.privacy ul.lst-kix_list_10-4 {
  list-style-type: none;
}

.privacy ul.lst-kix_list_10-3 {
  list-style-type: none;
}

.privacy .lst-kix_list_1-3 > li {
  counter-increment: lst-ctn-kix_list_1-3;
}

.privacy .lst-kix_list_1-5 > li:before {
  content: "" counter(lst-ctn-kix_list_1-5, lower-roman) ". ";
}

.privacy ul.lst-kix_list_10-2 {
  list-style-type: none;
}

.privacy ul.lst-kix_list_10-1 {
  list-style-type: none;
}

.privacy ol.lst-kix_list_7-5.start {
  counter-reset: lst-ctn-kix_list_7-5 0;
}

.privacy .lst-kix_list_2-1 > li:before {
  content: "o  ";
}

.privacy .lst-kix_list_2-3 > li:before {
  content: "\0025cf  ";
}

.privacy .lst-kix_list_1-1 > li {
  counter-increment: lst-ctn-kix_list_1-1;
}

.privacy .lst-kix_list_7-1 > li {
  counter-increment: lst-ctn-kix_list_7-1;
}

.privacy .lst-kix_list_3-0 > li:before {
  content: "\0025cf  ";
}

.privacy ol.lst-kix_list_7-7.start {
  counter-reset: lst-ctn-kix_list_7-7 0;
}

.privacy ul.lst-kix_list_5-7 {
  list-style-type: none;
}

.privacy ul.lst-kix_list_5-8 {
  list-style-type: none;
}

.privacy .lst-kix_list_3-1 > li:before {
  content: "o  ";
}

.privacy .lst-kix_list_3-2 > li:before {
  content: "\0025aa  ";
}

.privacy ul.lst-kix_list_5-5 {
  list-style-type: none;
}

.privacy ul.lst-kix_list_5-6 {
  list-style-type: none;
}

.privacy .lst-kix_list_8-1 > li:before {
  content: "o  ";
}

.privacy ol.lst-kix_list_1-8.start {
  counter-reset: lst-ctn-kix_list_1-8 0;
}

.privacy .lst-kix_list_8-2 > li:before {
  content: "\0025aa  ";
}

.privacy .lst-kix_list_3-5 > li:before {
  content: "\0025aa  ";
}

.privacy ul.lst-kix_list_5-0 {
  list-style-type: none;
}

.privacy .lst-kix_list_3-4 > li:before {
  content: "o  ";
}

.privacy ul.lst-kix_list_5-3 {
  list-style-type: none;
}

.privacy .lst-kix_list_3-3 > li:before {
  content: "\0025cf  ";
}

.privacy ul.lst-kix_list_5-4 {
  list-style-type: none;
}

.privacy ul.lst-kix_list_5-1 {
  list-style-type: none;
}

.privacy .lst-kix_list_8-0 > li:before {
  content: "\0025cf  ";
}

.privacy ul.lst-kix_list_5-2 {
  list-style-type: none;
}

.privacy .lst-kix_list_8-7 > li:before {
  content: "o  ";
}

.privacy .lst-kix_list_3-8 > li:before {
  content: "\0025aa  ";
}

.privacy .lst-kix_list_8-5 > li:before {
  content: "\0025aa  ";
}

.privacy .lst-kix_list_8-6 > li:before {
  content: "\0025cf  ";
}

.privacy .lst-kix_list_8-3 > li:before {
  content: "\0025cf  ";
}

.privacy .lst-kix_list_3-6 > li:before {
  content: "\0025cf  ";
}

.privacy .lst-kix_list_3-7 > li:before {
  content: "o  ";
}

.privacy .lst-kix_list_8-4 > li:before {
  content: "o  ";
}

.privacy .lst-kix_list_8-8 > li:before {
  content: "\0025aa  ";
}

.privacy ol.lst-kix_list_7-2.start {
  counter-reset: lst-ctn-kix_list_7-2 0;
}

.privacy .lst-kix_list_4-8 > li:before {
  content: "\0025aa  ";
}

.privacy .lst-kix_list_4-7 > li:before {
  content: "o  ";
}

.privacy ul.lst-kix_list_4-8 {
  list-style-type: none;
}

.privacy ul.lst-kix_list_4-6 {
  list-style-type: none;
}

.privacy ul.lst-kix_list_4-7 {
  list-style-type: none;
}

.privacy .lst-kix_list_7-3 > li {
  counter-increment: lst-ctn-kix_list_7-3;
}

.privacy ul.lst-kix_list_4-0 {
  list-style-type: none;
}

.privacy ul.lst-kix_list_4-1 {
  list-style-type: none;
}

.privacy ul.lst-kix_list_4-4 {
  list-style-type: none;
}

.privacy ul.lst-kix_list_4-5 {
  list-style-type: none;
}

.privacy ul.lst-kix_list_4-2 {
  list-style-type: none;
}

.privacy ul.lst-kix_list_4-3 {
  list-style-type: none;
}

.privacy ol.lst-kix_list_7-8.start {
  counter-reset: lst-ctn-kix_list_7-8 0;
}

.privacy ol.lst-kix_list_7-1.start {
  counter-reset: lst-ctn-kix_list_7-1 0;
}

.privacy .lst-kix_list_7-0 > li:before {
  content: "" counter(lst-ctn-kix_list_7-0, upper-roman) ". ";
}

.privacy .lst-kix_list_2-6 > li:before {
  content: "\0025cf  ";
}

.privacy .lst-kix_list_2-4 > li:before {
  content: "o  ";
}

.privacy .lst-kix_list_2-8 > li:before {
  content: "\0025aa  ";
}

.privacy .lst-kix_list_7-1 > li:before {
  content: "" counter(lst-ctn-kix_list_7-1, upper-latin) ". ";
}

.privacy .lst-kix_list_7-5 > li:before {
  content: "" counter(lst-ctn-kix_list_7-5, lower-roman) ". ";
}

.privacy .lst-kix_list_7-3 > li:before {
  content: "" counter(lst-ctn-kix_list_7-3, decimal) ". ";
}

.privacy .lst-kix_list_10-0 > li:before {
  content: "\0025cf  ";
}

.privacy .lst-kix_list_1-7 > li {
  counter-increment: lst-ctn-kix_list_1-7;
}

.privacy .lst-kix_list_7-7 > li:before {
  content: "" counter(lst-ctn-kix_list_7-7, lower-latin) ". ";
}

.privacy .lst-kix_list_7-5 > li {
  counter-increment: lst-ctn-kix_list_7-5;
}

.privacy .lst-kix_list_10-4 > li:before {
  content: "o  ";
}

.privacy .lst-kix_list_10-8 > li:before {
  content: "\0025aa  ";
}

.privacy .lst-kix_list_4-0 > li:before {
  content: "\0025cf  ";
}

.privacy .lst-kix_list_10-2 > li:before {
  content: "\0025aa  ";
}

.privacy ol.lst-kix_list_1-7.start {
  counter-reset: lst-ctn-kix_list_1-7 0;
}

.privacy .lst-kix_list_4-4 > li:before {
  content: "o  ";
}

.privacy .lst-kix_list_1-5 > li {
  counter-increment: lst-ctn-kix_list_1-5;
}

.privacy .lst-kix_list_4-2 > li:before {
  content: "\0025aa  ";
}

.privacy .lst-kix_list_4-6 > li:before {
  content: "\0025cf  ";
}

.privacy .lst-kix_list_9-3 > li:before {
  content: "\0025cf  ";
}

.privacy ol.lst-kix_list_7-0.start {
  counter-reset: lst-ctn-kix_list_7-0 0;
}

.privacy .lst-kix_list_10-6 > li:before {
  content: "\0025cf  ";
}

.privacy .lst-kix_list_9-1 > li:before {
  content: "o  ";
}

.privacy .lst-kix_list_9-7 > li:before {
  content: "o  ";
}

.privacy .lst-kix_list_9-5 > li:before {
  content: "\0025aa  ";
}

.privacy ul.lst-kix_list_6-6 {
  list-style-type: none;
}

.privacy ul.lst-kix_list_6-7 {
  list-style-type: none;
}

.privacy ul.lst-kix_list_6-4 {
  list-style-type: none;
}

.privacy ul.lst-kix_list_6-5 {
  list-style-type: none;
}

.privacy ul.lst-kix_list_6-8 {
  list-style-type: none;
}

.privacy .lst-kix_list_7-4 > li {
  counter-increment: lst-ctn-kix_list_7-4;
}

.privacy .lst-kix_list_1-0 > li:before {
  content: "" counter(lst-ctn-kix_list_1-0, upper-roman) ". ";
}

.privacy ul.lst-kix_list_6-2 {
  list-style-type: none;
}

.privacy ul.lst-kix_list_6-3 {
  list-style-type: none;
}

.privacy .lst-kix_list_1-2 > li:before {
  content: "" counter(lst-ctn-kix_list_1-2, lower-roman) ". ";
}

.privacy ul.lst-kix_list_6-0 {
  list-style-type: none;
}

.privacy ul.lst-kix_list_6-1 {
  list-style-type: none;
}

.privacy .lst-kix_list_1-4 > li:before {
  content: "" counter(lst-ctn-kix_list_1-4, lower-latin) ". ";
}

.privacy .lst-kix_list_1-0 > li {
  counter-increment: lst-ctn-kix_list_1-0;
}

.privacy .lst-kix_list_1-6 > li {
  counter-increment: lst-ctn-kix_list_1-6;
}

.privacy .lst-kix_list_1-6 > li:before {
  content: "" counter(lst-ctn-kix_list_1-6, decimal) ". ";
}

.privacy li.li-bullet-0:before {
  margin-left: -36pt;
  white-space: nowrap;
  display: inline-block;
  min-width: 36pt;
}

.privacy .lst-kix_list_2-0 > li:before {
  content: "\0025cf  ";
}

.privacy .lst-kix_list_1-8 > li:before {
  content: "" counter(lst-ctn-kix_list_1-8, lower-roman) ". ";
}

.privacy .lst-kix_list_2-2 > li:before {
  content: "\0025aa  ";
}

ol {
  margin: 0;
  padding: 0;
}

table td,
table th {
  padding: 0;
}

.privacy .c1 {
  background-color: #ffffff;
  margin-left: 36pt;
  padding-top: 0pt;
  padding-bottom: 0pt;
  line-height: 1.1500000000000001;
  orphans: 2;
  widows: 2;
  text-align: justify;
  height: 12pt;
}

.privacy .c4 {
  margin-left: 0pt;
  padding-top: 0pt;
  list-style-position: inside;
  text-indent: 36pt;
  padding-bottom: 0pt;
  line-height: 1.1500000000000001;
  orphans: 2;
  widows: 2;
  text-align: justify;
}

.privacy .c11 {
  margin-left: 18pt;
  padding-top: 0pt;
  padding-left: 18pt;
  padding-bottom: 0pt;
  line-height: 1.1500000000000001;
  orphans: 2;
  widows: 2;
  text-align: justify;
}

.privacy .c21 {
  margin-left: 18pt;
  padding-top: 0pt;
  padding-left: 18pt;
  padding-bottom: 0pt;
  line-height: 1.1500000000000001;
  orphans: 2;
  widows: 2;
  text-align: left;
}

.privacy .c5 {
  background-color: #ffffff;
  padding-top: 0pt;
  padding-bottom: 0pt;
  line-height: 1.1500000000000001;
  orphans: 2;
  widows: 2;
  text-align: justify;
}

.privacy .c2 {
  padding-top: 0pt;
  padding-bottom: 0pt;
  line-height: 1.1500000000000001;
  orphans: 2;
  widows: 2;
  text-align: justify;
  height: 12pt;
}

.privacy .c15 {
  -webkit-text-decoration-skip: none;
  color: #0563c1;
  font-weight: 400;
  text-decoration: underline;
  text-decoration-skip-ink: none;
  font-size: 11pt;
  font-family: "Montserrat";
}

.privacy .c0 {
  color: var(--navy);
  font-weight: 400;
  text-decoration: none;
  vertical-align: baseline;
  font-size: 11pt;
  font-family: "Montserrat";
  font-style: normal;
}

.privacy .c10 {
  margin-left: 36pt;
  padding-top: 0pt;
  padding-bottom: 0pt;
  line-height: 1.1500000000000001;
  orphans: 2;
  widows: 2;
  text-align: left;
}

.privacy .c12 {
  color: var(--navy);
  font-weight: 700;
  text-decoration: none;
  vertical-align: baseline;
  font-size: 11pt;
  font-family: "Montserrat";
  font-style: normal;
}

.privacy .c18 {
  padding-top: 0pt;
  padding-bottom: 0pt;
  line-height: 1;
  orphans: 2;
  widows: 2;
  text-align: center;
}

.privacy .c13 {
  padding-top: 0pt;
  padding-bottom: 0pt;
  line-height: 1.1500000000000001;
  orphans: 2;
  widows: 2;
  text-align: justify;
}

.privacy .c19 {
  color: var(--navy);
  text-decoration: none;
  vertical-align: baseline;
  font-style: italic;
}

.privacy .c24 {
  font-size: 11pt;
  font-family: "Montserrat";
  font-weight: 700;
}

.privacy .c16 {
  font-size: 11pt;
  font-family: "Montserrat";
  font-weight: 400;
}

.privacy .c8 {
  color: inherit;
  text-decoration: inherit;
}

.privacy .c3 {
  padding: 0;
  margin: 0;
}

.privacy .c23 {
  max-width: 468pt;
  padding: 72pt 72pt 72pt 72pt;
}

.privacy .c14 {
  margin-left: 54pt;
  padding-left: 18pt;
}

.privacy .c20 {
  color: var(--navy);
}

.privacy .c6 {
  background-color: #ffffff;
}

.privacy .c17 {
  margin-left: 72pt;
}

.privacy .c22 {
  margin-left: 36pt;
}

.privacy .c9 {
  text-indent: 36pt;
}

.privacy .c7 {
  height: 12pt;
}

.privacy .title {
  padding-top: 24pt;
  color: var(--navy);
  font-weight: 700;
  font-size: 36pt;
  padding-bottom: 6pt;
  font-family: "Montserrat";
  line-height: 1;
  page-break-after: avoid;
  orphans: 2;
  widows: 2;
  text-align: left;
}

.privacy .subtitle {
  padding-top: 18pt;
  color: #666666;
  font-size: 24pt;
  padding-bottom: 4pt;
  font-family: "Montserrat";
  line-height: 1;
  page-break-after: avoid;
  font-style: italic;
  orphans: 2;
  widows: 2;
  text-align: left;
}

.privacy li {
  color: var(--navy);
  font-size: 12pt;
  font-family: "Montserrat";
}

.privacy p {
  margin: 0;
  color: var(--navy);
  font-size: 12pt;
  font-family: "Montserrat";
}

.privacy h1 {
  padding-top: 24pt;
  color: var(--navy);
  font-weight: 700;
  font-size: 24pt;
  padding-bottom: 6pt;
  font-family: "Montserrat";
  line-height: 1;
  page-break-after: avoid;
  orphans: 2;
  widows: 2;
  text-align: left;
}

.privacy h2 {
  padding-top: 18pt;
  color: var(--navy);
  font-weight: 700;
  font-size: 18pt;
  padding-bottom: 4pt;
  font-family: "Montserrat";
  line-height: 1;
  page-break-after: avoid;
  orphans: 2;
  widows: 2;
  text-align: left;
}

.privacy h3 {
  padding-top: 14pt;
  color: var(--navy);
  font-weight: 700;
  font-size: 14pt;
  padding-bottom: 4pt;
  font-family: "Montserrat";
  line-height: 1;
  page-break-after: avoid;
  orphans: 2;
  widows: 2;
  text-align: left;
}

.privacy h4 {
  padding-top: 12pt;
  color: var(--navy);
  font-weight: 700;
  font-size: 12pt;
  padding-bottom: 2pt;
  font-family: "Montserrat";
  line-height: 1;
  page-break-after: avoid;
  orphans: 2;
  widows: 2;
  text-align: left;
}

.privacy h5 {
  padding-top: 11pt;
  color: var(--navy);
  font-weight: 700;
  font-size: 11pt;
  padding-bottom: 2pt;
  font-family: "Montserrat";
  line-height: 1;
  page-break-after: avoid;
  orphans: 2;
  widows: 2;
  text-align: left;
}

.privacy h6 {
  padding-top: 10pt;
  color: var(--navy);
  font-weight: 700;
  font-size: 10pt;
  padding-bottom: 2pt;
  font-family: "Montserrat";
  line-height: 1;
  page-break-after: avoid;
  orphans: 2;
  widows: 2;
  text-align: left;
}
