.audit-trail {
  display: flex;
  flex-direction: column;
  color: var(--navy);
  font-weight: 500;
}

.audit-trail__item-wrapper {
  display: flex;
  flex-direction: column;
}

.audit-trail__item {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 1rem 0;
  border-bottom: 2px solid var(--separator-02);
  color: var(--navy);
}

.audit-trail__dot {
  height: 0.688rem;
  width: 0.688rem;
  margin-right: 0.5rem;
  background: url("../../../../../core/assets/audit-trail-dot.svg") 0px center
    no-repeat;
  align-self: flex-start;
  margin-top: 0.5rem;
}

.audit-trail__action {
  margin-right: 0.5rem;
  color: var(--gray-70);
}

.audit-trail__time {
  margin-right: 0.5rem;
  color: var(--gray-50);
  white-space: nowrap;
}

.audit-trail__divider {
  margin-left: 0.25rem;
  height: 2rem;
  width: 1px;
  border: 1px solid var(--gray-30);
}
