.asset-note-cell {
  flex-grow: 0;
  flex-shrink: 0;
  padding-right: 0.5rem;
}

.asset-note-cell.asset-note {
  flex-grow: 1;
  flex-shrink: 1;
  word-wrap: break-word;
  /* border: 2px solid var(--separator-01);
  border-radius: 6px; */
}

.asset-note-cell.action {
  cursor: pointer;
}

.asset-note-cell:last-child {
  padding-right: unset;
}

.asset-note__note-input {
  resize: none;
  height: 24rem;
  font-size: 15px;
}

.asset-note-cell.asset-note pre {
  margin-bottom: 0;
}

.asset-note__menu {
  cursor: pointer;
  position: relative;
}

.asset-note__menu-item-wrapper {
  border: none;
  box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.16);
  border-radius: 2px;
}
.asset-note__menu-item {
  width: 15rem;
  display: flex;
  align-items: center;
  user-select: none;
}
.asset-note__menu-item:hover {
  background: var(--yellow-50);
}
