.file-upload-dropzone {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.file-upload-form-boarder {
  margin: 1rem 0;
  height: 3.875rem;
  border: 2px dashed #b5beca;
  border-radius: 6px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.file-upload-form__choose-file-button {
  height: 2rem;
  width: fit-content;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
  align-items: center;
  justify-content: center;
  display: flex;
  font-size: 0.875rem;
  font-weight: normal;
  min-height: unset !important;
}
