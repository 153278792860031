.ds-home-button {
  width: 9rem;
  border-radius: 2rem;
  background-color: #0f436c;
  border: #0f436c;
  padding: 0.5rem;
  color: white;
}

.home {
  height: 100%;
  width: 75%;
  margin: auto;
  display: flex;
  flex-direction: column;
}

.home-background {
  width: 50vw;
  height: 100vh;
  position: absolute;
  right: 0;
  bottom: 0px;
  left: auto;
}

.home-background .flat-back {
  width: 40vw;
  height: 100vh;
  background-color: var(--bg-01);
  z-index: 1;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
}
.home-background .bg {
  height: 100vh;
  width: 100vh;
  background-color: transparent;
  background-image: url("../../core/assets/landing-page.png");
  background-size: 100%;
  background-repeat: no-repeat;
  background-position-x: 100%;
  background-position-y: 100%;
  z-index: 2;
  position: absolute;
  right: 0;
  top: 0;
  overflow: hidden;
}
