.overview-vertical-section {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-bottom: 40px;
}

.overview .topline > div {
  border: 1px solid var(--gray-100);
  padding: 1.5rem;
  margin: 0 0.75rem;
  width: 33%;
  display: flex;
  text-decoration: none;
  cursor: pointer;
}
.overview .topline > div > div {
  width: 100%;
  margin-top: -1rem;
}
.overview .topline > div:hover {
  box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.16);
}
.overview .topline > div:active {
  background: var(--gray-50);
  box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.16);
}
.overview .topline > div:first-child {
  margin-left: 0;
}
.overview .topline > div:last-child {
  margin-right: 0;
}

.overview .account-detail-tab-list {
  margin-bottom: -1px;
}

@media screen and (max-width: 992px) {
  .overview .topline > div {
    border: 1px solid var(--gray-100);
    padding: 1.5rem;
    margin: 0.5rem 0;
    width: 100%;
    display: flex;
    text-decoration: none;
  }
}

/* Pull into components */

/* CHANGE IN VALUE */

.change-in-value-label {
  background: transparent;
}
.change-in-value-label > div {
  display: inline-flex;
  border-radius: 0.5rem;
  padding: 0.125rem 0.5rem;
}
.change-in-value-label > div > div {
  display: flex;
}

/* CREDIT SCORE */

.credit-score-block {
  width: 50%;
}
.credit-score-block:nth-child(2) {
  border-left: 1px solid var(--gray-100);
  padding-left: 24px;
}
.credit-score-block p {
  margin-top: 0;
}
