.networth-summary {
  height: auto;
  margin: 1rem;
  padding: 1rem;
  line-height: 2rem;
  border-radius: 6px;
  width: 20rem;
  background-color: #f3f6fc;
}

.networth-summary-title {
  height: "1.125rem";
  color: var(--navy);
}

.subsection-title {
  color: var(--navy);
  margin-top: 1rem;
  font-weight: normal;
}
.subsection-line-item {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  white-space: nowrap;
}
.subsection-line-item > div:first-child {
  color: var(--gray-70);
}
.subsection-total {
  display: flex;
  justify-content: space-between;
  margin-top: 1rem;
  margin-right: -0.5rem;
  margin-left: -0.5rem;
  padding: 1rem 0.5rem;
  background-color: white;
  border-radius: 6px;
}
