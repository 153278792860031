.pylon-a-link {
  font-family: "Montserrat", sans-serif;
  text-decoration: none;
}

.pylon-a-link__default {
  font-style: normal;
  font-weight: 400;
  font-size: 0.875rem;
  line-height: 1.813rem;
}
