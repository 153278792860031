.recipient-item-name,
.recipient-item-email {
  overflow: hidden;
  width: 95%;
  text-overflow: ellipsis;
}

.recipient-item {
  flex: 1;
  margin-right: 1rem;
  z-index: 10;
}
