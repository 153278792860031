.home__wrapper {
  height: 100%;
  display: flex;
  flex-direction: row;
  margin: auto;
  left: 0;
  width: 75%;
  position: absolute;
  right: 0;
}

.home__email-wrapper {
  height: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin: auto;
}

.home__input:focus,
.home__input:active {
  background-color: #ffffff;
  border: 1px solid black;
  outline: none;
}

.home__email-section {
  width: 40%;
  height: 25rem;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
}

.home__email-section-title {
  font-size: 48px;
  font-weight: 800;
  line-height: 3.5rem;
  color: var(--text-01);
}

.home__email-section-subtitle {
  font-size: 16px;
  font-weight: 400;
}

.home__email-inputs {
  display: flex;
  height: 58px;
  flex-direction: row;
  align-items: center;
  position: relative;
}

.home__image {
  height: 100vh;
  width: 50%;
  background-image: url("../../../../core/assets/landing-page.png");
}

.home__green-check {
  margin: 0 1rem;
  height: 32px;
  width: 32px;
  background: url("../../../../core/assets/greencheck.svg") no-repeat center;
}

.home__animation-holder {
  width: 0%;
  height: 250px;
  position: absolute;
  transition: width 0.75s;
  border-radius: 0.5rem;
  display: none;
}

.home__text-animation-holder {
  display: none;
  flex-direction: row;
  height: 100%;
  align-items: center;
  visibility: hidden;
  opacity: 0;
  transition: opacity 1s;
  display: none;
}

.home__text-animiation-success {
  display: flex;
  visibility: visible;
  opacity: 1;
}

.home__success-animation {
  width: 100vw;
  background-color: #cfeedd;
  border: 1px solid #cfeedd;
  display: flex;
}
.landing .left {
  height: 100%;
  width: 40vw;
  position: absolute;
  bottom: 0;
  left: 10%;
  justify-content: center;
  overflow: hidden;
  z-index: 3;
}
.landing .left > div {
  margin-top: -16vh;
}

.landing-mobile .tag-line-1,
.landing .tag-line-1 {
  font-family: Montserrat;
  font-style: normal;
  font-weight: bold;
  font-size: 28.7935px;
  line-height: 39px;
  color: var(--blue-01);
}

.landing-mobile .tag-line-2,
.landing .tag-line-2 {
  font-family: Montserrat;
  font-style: normal;
  font-weight: 600;
  font-size: 5vh;
  line-height: 6vh;
  color: var(--text-01);

  margin-top: 4rem;
}

.landing-mobile .form-title,
.landing .form-title {
  font-family: Montserrat;
  font-style: normal;
  font-weight: normal;
  font-size: 24px;
  line-height: 110%;
  display: block;
  margin-bottom: 1rem;
  color: var(--text-01);
}
.landing .form {
  margin-top: 6rem;
  width: 60%;
  min-width: 432px;
}

.landing-mobile .form {
  margin-top: 6rem;
  width: 100%;
}
.landing-mobile .form input,
.landing .form input {
  height: 3.5rem;
  background: white;
  border: 2px solid var(--separator-01) !important;
  color: var(--text-02);
  margin: 0;
  margin-right: 1rem;
  margin-left: 4px;
  border-radius: 0.5rem;
}

.landing-mobile button,
.landing button {
  color: white;
  background-color: var(--blue-01);
  cursor: pointer;
  text-align: center;
  text-decoration: none;
  font-weight: bold;
  box-sizing: border-box;
  border: none;
  border-radius: 0.5rem;
  height: 3.5rem;
  padding: inherit auto;
  min-width: 6rem;
  width: 6rem;
  margin-top: 1rem;
}

.landing {
  background-image: url("../../../../core/assets/full-wire-background.svg");
  background-repeat: repeat-x;
  background-size: 100%;
  background-position-y: 90%;
  width: 100vw;
  height: 100vh;
  margin-left: -185px;
}

.home-nav {
  display: none !important;
}

.landing .logo-mobile {
  display: none;
}
.landing .logo-desktop {
  display: block;
}

@media screen and (max-width: 1300px) {
  .landing .right .bg {
    background-size: 80%;
  }
}

.landing-mobile {
  display: none !important;
}

.landing {
  display: block !important;
}

.landing .home__email-section-subtitle {
  margin: 0.5rem;
  color: var(--red-01);
}

.marketing .form {
  display: flex;
}
.marketing .register {
  display: none;
}

.marketing.home-2 .form,
.marketing.networth-1 .form,
.marketing.cashflow-1 .form {
  display: none;
}
.marketing.home-2 .register,
.marketing.networth-1 .register,
.marketing.cashflow-1 .register {
  display: flex;
}
.marketing.home-2 .landing-page__register,
.marketing.networth-1 .landing-page__register,
.marketing.cashflow-1 .landing-page__register {
  width: 18rem;
  max-width: 18rem;
}
.marketing .form.hide {
  display: none;
}
.marketing .form.show {
  display: flex;
}

.marketing.home-2 .form.show,
.marketing.networth-1 .form.show,
.marketing.cashflow-1 .form.show {
  display: none;
}

@media screen and (max-width: 992px) {
  .landing-mobile {
    display: block !important;
    text-align: center;
    padding: 2vh 2vw;
  }
  .landing-mobile .home-background {
    position: relative;
    height: 450px;
    width: unset;
    margin-top: 1vh;
  }
  .landing-mobile .home-background .bg {
    position: relative;
    background-image: url(../../../../core/assets/landing-page-full.png);
    height: 450px;
    width: 100%;
    background-size: 90%;
    background-position-x: 53%;
    background-position-y: 0;
    z-index: 1;
    box-shadow: inset 0px -60px 10px 10px #ffffff;
    -webkit-box-shadow: inset 0px -60px 10px 10px #ffffff;
    -webkit-appearance: none;
  }
  .landing-mobile .home-background .flat-back {
    height: 300px;
    width: 90%;
    background-color: var(--bg-01);
    z-index: 1;
    position: absolute;
    top: 25%;
    bottom: 0;
    right: 5%;
    left: 5%;
  }
  .landing {
    display: none !important;
  }
  .home {
    width: 95vw !important;
  }
  .landing {
    margin-left: 0;
    width: auto;
  }
  .landing .logo-mobile {
    display: block;
  }
  .landing .logo-desktop {
    display: none;
  }
  .landing .right {
    display: none;
  }

  .landing .left > div {
    margin-top: 0 !important;
  }
  .landing .left {
    height: 100vh;
    width: 90%;
    justify-content: center;
    overflow: hidden;
    position: relative;
  }

  .landing-mobile .form {
    margin-top: 4vh;
    min-width: 340px;
  }

  .landing .form .flex-row.fields {
    flex-direction: column !important;
    justify-content: flex-start;
    margin-left: 2px;
  }

  .landing .form .flex-row.fields input {
    margin-bottom: 1rem;
  }

  .landing-mobile .tag-line-1 {
    margin-top: 2vh;
    font-size: 1.675rem;
  }

  .landing-mobile button {
    width: 100%;
    min-width: 100%;
  }
  .landing-mobile .tag-line-2 {
    font-size: 2.5rem;
    line-height: 3rem;
    margin-top: 2vh !important;
  }
  .home-page-links a {
    font-size: 0.75rem;
    text-transform: uppercase;
    font-weight: 600;
  }
  .home-page-links > div {
    display: flex;
    flex-direction: row;
  }
}
