.pylon-input.transaction-search-input {
  background: white;
  border: none;
  border-bottom: 1px solid var(--gray-100);
}
.pylon-input.transaction-search-input:hover {
  border: none;
  border-bottom: 1px solid var(--gray-100);
}
.pylon-input.transaction-search-input:disabled {
  border: none;
  border-bottom: 1px solid var(--gray-100);
}
.pylon-input.transaction-search-input:focus {
  border: none;
  border-bottom: 1px solid var(--gray-100);
}

.pylon-input.transaction-search-input:active {
  background: white;
}

.transaction-pill {
  border-radius: 100px;
  border: 1px solid #ffffff;
  padding: 0.25rem 0.5rem;
  text-align: center;
  cursor: pointer;
  position: relative;
}

.transaction-pill:hover {
  filter: brightness(90%);
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.24);
}

.transaction-pill:active {
  filter: brightness(80%);
  box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.24);
}

.transaction-pill:hover.disabled {
  filter: none;
  box-shadow: unset;
}
.transaction-pill:active.disabled {
  filter: none;
  box-shadow: unset;
}
