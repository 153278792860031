.editable-title {
  margin-bottom: 0.35rem;
  min-width: 1rem;
  line-height: 2.2rem;
  padding: 0;
  outline-width: 0px;
}
div.editable-title {
  font-size: 2.5rem;
  font-weight: 600;
  color: var(--text-01);
  word-break: break-word;
}
