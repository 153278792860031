.insurance-fields input {
  margin: unset !important;
}

.insurance-personal-data-input-fields .react-datepicker-wrapper {
  width: unset;
}

.insurance-additional-field-label {
  width: 10rem;
}

.insurance-date-picker {
  width: 10rem !important;
}
