.pylon-text {
  font-family: "Montserrat", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 0.875rem;
  line-height: 1.25rem;
}

.pylon-text-highlight {
  font-family: "Montserrat", sans-serif;
  font-weight: 600;
  font-size: 1rem;
  color: #000000;
}

.pylon-text-hint {
  font-family: Helvetica;
  font-weight: 400;
  font-size: 0.875rem;
  color: #8493a6;
  text-align: right;
}

.pylon-text-error {
  font-family: "Montserrat", sans-serif;
  font-weight: 600;
  font-size: 0.875rem;
  color: #f35151;
}

.pylon-text-title-Montserrat {
  font-family: "Montserrat", sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 1.5rem;
  line-height: 2rem;
  color: #09274d;
}

.pylon-text-Montserrat {
  font-family: "Montserrat", sans-serif;
  font-style: normal;
  line-height: 2rem;
  color: #09274d;
}

.pylon-text-field-label {
  font-style: normal;
  font-weight: 600;
  font-size: 0.875rem;
  line-height: 1.5rem;

  /* Gray 70 */
  color: #536882;
}

.pylon-text-B-12 {
  font-family: "Montserrat", sans-serif;
  font-weight: 400;
  font-size: 0.75rem;
  line-height: 130%;

  color: var(--navy);
}
.pylon-text-B-14 {
  font-family: "Montserrat", sans-serif;
  font-weight: 400;
  font-size: 0.875rem;
  line-height: 1.3125rem;

  color: var(--navy);
}

.pylon-text-B-16 {
  /* B - 16 */
  font-family: "Montserrat", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 1rem;
  line-height: 1.5rem;
  /* Navy */
  color: #09274d;
}

.pylon-text-B-18 {
  /* B - 18 */
  font-family: "Montserrat", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 1.125rem;
  line-height: 1.75rem;
  /* Navy */
  color: #09274d;
}

.pylon-text-L-12 {
  font-family: "Montserrat", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 0.875rem;
  line-height: 1.5rem;
}

.pylon-text-L-14 {
  font-family: "Montserrat", sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 0.875rem;
  line-height: 1.5rem;
}

.pylon-text-O-10 {
  font-family: "Montserrat", sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 0.675rem;
  line-height: 1.5rem;
  color: var(--text-03);
}

.pylon-text-M-11 {
  font-family: "Montserrat", sans-serif;
  font-size: 11px;
  font-style: normal;
  font-weight: normal;
  line-height: 1rem;
}

.pylon-text-M-15 {
  font-family: "Montserrat", sans-serif;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: 1.5rem;
  color: black;
}

.pylon-text-M-15-semi-bold {
  font-family: "Montserrat", sans-serif;
  font-size: 15px;
  font-style: normal;
  font-weight: 600;
  line-height: 1.5rem;
}

.pylon-text-M-17-semi-bold {
  font-family: "Montserrat", sans-serif;
  font-size: 17px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
}

.pylon-text-M-18 {
  font-family: "Montserrat", sans-serif;
  font-size: 1.125rem;
  font-style: normal;
  font-weight: 500;
  line-height: 1.5rem;
  color: var(--text-01);
}

/*styleName: montseratt/31/bold;*/
.pylon-text-M-31-B {
  font-family: Montserrat;
  font-size: 31px;
  font-style: normal;
  font-weight: 700;
  line-height: 40px;
}
/*styleName: montseratt/35/regular;*/
.pylon-text-M-35-R {
  font-family: Montserrat;
  font-size: 35px;
  font-style: normal;
  font-weight: 400;
  line-height: 48px;
}
/* styleName: montseratt/64/regular; */
.pylon-text-M-64 {
  font-family: Montserrat;
  font-size: 64px;
  font-style: normal;
  font-weight: 400;
  line-height: 80px;
}

.pylon-text-S-20 {
  font-family: "Montserrat", sans-serif;
  font-size: 1.25rem;
  font-style: normal;
  font-weight: 400;
  line-height: 130%;
  letter-spacing: 0em;
  text-align: left;
  color: var(--navy);
}

.pylon-text-sharp-17-regular {
  font-family: SharpGroteskBook20;
  font-size: 17px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  color: #000000;
}

.pylon-text-sharp-9-bold {
  font-family: SharpGroteskMedium25;
  font-size: 9px;
  font-style: normal;
  font-weight: 400;
  line-height: 12px;
  color: #000000;
}

.pylon-text-sharp-17-bold {
  font-family: SharpGroteskMedium25;
  font-size: 17px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  color: #000000;
}

.pylon-text-sharp-24-bold {
  font-family: SharpGroteskMedium25;
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: 32px;
  color: #000000;
}

.pylon-text-sharp-7-regular {
  font-family: SharpGroteskBook20;
  font-size: 7px;
  font-style: normal;
  font-weight: 400;
  line-height: 8px;
  color: #000000;
}

.pylon-text-sharp-9-regular {
  font-family: SharpGroteskBook20;
  font-size: 9px;
  font-style: normal;
  font-weight: 400;
  line-height: 12px;
  color: #000000;
}

.pylon-text-sharp-14-regular {
  font-family: SharpGroteskBook20;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  color: #000000;
}

.pylon-text-sharp-35-bold {
  font-family: SharpGroteskMedium25;
  font-size: 35px;
  font-style: normal;
  font-weight: 400;
  line-height: 48px;
  color: #000000;
}

.pylon-text-sharp-56-bold {
  font-family: SharpGroteskMedium25;
  font-size: 56px;
  font-style: normal;
  font-weight: 400;
  line-height: 64px;
  color: #000000;
}
